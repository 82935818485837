import React from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {
    Button,
    Modal,
    Input,
    TextArea,
    Select,
    Checkbox,
    DropdownButton,
    ValueType,
    Panel,
    FileModel,
    Upload,
} from "@appkit4/react-components";
import PagePanel from "../../misc/PagePanel";
import Centered from "../../misc/Centered";
import {Table, Column} from "@appkit4/react-components/table";
import {useGlobalStore} from "../../stores/GlobalStore";
import {HeaderType} from "../../types/HeaderType";
import Layout from "../Layout/Layout";
import * as O from "fp-ts/Option";
import {useTr} from "../../utils/trUtil";
import {Loading} from "@appkit4/react-components/loading";
import {Number} from "../../misc/AdjustmentColumns";
import "./AdjustmentEntries.scss";
import {extraPropToFlowjs, uploadUrl} from "../../utils/uploadUtil";
import {adjustment_types} from "../../Constants";
import useDownloadUrl from "../../hooks/useDownloadUrl";
import {TaxTreatmentType} from "../../types/TaxTreatmentTypes";

declare type PropTypes = {};

const AdjustmentEntries: React.FC<PropTypes> = (props: PropTypes) => {
    const trh = useTr("header");
    const trg = useTr("global");
    const trtf = useTr("tax_flows");

    const params = useParams();

    const {renderLink, download} = useDownloadUrl();

    const [searchParams] = useSearchParams();
    const [backlink, setBacklink] = React.useState<string | null>();

    const [entryId, setEntryId] = React.useState<number>();
    const [type, setType] = React.useState<string>();
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [data, setData] = React.useState<any[]>([]);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [reloadingData, setReloadingData] = React.useState<boolean>(false);
    const [formIsSubmitted, setFormIsSubmitted] = React.useState<boolean>(false);
    const [paramEntryIdWasAlreadyLoaded, setParamEntryIdWasAlreadyLoaded] = React.useState<boolean>(false);
    const [entry, setEntry] = React.useState<any>();
    const [reconciliationData, setReconciliationData] = React.useState<any[]>();

    const [accountNumber, setAccountNumber] = React.useState<string>("");
    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [taxType, setTaxType] = React.useState<string>("");
    const [openingBalance, setOpeningBalance] = React.useState<string>("2.0");
    const [creation, setCreation] = React.useState<string>("0.0");
    const [release, setRelease] = React.useState<string>("0.0");
    const [closingBalance, setClosingBalance] = React.useState<string>("0.0");
    const [internalComment, setInternalComment] = React.useState<string>("");
    const [creationComment, setCreationComment] = React.useState<string>("");
    const [releaseComment, setReleaseComment] = React.useState<string>("");

    const [newAccount, setNewAccount] = React.useState<boolean>(false);
    const [availableAccounts, setAvailableAccounts] = React.useState<any[]>([]);
    const [accountsAreLoaded, setAccountsAreLoaded] = React.useState<boolean>(false);

    const [importModalIsOpen, setImportModalIsOpen] = React.useState<boolean>(false);
    const [reconciliationModalIsOpen, setReconciliationModalIsOpen] = React.useState<boolean>(false);

    const [taxTypeOptions, setTaxTypeOptions] = React.useState<{value: string; label: string}[]>([]);
    const [selectedIndexs, setSelectedIndexs] = React.useState([] as number[]);
    const [selectedAction, setSelectedAction] = React.useState<{action: ValueType; data: any}>();
    const [deleteEntryIsRunning, setDeleteEntryIsRunning] = React.useState<boolean>(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (params.entry_id) setEntryId(parseInt(params.entry_id));
        if (params.type) {
            let idx = Object.entries(adjustment_types).find(
                ([key, val]) => val === encodeURIComponent(params.type ? params.type : "").toLowerCase()
            );
            setType(idx === undefined ? undefined : idx[1]);
        }
    }, [params]);

    React.useEffect(() => {
        setBacklink(searchParams.get("backlink"));
    }, [searchParams]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries_tax_treatment_types/?type=${type}`
            )
                .then((res: any) => res.json())
                .then((res: TaxTreatmentType | any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setTaxTypeOptions(
                            res.map((t: TaxTreatmentType) => {
                                return {key: t.type_en, label: t.type, value: t.type_en};
                            })
                        );
                    }
                });
        }
    }, [entity, calculation, type, addErrorNotification, fetchApi]);

    React.useEffect(() => {
        if (entryId && dataAreLoaded && paramEntryIdWasAlreadyLoaded === false) {
            let row = data.find((entry) => entry.id === entryId);
            if (row) setEntry(row);
            setParamEntryIdWasAlreadyLoaded(true);
        }
    }, [entryId, data, dataAreLoaded, paramEntryIdWasAlreadyLoaded]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && (dataAreLoaded === false || reloadingData === true) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries/?type=${type}`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setReloadingData(false);
                    }
                    setData(prepare_data(data));
                    setDataAreLoaded(true);
                });
        }
    }, [type, dataAreLoaded, reloadingData, entity, calculation, addErrorNotification, fetchApi]);

    React.useEffect(() => {
        const entries: any = [];
        data.forEach((d) => {
            let index = 0;
            let accounts = entries.map((e: any) => e.account_number);
            if (entries.length > 0) index = accounts.indexOf(d.account_number);
            if (index === -1) index = entries.length;

            typeof entries[index] !== "undefined"
                ? (entries[index] = {
                      ...d,
                      creation: entries[index].creation + parseFloat(d.creation),
                      release: entries[index].release + parseFloat(d.release),
                      opening_balance: entries[index].opening_balance + parseFloat(d.opening_balance),
                      closing_balance: entries[index].closing_balance + parseFloat(d.closing_balance),
                      adjusted_closing_balance: d.adjusted_closing_balance,
                      adjusted_opening_balance: d.adjusted_opening_balance,
                      adjusted_credit: parseFloat(d.adjusted_credit),
                      adjusted_debit: parseFloat(d.adjusted_debit),
                      je_movement: parseFloat(d.adjusted_debit) - parseFloat(d.adjusted_credit),
                      net_movement:
                          entries[index].creation +
                          parseFloat(d.creation) -
                          entries[index].release -
                          parseFloat(d.release),
                  })
                : (entries[index] = {
                      ...d,
                      creation: parseFloat(d.creation),
                      release: parseFloat(d.release),
                      opening_balance: parseFloat(d.opening_balance),
                      closing_balance: parseFloat(d.closing_balance),
                      je_movement: parseFloat(d.adjusted_debit) - parseFloat(d.adjusted_credit),
                      net_movement: parseFloat(d.creation) - parseFloat(d.release),
                  });
        });

        setReconciliationData(entries);
    }, [data]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && accountsAreLoaded === false)
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/available_accounts/`)
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : setAvailableAccounts(data);
                    setAccountsAreLoaded(true);
                });
    }, [entity, calculation, accountsAreLoaded, addErrorNotification, fetchApi]);

    const prepare_data = (data: any) => {
        return data.map((item: any) => {
            return {
                ...item,
                check: (
                    parseFloat(item.opening_balance) +
                    parseFloat(item.creation) -
                    parseFloat(item.release) -
                    parseFloat(item.closing_balance)
                ).toFixed(2),
            };
        });
    };

    React.useEffect(() => {
        if (entry) {
            setAccountNumber(entry.account_number);
            setName(entry.name);
            setDescription(entry.description);
            setOpeningBalance(parseFloat(entry.opening_balance).toFixed(2));
            setCreation(parseFloat(entry.creation).toFixed(2));
            setRelease(parseFloat(entry.release).toFixed(2));
            setClosingBalance(parseFloat(entry.closing_balance).toFixed(2));
            setTaxType(entry.tax_type);
            setInternalComment(entry.comment_internal ? entry.comment_internal : "");
            setCreationComment(entry.comment_creation ? entry.comment_creation : "");
            setReleaseComment(entry.comment_release ? entry.comment_release : "");
        } else {
            setAccountNumber("");
            setNewAccount(false);
            setName("");
            setDescription("");
            setTaxType("");
            setOpeningBalance("0.00");
            setCreation("0.00");
            setRelease("0.00");
            setClosingBalance("0.00");
            setInternalComment("");
            setCreationComment("");
            setReleaseComment("");
        }
    }, [entry]);

    const saveHandler = () => {
        if (O.isSome(entity) && O.isSome(calculation) && !!description && type) {
            setFormIsSubmitted(true);
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries/?type=${type}`,
                "patch",
                {
                    id: entry.id,
                    account_number: accountNumber,
                    name: name ? name : description,
                    description: description,
                    tax_type: taxType,
                    opening_balance: openingBalance,
                    creation: creation,
                    release: release,
                    closing_balance: closingBalance,
                    comment_internal: internalComment,
                    comment_creation: creationComment,
                    comment_release: releaseComment,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setReloadingData(true);
                        setAccountsAreLoaded(false);
                        setFormIsSubmitted(false);
                        setEntry(undefined);
                    }
                })
                .catch((e) => {
                    if (typeof e.error !== "undefined") {
                        addErrorNotification(e);
                    }
                    setFormIsSubmitted(false);
                });
        }
    };

    const splitEntry = (data: any) => {
        if (O.isSome(entity) && O.isSome(calculation) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries/?type=${type}`,
                "post",
                {
                    entry_id: data.id,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    }
                    setReloadingData(true);
                    setEntry(undefined);
                });
        }
    };

    const confirmSplit = (data: any) => {
        if (O.isSome(entity) && O.isSome(calculation) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries_confirm_split/?type=${type}`,
                "post",
                {
                    entry_id: data.id,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    }
                    setReloadingData(true);
                    setEntry(undefined);
                });
        }
    };

    const deleteEntry = React.useCallback(
        (entry_id: number | null | undefined = null) => {
            if (O.isSome(entity) && O.isSome(calculation) && type) {
                setDeleteEntryIsRunning(true);
                fetchApi(
                    `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries/?type=${type}`,
                    "delete",
                    {
                        entry_id: entry_id ? entry_id : entry.id,
                    }
                )
                    .then((res) => {
                        if (res.ok === false) {
                            addErrorNotification(res);
                        }
                        setEntry(undefined);
                        setReloadingData(true);
                        return res.json();
                    })
                    .then((data: any) => {
                        if (typeof data.error !== "undefined") {
                            addErrorNotification(data);
                        }
                        setEntry(undefined);
                        setReloadingData(true);
                        setDeleteEntryIsRunning(false);
                    })
                    .catch((data) => {
                        if (typeof data.error !== "undefined") {
                            addErrorNotification(data);
                        }
                        setEntry(undefined);
                        setReloadingData(true);
                        setDeleteEntryIsRunning(false);
                    });
            }
        },
        [entity, calculation, fetchApi, addErrorNotification, entry, type]
    );

    const createAdjustmentsFromTrialBalance = () => {
        if (O.isSome(entity) && O.isSome(calculation) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries_create_from_trial_balance/?type=${type}`,
                "post",
                {}
            )
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    }
                    setReloadingData(true);
                    setEntry(undefined);
                })
                .catch((data) => {
                    setReloadingData(true);
                    setEntry(undefined);
                });
        }
    };

    const generate_net_create_release = React.useCallback(() => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries_generate_net_creation_or_release/?type=${type}`,
                "post",
                {entry_ids: selectedIndexs}
            )
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    }
                    setReloadingData(true);
                    setEntry(undefined);
                })
                .catch((data) => {
                    setReloadingData(true);
                    setEntry(undefined);
                });
        }
    }, [entity, calculation, addErrorNotification, fetchApi, setEntry, selectedIndexs, type]);

    const generate_create_release = React.useCallback(() => {
        if (O.isSome(entity) && O.isSome(calculation) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries_generate_creation_release/?type=${type}`,
                "post",
                {entry_ids: selectedIndexs}
            )
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    }
                    setReloadingData(true);
                    setEntry(undefined);
                })
                .catch((data) => {
                    addErrorNotification(data);
                    setReloadingData(true);
                    setEntry(undefined);
                });
        }
    }, [entity, calculation, addErrorNotification, fetchApi, setEntry, selectedIndexs, type]);

    React.useEffect(() => {
        if (selectedIndexs.length > 0 && selectedAction && typeof selectedAction.action != "undefined") {
            switch (selectedAction.action) {
                case "deleteEntries":
                    selectedIndexs.forEach((value) => deleteEntry(value));
                    setSelectedIndexs([]);
                    setReloadingData(true);
                    break;

                case "gen_creation_release":
                    generate_create_release();
                    break;

                case "gen_net_creation_or_release":
                    generate_net_create_release();
                    break;

                default:
                    break;
            }

            setSelectedAction(undefined);
        }
    }, [selectedAction, deleteEntry, generate_create_release, generate_net_create_release, selectedIndexs]);

    const addNewEntry = () => {
        if (O.isSome(entity) && O.isSome(calculation) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries/?type=${type}`,
                "post",
                {
                    tax_type: "TD",
                    creation: 0,
                    release: 0,
                    opening_balance: 0,
                    closing_balance: 0,
                    type: type,
                    name: "new entry",
                    description: "new entry",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    }
                    setReloadingData(true);
                    setEntry(data);
                });
        }
    };

    const exportEntries = () => {
        if (O.isSome(entity) && O.isSome(calculation) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries_export/?type=${type}`
            )
                .then((res) => res.blob())
                .then((response) => {
                    if (response) {
                        download(
                            URL.createObjectURL(response),
                            `adjustment-entries-${type}-export_${calculation.value.hash}.xlsx`
                        );
                    }
                });
        }
    };

    const onImportChange = (file: FileModel, fileList: FileList): void => {
        if (file.percent === 100 && O.isSome(calculation) && O.isSome(entity) && type) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustments_entries_import/?type=${type}`,
                "post",
                file.response
            )
                .then((response) => {
                    if (response.status >= 500) addErrorNotification(response);
                    return response.json();
                })
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setReloadingData(true);
                        setImportModalIsOpen(false);
                    }
                })
                .catch((data) => {
                    addErrorNotification(data);
                });
        }
    };

    const importModalContent = () => {
        return O.isSome(calculation) && O.isSome(entity) ? (
            <Modal
                visible={importModalIsOpen}
                onCancel={() => setImportModalIsOpen(false)}
                modalStyle={{width: "33.75rem"}}
                maskCloseable={false}
            >
                <Upload
                    onChange={onImportChange}
                    action={uploadUrl(entity.value)}
                    multiple={false}
                    autoUpload={true}
                    extraPropToFlowjs={extraPropToFlowjs(entity.value, calculation.value, accessToken)}
                    acceptFileType=".xlsx"
                    uploadInstruction={`(adjustments-entries-${type}-export_${calculation.value.hash}.xlsx)`}
                    uploadTitle={trg("upload_previously")}
                    config={{
                        trigger: false,
                        type: "inline",
                        size: false,
                    }}
                ></Upload>
            </Modal>
        ) : (
            <></>
        );
    };

    const reconciliationModalContent = () => {
        return O.isSome(calculation) && O.isSome(entity) ? (
            <Modal
                visible={reconciliationModalIsOpen}
                onCancel={() => setReconciliationModalIsOpen(false)}
                modalStyle={{width: "90%"}}
                title={trg("trial_balance_reconciliation")}
            >
                {reconciliationData && (
                    <Table className="reconciliation-table" originalData={reconciliationData} hasTitle={true}>
                        <Column sortKey="account_number">{trg("account_number")}</Column>
                        <Column
                            sortKey="adjusted_opening_balance"
                            renderCell={(row, field) => (
                                <Number row={row} field={field} amount={row.adjusted_opening_balance} />
                            )}
                        >
                            <br />
                            {trg("tb")}
                        </Column>
                        <Column
                            sortKey="opening_balance"
                            renderCell={(row, field) => <Number row={row} field={field} amount={row.opening_balance} />}
                        >
                            {trg("opening")}&nbsp;{trg("balance")}
                            <br />
                            {trg("table")}
                        </Column>
                        <Column
                            field="account_number"
                            renderCell={(row, field) => (
                                <Number
                                    row={row}
                                    field={field}
                                    amount={Math.abs(row.adjusted_opening_balance + row.opening_balance)}
                                />
                            )}
                        >
                            <br />
                            {trg("diff")}
                        </Column>
                        <Column
                            sortKey="je_movement"
                            renderCell={(row, field) => <Number row={row} field={field} amount={row.je_movement} />}
                        >
                            <br />
                            {trg("tb")}
                        </Column>
                        <Column
                            sortKey="net_movement"
                            renderCell={(row, field) => <Number row={row} field={field} amount={row.net_movement} />}
                        >
                            {trg("movement")}
                            <br />
                            {trg("table")}
                        </Column>
                        <Column
                            field="account_number"
                            renderCell={(row, field) => (
                                <Number row={row} field={field} amount={Math.abs(row.je_movement + row.net_movement)} />
                            )}
                        >
                            <br />
                            {trg("diff")}
                        </Column>
                        <Column
                            sortKey="adjusted_closing_balance"
                            renderCell={(row, field) => (
                                <Number row={row} field={field} amount={row.adjusted_closing_balance} />
                            )}
                        >
                            <br />
                            {trg("tb")}
                        </Column>
                        <Column
                            sortKey="closing_balance"
                            renderCell={(row, field) => <Number row={row} field={field} amount={row.closing_balance} />}
                        >
                            {trg("opening")}&nbsp;{"balance"}
                            <br />
                            {trg("table")}
                        </Column>
                        <Column
                            field="account_number"
                            renderCell={(row, field) => (
                                <Number
                                    row={row}
                                    field={field}
                                    amount={Math.abs(row.adjusted_closing_balance + row.closing_balance)}
                                />
                            )}
                        >
                            <br />
                            {trg("diff")}
                        </Column>
                    </Table>
                )}
            </Modal>
        ) : (
            <></>
        );
    };

    const runAction = (action: any, item: any) => {
        switch (action) {
            case "addNewEntry":
                addNewEntry();
                break;
            case "exportEntries":
                exportEntries();
                break;
            case "importEntries":
                setImportModalIsOpen(true);
                break;
            default:
                break;
        }
    };

    React.useEffect(() => {}, [selectedIndexs]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <PagePanel
                    title={`${trh("AdjustmentEntriesLabel")} - ${type ? trtf(type) : type}`}
                    className="adjustment-entries-page"
                    buttons={
                        O.isSome(entity) &&
                        O.isSome(calculation) && (
                            <Button
                                kind="text"
                                icon="icon-arrow-left-fill"
                                onClick={() => {
                                    navigate(
                                        backlink
                                            ? backlink
                                            : `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${type}`
                                    );
                                }}
                            >
                                {trg("back")}
                            </Button>
                        )
                    }
                >
                    {dataAreLoaded ? (
                        <>
                            <div className="d-flex">
                                {selectedIndexs.length === data.length && data.length !== 0 ? (
                                    <Button
                                        onClick={() => setSelectedIndexs([])}
                                        compact={true}
                                        kind="text"
                                        className="mx-1"
                                        style={{width: "100px"}}
                                    >
                                        {trg("deselect_all")}
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => setSelectedIndexs(data.map((d) => d.id))}
                                        compact={true}
                                        kind="text"
                                        className="mx-1"
                                        style={{width: "100px"}}
                                    >
                                        {trg("select_all")}
                                    </Button>
                                )}
                                <DropdownButton
                                    kind="secondary"
                                    compact
                                    className="actions-dropdown-menu mx-1"
                                    dropdownMatchWidth={350}
                                    data={[
                                        {
                                            label: trg("Delete entries"),
                                            value: "deleteEntries",
                                            disabled: false,
                                        },
                                        {
                                            label: trg("generate_creation_release_per"),
                                            value: "gen_creation_release",
                                            disabled: false,
                                        },
                                        {
                                            label: trg("generate_net_creation_or"),
                                            value: "gen_net_creation_or_release",
                                            disabled: false,
                                        },
                                    ]}
                                    onSelect={(action, item) => {
                                        setSelectedAction({action: action, data: item});
                                    }}
                                >
                                    {trg("action_on_selected")}
                                </DropdownButton>
                                <Button
                                    kind="primary"
                                    compact
                                    onClick={() => setReconciliationModalIsOpen(true)}
                                    className="mx-1"
                                >
                                    {"Reconciliation"}
                                </Button>
                                <DropdownButton
                                    kind="primary"
                                    compact
                                    className="mx-1 entries-dropdown-menu"
                                    splitButton={false}
                                    data={[
                                        {
                                            label: trg("add_new_entry"),
                                            value: "addNewEntry",
                                            disabled: false,
                                        },
                                        {
                                            label: trg("export_entries_to"),
                                            value: "exportEntries",
                                            disabled: false,
                                        },
                                        {
                                            label: trg("import_entries_from"),
                                            value: "importEntries",
                                            disabled: false,
                                        },
                                    ]}
                                    onSelect={(action, item) => {
                                        runAction(action, item);
                                    }}
                                >
                                    {/* <span className="Appkit4-icon icon-horizontal-more-fill"></span> */}
                                    {trg("entries")}
                                </DropdownButton>
                                <div className="p-2 flex-grow-1 bd-highlight">&nbsp;</div>
                            </div>

                            <Table
                                className="table-adustment-entries"
                                originalData={data}
                                key="id"
                                hasTitle={true}
                                striped={true}
                                condensed={true}
                            >
                                <Column
                                    field="id"
                                    renderCell={(row, field) => {
                                        const onCheckboxChange = (value: boolean) => {
                                            if (value) {
                                                selectedIndexs.push(row.id);
                                            } else {
                                                selectedIndexs.splice(selectedIndexs.indexOf(row.id), 1);
                                            }
                                            setSelectedIndexs([...selectedIndexs]);
                                        };
                                        return (
                                            <>
                                                <Checkbox
                                                    value={row[field]}
                                                    checked={selectedIndexs.includes(row.id)}
                                                    onChange={(value) => onCheckboxChange(value)}
                                                >
                                                    {/* <span>{row[field]}</span> */}
                                                </Checkbox>
                                            </>
                                        );
                                    }}
                                ></Column>
                                <Column
                                    sortKey="account_number"
                                    renderCell={(row, field) => {
                                        return row.parent ? (
                                            <div className="d-flex justify-content-center">
                                                <small className="text-muted">{trg("of_which_it_is")}&nbsp;</small>
                                                <span className="Appkit4-icon icon-arrow-right-fill"></span>
                                            </div>
                                        ) : (
                                            <Button
                                                kind="text"
                                                onClick={() => setEntry(row)}
                                                style={{color: "#203570"}}
                                            >
                                                {row.account_number}&nbsp;&nbsp;&nbsp;&nbsp;<i>{row.name}</i>
                                            </Button>
                                        );
                                    }}
                                >
                                    {trg("account")}
                                </Column>
                                <Column
                                    sortKey="description"
                                    renderCell={(row, field) => {
                                        return (
                                            <span className={row.parent ? "small" : ""} onClick={() => setEntry(row)}>
                                                {row.description}
                                            </span>
                                        );
                                    }}
                                >
                                    {trg("description")}
                                </Column>
                                <Column
                                    sortKey="tax_type"
                                    renderCell={(row, field) => (
                                        <span className={row.parent ? "small" : ""} onClick={() => setEntry(row)}>
                                            {row.tax_type}
                                        </span>
                                    )}
                                >
                                    {trg("type")}
                                </Column>
                                <Column
                                    style={{width: "100px", textAlign: "right"}}
                                    sortKey="opening_balance"
                                    renderCell={(row, field) => (
                                        <Number
                                            row={row}
                                            field={field}
                                            amount={parseFloat(row.opening_balance)}
                                            onClick={() => setEntry(row)}
                                        />
                                    )}
                                >
                                    {trg("opening_balance")}
                                </Column>
                                <Column
                                    style={{width: "100px", textAlign: "right"}}
                                    sortKey="creation"
                                    renderCell={(row, field) => {
                                        return (
                                            <>
                                                {row.children && row.children.length && row.parent == null ? (
                                                    <>
                                                        <Number
                                                            row={row}
                                                            field={field}
                                                            redIfLtZero={true}
                                                            amount={
                                                                parseFloat(row.creation) -
                                                                row.children.reduce((prev: any, d: any) => {
                                                                    return prev + parseFloat(d.creation);
                                                                }, 0)
                                                            }
                                                            onClick={() => setEntry(row)}
                                                        />
                                                        <small className="text-muted">
                                                            <Number
                                                                prefix={<>(</>}
                                                                suffix={<>)</>}
                                                                row={row}
                                                                className={row.parent ? "small" : ""}
                                                                field={field}
                                                                amount={parseFloat(row.creation)}
                                                                onClick={() => setEntry(row)}
                                                            />
                                                        </small>
                                                    </>
                                                ) : (
                                                    <Number
                                                        row={row}
                                                        className={row.parent ? "small" : ""}
                                                        field={field}
                                                        amount={parseFloat(row.creation)}
                                                        onClick={() => setEntry(row)}
                                                    />
                                                )}
                                            </>
                                        );
                                    }}
                                >
                                    {trg("creation")}
                                </Column>
                                {/* <Column sortKey="creation_accounts">Creation Accounts</Column> */}
                                <Column
                                    style={{width: "100px", textAlign: "right"}}
                                    sortKey="release"
                                    renderCell={(row, field) => (
                                        <>
                                            {row.children && row.children.length && row.parent == null ? (
                                                <>
                                                    <Number
                                                        row={row}
                                                        field={field}
                                                        redIfLtZero={true}
                                                        amount={
                                                            parseFloat(row.release) -
                                                            row.children.reduce((prev: any, d: any) => {
                                                                return prev + parseFloat(d.release);
                                                            }, 0)
                                                        }
                                                        onClick={() => setEntry(row)}
                                                    />
                                                    <small className="text-muted">
                                                        <Number
                                                            prefix={<>(</>}
                                                            suffix={<>)</>}
                                                            row={row}
                                                            className={row.parent ? "small" : ""}
                                                            field={field}
                                                            amount={parseFloat(row.release)}
                                                            onClick={() => setEntry(row)}
                                                        />
                                                    </small>
                                                </>
                                            ) : (
                                                <Number
                                                    row={row}
                                                    className={row.parent ? "small" : ""}
                                                    field={field}
                                                    amount={parseFloat(row.release)}
                                                    onClick={() => setEntry(row)}
                                                />
                                            )}
                                        </>
                                    )}
                                >
                                    {trg("release")}
                                </Column>
                                {/* <Column sortKey="release_accounts">Release Accounts</Column> */}
                                <Column
                                    style={{width: "100px", textAlign: "right"}}
                                    sortKey="closing_balance"
                                    renderCell={(row, field) => (
                                        <Number
                                            row={row}
                                            field={field}
                                            amount={parseFloat(row.closing_balance)}
                                            onClick={() => setEntry(row)}
                                        />
                                    )}
                                >
                                    {trg("closing_balance")}
                                </Column>
                                <Column
                                    sortKey="check"
                                    renderCell={(row, field) => (
                                        <Number
                                            row={row}
                                            field={field}
                                            amount={parseFloat(row.check)}
                                            redIfNotZero={true}
                                            onClick={() => setEntry(row)}
                                        />
                                    )}
                                >
                                    {trg("check")}
                                </Column>
                                <Column
                                    field="id"
                                    renderCell={(row, field) => (
                                        <div onClick={() => setEntry(row)}>
                                            {row.comment_internal && (
                                                <div>
                                                    <small className="text-muted">{trg("intenal")}:&nbsp;</small>
                                                    {row.comment_internal}
                                                </div>
                                            )}
                                            {row.comment_creation && (
                                                <div>
                                                    <small className="text-muted">{trg("creation")}:&nbsp;</small>
                                                    {row.comment_creation}
                                                </div>
                                            )}
                                            {row.comment_release && (
                                                <div>
                                                    <small className="text-muted">{trg("release")}:&nbsp;</small>
                                                    {row.comment_release}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                >
                                    {trg("comments")}
                                </Column>
                                <Column
                                    field="id"
                                    renderCell={(row, field) =>
                                        row.parent === null && (
                                            <div className="d-flex flex-row-reverse">
                                                <div>
                                                    {row.children.length > 0 && (
                                                        <Button
                                                            kind="secondary"
                                                            compact={true}
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                confirmSplit(row);
                                                            }}
                                                        >
                                                            {trg("confirm_split")}
                                                        </Button>
                                                    )}
                                                    <Button
                                                        kind="text"
                                                        icon="icon-pie-chart-fill"
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            splitEntry(row);
                                                        }}
                                                        compact
                                                    >
                                                        {trg("split")}
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    }
                                >
                                    {trg("actions")}
                                </Column>
                            </Table>
                            {data.length === 0 && (
                                <Panel className="mt-3">
                                    <div className="row text-center">
                                        <div className="col-md-12 mt-3">
                                            <h4 className="text-muted">{trg("list_of_adjustments_is_empty")}</h4>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <div className="d-inline-block mx-auto my-3">
                                                <Button
                                                    kind="primary"
                                                    icon="icon-plus-fill"
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        createAdjustmentsFromTrialBalance();
                                                    }}
                                                >
                                                    {trg("create_adjustment_from_trial")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            )}
                        </>
                    ) : (
                        <Centered>
                            <Loading loadingType="linear" indeterminate={false} compact={false}></Loading>
                        </Centered>
                    )}
                    {reloadingData && (
                        <div className="row mt-3">
                            <div className="col text-center">
                                <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                            </div>
                        </div>
                    )}
                </PagePanel>
                <Modal
                    visible={entry}
                    title={trg("edit_adjustment_entry")}
                    className="adjustment-entry-form-modal"
                    modalStyle={{width: "60%", minWidth: "400px"}}
                    placement="top"
                    maskCloseable={false}
                    onCancel={() => setEntry(undefined)}
                    icons={""}
                    footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                    header={""}
                    footer={
                        <div className="mt-2 d-flex">
                            <div className="flex-grow-1">
                                <Button
                                    onClick={() => deleteEntry()}
                                    kind="text"
                                    style={{marginRight: "10px"}}
                                    loading={deleteEntryIsRunning}
                                >
                                    <span className="Appkit4-icon icon-delete-fill text-danger"></span>
                                </Button>
                            </div>
                            <Button onClick={() => setEntry(undefined)} kind="tertiary" style={{marginRight: "10px"}}>
                                {trg("cancel")}
                            </Button>
                            <Button onClick={saveHandler} kind="primary" loading={formIsSubmitted}>
                                {trg("update")}
                            </Button>
                        </div>
                    }
                >
                    <div className="row">
                        <div className="col-md-6">
                            {newAccount && (
                                <div className="d-flex">
                                    <div className="mt-3">
                                        <Input
                                            type="text"
                                            value={accountNumber ? accountNumber : ""}
                                            title="Account number"
                                            onChange={(v) => setAccountNumber(v)}
                                        />
                                    </div>
                                    <div className="mt-3 flex-grow-1">
                                        <Input
                                            type="text"
                                            value={name ? name : ""}
                                            title="Account name"
                                            onChange={(v) => setName(v)}
                                        />
                                    </div>
                                </div>
                            )}
                            {!newAccount && accountsAreLoaded && (
                                <div className="mt-3">
                                    <Select
                                        data={availableAccounts.map(({number, name}) => {
                                            return {
                                                value: number,
                                                label: name !== null ? `${number} - ${name}` : `${number}`,
                                                key: name !== null ? `${number} - ${name}` : `${number}`,
                                            };
                                        })}
                                        value={accountNumber ? accountNumber : ""}
                                        searchable={true}
                                        placeholder={trg("account_number")}
                                        dropdownMatchWidth={false}
                                        onSelect={(val) => setAccountNumber(val.toString())}
                                    ></Select>
                                </div>
                            )}
                            <div className="mb-3">
                                <Button
                                    kind={newAccount ? "primary" : "text"}
                                    icon="icon-circle-plus-outline"
                                    onClick={() => setNewAccount(!newAccount)}
                                    compact
                                    className="p-1 mt-1"
                                >
                                    {trg("new_account")}
                                </Button>
                            </div>

                            <div className="my-3">
                                <Input
                                    type="text"
                                    title={trg("description")}
                                    value={description ? description : ""}
                                    required={true}
                                    error={!description}
                                    onChange={(v: string) => setDescription(v)}
                                />
                            </div>
                            <div className="my-3">
                                <Select
                                    data={taxTypeOptions}
                                    value={taxType}
                                    placeholder={trg("type")}
                                    onSelect={(v) => setTaxType(v.toString())}
                                    dropdownMatchWidth={false}
                                    searchable={false}
                                ></Select>
                            </div>
                            <div className="my-3">
                                <Input
                                    type="text"
                                    title={trg("opening_balance")}
                                    value={openingBalance}
                                    onChange={(v) => setOpeningBalance(v)}
                                    onBlur={() => setOpeningBalance(parseFloat(openingBalance).toFixed(2))}
                                />
                            </div>
                            <div className="my-3">
                                <Input
                                    type="text"
                                    title={trg("creation")}
                                    value={creation}
                                    onChange={(v) => setCreation(v)}
                                    onBlur={() => setCreation(parseFloat(creation).toFixed(2))}
                                />
                            </div>
                            <div className="my-3">
                                <Input
                                    type="text"
                                    title={trg("release")}
                                    value={release}
                                    onChange={(v) => setRelease(v)}
                                    onBlur={() => setRelease(parseFloat(release).toFixed(2))}
                                />
                            </div>
                            <div className="my-3">
                                <Input
                                    type="text"
                                    title={trg("closing_balance")}
                                    value={closingBalance}
                                    onChange={(v) => setClosingBalance(v)}
                                    onBlur={() => setClosingBalance(parseFloat(closingBalance).toFixed(2))}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="my-3">
                                <TextArea
                                    title={trg("internal_comment")}
                                    value={internalComment}
                                    onChange={(v) => setInternalComment(v)}
                                />
                            </div>
                            <div className="my-3">
                                <TextArea
                                    title={trg("creation_comment")}
                                    value={creationComment}
                                    onChange={(v) => setCreationComment(v)}
                                />
                            </div>
                            <div className="my-3">
                                <TextArea
                                    title={trg("release_comment")}
                                    value={releaseComment}
                                    onChange={(v) => setReleaseComment(v)}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            </Layout>
            {importModalContent()}
            {reconciliationModalContent()}
            {renderLink}
        </CanShowCalculationMiddleware>
    );
};

export default AdjustmentEntries;
