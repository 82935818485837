import React from "react";
import {Adjustment} from "../../../types/Adjustment";
import {useTr} from "../../../utils/trUtil";
import {useGlobalStore} from "../../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {Select, Button, Notice} from "@appkit4/react-components";
import {adjustment_types} from "../../../Constants";
declare type AdjustmentChildPropTypes = {
    adjustment: Adjustment;
};

export const AdjustmentSettings: React.FC<AdjustmentChildPropTypes> = (props: AdjustmentChildPropTypes) => {
    const [adjustment, setAdjustment] = React.useState<Adjustment>(props.adjustment);
    const [type, setType] = React.useState<string>(props.adjustment.type);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const trg = useTr("global");
    const trtf = useTr("tax_flows");
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const loadAdjustments = useGlobalStore((s) => s.loadAdjustments);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const addSuccessNotification = useGlobalStore((s) => s.addSuccessNotification);

    // React.useEffect(() => {
    //     setAdjustment(props.adjustment);
    //     setType(props.adjustment.type);
    // }, [props, props.adjustment]);

    const onSubmit = () => {
        if (props.adjustment && props.adjustment.type == type) {
            addSuccessNotification(trg("saved_successfully"));
            return;
        }
        if (O.isSome(entity) && O.isSome(calculation)) {
            setIsLoading(true);
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/${adjustment.id}/`,
                "patch",
                {
                    type: type,
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        loadAdjustments();
                        setAdjustment(data);
                        setIsLoading(false);
                        addSuccessNotification(trg("saved_successfully"));
                    }
                })
                .catch((err) => addErrorNotification(err));
        }
    };

    const helpText = (
        <div>
            &bull;&nbsp;some filters can be deleted,
            <br />
            &bull;&nbsp;some adjustment entries can be added or deleted,
            <br />
            &bull;&nbsp;some subadjustment can be added or deleted
        </div>
    );

    return (
        <>
            <div className="mx-auto mt-3 p-3 pt-2 pb-4">
                <div className="row">
                    <div className="col-md-12">
                        <Notice
                            className="w-100 mx-0"
                            title="If you change adjustment type:"
                            message={helpText}
                            status="warning"
                            closeable={false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <label>
                            <small>{trg("adjustment_settings_change_type_description")}</small>
                        </label>
                        <Select
                            placeholder={trg("adjustment_type")}
                            //hideTitleOnInput={true}
                            data={Object.entries(adjustment_types).map(([t, name]) => {
                                return {
                                    label: trtf(`${name}_label`),
                                    value: name,
                                };
                            })}
                            value={type}
                            dropdownMatchWidth={false}
                            onSelect={(v: any) => {
                                setType(v);
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <div className="flex-grow-1"></div>
                    <div>
                        <Button loading={isLoading} onClick={onSubmit}>
                            {trg("save")}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
