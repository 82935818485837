import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Table, Column} from "@appkit4/react-components/table";
import {Loading} from "@appkit4/react-components";
import {DjangoDefaultPaginationType} from "../../../types/DjangoDefaultPaginationType";
import {Pagination} from "@appkit4/react-components/pagination";
import {FormRow} from "../../../types/FormRow";
import FormRowForm from "./Forms/FormRowForm";
import useFormSelection from "./Forms/useFormSelection";
import ButtonCopyFormObject from "./Forms/ButtonCopyFormObject";
import {Switch} from "@appkit4/react-components/switch";
import Centered from "../../../misc/Centered";

declare type PropTypes = {};

interface PaginationType extends DjangoDefaultPaginationType {
    results: FormRow[];
}

const AdminFixtureFormRow: React.FC<PropTypes> = (props: PropTypes) => {
    const [data, dispatch] = React.useReducer(formRowReducer, []);

    //const [data, setData] = React.useState<FormRow[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [countPerPage, setCountPerPage] = React.useState<number>(100);
    const [current, setCurrent] = React.useState(1);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [dataAreLoading, setDataAreLoading] = React.useState<boolean>(false);
    const [showAsTree, setShowAsTree] = React.useState<boolean>(true);
    const {formSelector, selectedForm, setNewForm} = useFormSelection(dataAreLoading);
    const [treeRender, setTreeRender] = React.useState<JSX.Element>(<></>);

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const onPageChange = (page: number) => {
        setCurrent(page);
        setDataAreLoading(true);
    };

    React.useEffect(() => {
        setDataAreLoading(true);
    }, [selectedForm]);

    React.useEffect(() => {
        if (selectedForm && (dataAreLoaded === false || dataAreLoading === true)) {
            fetchApi(
                `/settings/_/fixtures/formrow/${showAsTree ? "tree/" : ""}?form=${selectedForm.id}&page=${current}`
            )
                .then((r) => r.json())
                .then((res: PaginationType | any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        dispatch({type: "INIT", items: showAsTree ? res : res.results});
                        setTotalCount(res.count);
                    }
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                });
        }
    }, [dataAreLoaded, addErrorNotification, fetchApi, dataAreLoading, current, selectedForm, showAsTree]);

    const renderActionCell = (row: FormRow, field) => {
        return (
            selectedForm && (
                <FormRowForm
                    key={row.id}
                    form={selectedForm}
                    item={row}
                    parent={row.parent}
                    onSaveHandler={() => setDataAreLoaded(false)}
                />
            )
        );
    };

    const pagination = React.useMemo(
        () => (
            <Pagination
                current={current}
                total={Math.ceil(totalCount / countPerPage)}
                onPageChange={onPageChange}
            ></Pagination>
        ),
        [totalCount, countPerPage, current]
    );

    const renderRulesetCategoryIds = (row: any, field: string) =>
        row.ruleset_category_ids.length > 0 ? row.ruleset_category_ids.slice(0, 30) + "..." : "";

    const table = (
        <>
            {pagination}
            <Table className="table-responsive" originalData={data} hasTitle striped condensed>
                <Column field="id">ID</Column>
                <Column field="id" title={"Action"} renderCell={renderActionCell}>
                    Action
                </Column>
                <Column field="form">Form</Column>
                <Column field="description" style={{minWidth: 400}}>
                    Description
                </Column>
                <Column field="description_en">Description EN</Column>
                <Column field="info">Info</Column>
                <Column field="info_en">Info EN</Column>
                <Column field="ruleset_category_ids" renderCell={renderRulesetCategoryIds}>
                    Ruleset category ids
                </Column>
                <Column field="row_number">Row number</Column>
                <Column field="row_type">Row type</Column>
                <Column field="formula">Formula</Column>
                <Column field="xml_node_name">XML node name</Column>
                <Column field="xml_node_type">XML node type</Column>
                <Column
                    field="source_data"
                    style={{width: 300}}
                    renderCell={(row: any, field: string) => row.source_data?.slice(0, 100)}
                >
                    Source data
                </Column>
                <Column field="html_type">HTML type</Column>
                <Column field="data_type">Data type</Column>
                <Column field="input_type">Input type</Column>
                <Column field="calculate_with">Calculate with</Column>
                <Column field="print_with">Print with</Column>
                <Column field="print">Print</Column>
                <Column field="show">Show</Column>
                <Column field="priority_xml">Priority XML</Column>
                <Column field="priority_form">Priority Form</Column>
                <Column field="parent">Parent</Column>
                <Column field="children">Children</Column>
            </Table>
            {pagination}
        </>
    );

    const formRow = React.useCallback(
        (row: FormRow) => {
            return (
                <div key={row.id} className="m-2 p-2 border">
                    <table className="table table-condensed">
                        <tbody>
                            <tr>
                                <td rowSpan={3}>
                                    <small>{row.id}</small>
                                </td>
                                <td rowSpan={3}>
                                    {selectedForm && (
                                        <FormRowForm
                                            item={row}
                                            form={selectedForm}
                                            parent={row.parent}
                                            onSaveHandler={(res) => dispatch({type: "UPDATE", data: res})}
                                            onDeleteHandler={(res) => dispatch({type: "DELETE", data: row.id})}
                                        />
                                    )}
                                </td>
                                <td colSpan={4}>
                                    {row.description}
                                    {row.info && (
                                        <>
                                            , <small>( {row.info})</small>
                                        </>
                                    )}
                                </td>
                                <td>
                                    {selectedForm && (
                                        <FormRowForm
                                            parent={row.id}
                                            className="float-end"
                                            form={selectedForm}
                                            onSaveHandler={(new_form_row) => {
                                                dispatch({type: "ADD_TREE", data: new_form_row});
                                            }}
                                        />
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <small className="text-muted">row_type:</small> {row.row_type}
                                </td>
                                <td>
                                    <small className="text-muted">formula:</small> {row.formula}
                                </td>
                                <td>
                                    <small className="text-muted">data_type:</small> {row.data_type}
                                </td>
                                <td>
                                    <small className="text-muted">html_type:</small> {row.html_type}
                                </td>
                                <td>
                                    <small className="text-muted">input_type:</small> {row.input_type}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={5}>
                                    <small className="text-muted">xml nod name:</small> {row.xml_node_name}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {row.children && row.children.map((child) => formRow(child))}
                </div>
            );
        },
        [selectedForm]
    );

    React.useEffect(() => {
        setTreeRender(<>{data.map((row) => formRow(row))}</>);
    }, [data, formRow]);

    return (
        <CanShowAdminMiddleware>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-4">{formSelector}</div>
                    {selectedForm && (
                        <div className="col-8 text-end">
                            <ButtonCopyFormObject
                                className="float-end"
                                form={selectedForm}
                                onCopyHandler={(new_form) => {
                                    setNewForm(new_form);
                                }}
                                disabled={dataAreLoading}
                            />
                            <Switch
                                showIndicator
                                className="float-end me-2 mt-1"
                                checked={showAsTree}
                                onChange={(v) => {
                                    dispatch({type: "INIT", items: []});
                                    setShowAsTree(v);
                                    setDataAreLoading(true);
                                }}
                                disabled={dataAreLoading}
                            >
                                {" "}
                                Show as tree
                            </Switch>
                        </div>
                    )}
                </div>
                {selectedForm ? (
                    !dataAreLoading ? (
                        showAsTree ? (
                            treeRender
                        ) : (
                            table
                        )
                    ) : (
                        <div className="m-5 p-5">
                            <Centered>
                                <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                            </Centered>
                        </div>
                    )
                ) : (
                    <div className="bg-light my-3 p-3">Select the relevant form to view its form rows.</div>
                )}
            </div>
            {selectedForm && (
                <FormRowForm
                    form={selectedForm}
                    onSaveHandler={(new_form_row) => {
                        dispatch({type: showAsTree ? "ADD_TREE" : "ADD", data: new_form_row});
                    }}
                />
            )}
        </CanShowAdminMiddleware>
    );
};

export default AdminFixtureFormRow;

export const formRowReducer = (state: any, action: any) => {
    var new_state: any = undefined;

    const addChildToParent = (parent, newChild) => {
        if (parent.id === newChild.parent) {
            return {
                ...parent,
                children: parent.children ? [...parent.children, newChild] : [newChild],
            };
        }
        const updatedChildren = parent.children
            ? parent.children.map((child) => addChildToParent(child, newChild))
            : [];

        return {
            ...parent,
            children: updatedChildren,
        };
    };

    const updateRowInTree = (row, newRow) => {
        if (row.id === newRow.id) {
            return newRow;
        }
        const updatedChildren = row.children ? row.children.map((child) => updateRowInTree(child, newRow)) : [];

        return {
            ...row,
            children: updatedChildren,
        };
    };

    const deleteRowInTree = (row, rowIdToDelete) => {
        if (row.id === rowIdToDelete) {
            return null;
        }
        const updatedChildren = row.children ? row.children.map((child) => deleteRowInTree(child, rowIdToDelete)) : [];

        return {
            ...row,
            children: updatedChildren.filter((i) => i !== null),
        };
    };

    switch (action.type) {
        case "INIT":
            return action.items;
        case "ADD":
            new_state = [...state];
            new_state.push({...action.data, children: []});
            return new_state;
        case "ADD_TREE":
            new_state = [...state].map((parent) => addChildToParent(parent, action.data));
            if (action.data.parent === null) new_state.push({...action.data, children: []});
            return new_state;
        case "UPDATE":
            new_state = [...state].map((parent) => updateRowInTree(parent, action.data));
            return new_state;
        case "DELETE":
            new_state = [...state].map((parent) => deleteRowInTree(parent, action.data)).filter((i) => i !== null);
            return new_state;
        default:
            return state;
    }
};
