import React from "react";
import {safeBackendFetch} from "../utils/apiClient";
import FrontView from "./FrontView";
import {useAuth} from "react-oidc-context";
import {useGlobalStore} from "../stores/GlobalStore";
import {Button} from "@appkit4/react-components";
import LoginPage from "./LoginPage";
import LoadingContainer, {CircularWidth} from "./LoadingContainer";
import {urlInsertParam} from "../utils/urlInsertParam";

interface PropTypes {
    children: React.ReactNode;
}
// Check whether backend is available -- TODO: Rework to use web sockets and ping ponging
const BackendAvailabilityProvider: React.FC<PropTypes> = (props: PropTypes) => {
    const [reloaded, setReloaded] = React.useState(false);
    const [beError, setBeError] = React.useState(null);
    const [beStatusLoop, setStatusLoop] = React.useState<Promise<NodeJS.Timeout>>();
    const setAccessToken = useGlobalStore((s) => s.setAccessToken);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const currentLocation = useGlobalStore((s) => s.currentLocation);
    const setAuth = useGlobalStore((s) => s.setAuth);

    const auth = useAuth();

    React.useEffect(() => {
        let inputUrl = new URL(window.location.href);
        let inputParams = new URLSearchParams(inputUrl.search);
        if (inputParams.get("reloaded")) setReloaded(true);
        if (currentLocation) window.localStorage.setItem("lastpage", JSON.stringify(currentLocation));
    }, [currentLocation]);

    React.useEffect(() => {
        setAuth(auth);
    }, [auth, setAuth]);

    React.useEffect(() => {
        if (auth.user?.access_token) {
            setAccessToken(auth.user?.access_token);
        }
        if (auth.user?.profile.given_name) window.localStorage.setItem("firstname", auth.user?.profile.given_name);
        if (auth.user?.profile.family_name) window.localStorage.setItem("lastname", auth.user?.profile.family_name);
    }, [auth.user?.access_token, auth.user?.profile, setAccessToken]);

    React.useEffect(() => {
        if (!beStatusLoop) {
            const beStatusLoop = async () => {
                try {
                    await safeBackendFetch("/", {method: "post"})
                        .then((r) => {
                            return r.json();
                        })
                        .then((res) => {
                            setBeError(typeof res.error !== "undefined" ? res.error.details[0] : null);
                        });
                } catch (e: any) {
                    setBeError(e.message);
                }
                return setTimeout(() => beStatusLoop(), beError ? 10000 : 15000);
            };
            setStatusLoop(beStatusLoop);
        }
        return () => {};
    }, [beError, beStatusLoop]);

    React.useEffect(() => {
        if (auth.error && auth.error.message.indexOf("No matching state found in storage") < 0 && reloaded === false) {
            urlInsertParam("reloaded", "true", null, true);
        }
    }, [auth]);

    // automatically sign-in
    // React.useEffect(() => {
    //     if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
    //         auth.signinRedirect();
    //     }
    // }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading && accessToken === "") {
        return <LoadingContainer circularWidthOverride={CircularWidth.big} />;
    }

    if (auth.error) {
        return (
            <FrontView subtitle="Oops..." title="Something is wrong">
                <h5>{auth.error.message.charAt(0).toUpperCase() + auth.error.message.slice(1)}</h5>
                {auth.error.message.indexOf("No matching state found in storage") >= 0 ? (
                    <p className="a-p-20 mt-3 text-center">
                        <Button role="link" onClick={() => (window.location.href = "/")}>
                            Login
                        </Button>
                    </p>
                ) : (
                    <p className="a-p-20 mt-3 text-center">
                        <Button role="link" onClick={() => window.location.reload()}>
                            Refresh
                        </Button>
                    </p>
                )}
            </FrontView>
        );
    }

    if (auth.isAuthenticated) {
        return beError ? (
            <FrontView
                title={beError === "Failed to fetch" ? "We are sorry but this service is currently offline!" : beError}
                subtitle="Oh no! Something went wrong!"
            >
                <h6 className="pt-4">
                    You can wait here until it gets back online.
                    <br />
                    If you refresh, you're likelly to lose your progress.
                </h6>
            </FrontView>
        ) : (
            <>{props.children}</>
        );
    }

    return <LoginPage />;
};

export default BackendAvailabilityProvider;
