import React from "react";
import LayoutFooter from "./LayoutFooter";
import LayoutHeader from "./LayoutHeader";
import "./Layout.scss";
import {HeaderType} from "../../types/HeaderType";
import NotificationToaster from "../../misc/NotificationToaster";
import {useGlobalStore} from "../../stores/GlobalStore";
import {useLocation} from "react-router-dom";

interface PropTypes {
    children: React.ReactNode;
    headerType: HeaderType;
}

const Layout: React.FC<PropTypes> = (props: PropTypes) => {
    const gs = useGlobalStore((s) => s);
    const location = useLocation();
    React.useEffect(() => {
        gs.setCurrentLocation(location);
    }, [location]);
    return (
        <div id="layout-node">
            <div id="layout-header-container">
                <LayoutHeader headerType={props.headerType} />
            </div>
            <div id="content-body">{props.children}</div>
            <div id="layout-footer-container">
                <LayoutFooter />
            </div>
            <NotificationToaster />
        </div>
    );
};

export default Layout;
