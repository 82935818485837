import React from "react";
import {useNavigate} from "react-router";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Ruleset} from "../../../../types/Ruleset";
import {Input, Button, Select} from "@appkit4/react-components";
import {CalendarPicker} from "@appkit4/react-components/datepicker";
import i18n from "../../../../utils/i18n";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {TextArea} from "@appkit4/react-components/field";
import {dateToIsoString} from "../../../../utils/dateToIsoString";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {load} from "mime";

declare type PropTypes = {
    ruleset?: Ruleset;
    onSaveHandler?: () => void;
    isCopyForm?: boolean;
};

const RulesetForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [ruleset, setRuleset] = React.useState<Ruleset | undefined>(props.ruleset ? props.ruleset : undefined);

    const {loadRulesets} = useFixtureStore((s) => ({
        loadRulesets: s.loadRulesets,
    }));

    const [data, setData] = React.useState<Ruleset>({
        id: props.ruleset ? props.ruleset.id : 0,
        name: props.ruleset ? props.ruleset.name : "",
        name_sk: props.ruleset ? props.ruleset.name_sk : "",
        description: props.ruleset && props.ruleset.description ? props.ruleset.description : "",
        description_sk: props.ruleset && props.ruleset.description_sk ? props.ruleset.description_sk : "",
        country_key: props.ruleset && props.ruleset.country_key ? props.ruleset.country_key + "" : "",
        valid_from:
            props.ruleset && props.ruleset.valid_from
                ? dateToIsoString(new Date(props.ruleset.valid_from)).split("T")[0]
                : undefined,
        valid_to:
            props.ruleset && props.ruleset.valid_to
                ? dateToIsoString(new Date(props.ruleset.valid_to)).split("T")[0]
                : undefined,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint =
            props.ruleset && props.isCopyForm
                ? `/settings/_/fixtures/ruleset/${props.ruleset.id}/copy/`
                : props.ruleset
                ? `/settings/_/fixtures/ruleset/${props.ruleset.id}/`
                : `/settings/_/fixtures/ruleset/`;

        fetchApi(endpoint, props.ruleset && props.isCopyForm ? "post" : props.ruleset ? "patch" : "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    loadRulesets();
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setRuleset(props.ruleset);
    }, [props.ruleset]);

    return (
        <>
            {ruleset === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    Add
                </Button>
            ) : props.isCopyForm ? (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-copy-outline"></span>
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            <Modal
                visible={visible}
                title={"Ruleset Form"}
                ariaLabel={"Ruleset Form"}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    ruleset && props.isCopyForm ? (
                        <Badge type={"warning"} value="Copying"></Badge>
                    ) : ruleset ? (
                        <Badge type={"primary"} value="Editing"></Badge>
                    ) : (
                        <Badge type="success" value="Adding"></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            Cancel
                        </Button>
                        <Button onClick={saveHandler} loading={isLoading}>
                            Save
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row">
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Name"}
                            value={data.name}
                            required
                            onChange={(val: string) => setData((s) => ({...s, name: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-4 mt-3">
                        <Select
                            data={["SK", "CZ", "HU", "RO", "PL"]}
                            placeholder="Country key"
                            value={data.country_key}
                            onSelect={(val) => setData((s) => ({...s, country_key: val.toString()}))}
                        ></Select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Name SK"}
                            value={data.name_sk}
                            required
                            onChange={(val: string) => setData((s) => ({...s, name_sk: val}))}
                        ></Input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <div className="row justify-content-between">
                            <div className="col-md-6">
                                <CalendarPicker
                                    key={1}
                                    format="YYYY-MM-DD"
                                    placeholder="YYYY-MM-DD"
                                    value={data.valid_from}
                                    onChange={(val, isValid) =>
                                        isValid && data.valid_from !== val
                                            ? setData((s) => ({...s, valid_from: val}))
                                            : null
                                    }
                                    locale={i18n.language}
                                    required
                                    fieldTitle="Valid from"
                                />
                            </div>
                            <div className="col-md-6">
                                <CalendarPicker
                                    key={2}
                                    format="YYYY-MM-DD"
                                    placeholder="YYYY-MM-DD"
                                    value={data.valid_to ? data.valid_to : undefined}
                                    onChange={(val: any, isValid: boolean) =>
                                        isValid && data.valid_to !== val
                                            ? setData((s) => ({...s, valid_to: val}))
                                            : null
                                    }
                                    locale={i18n.language}
                                    fieldTitle="Valid to"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button
                                    compact
                                    kind="text"
                                    className="ms-auto"
                                    onClick={() => setData((s) => ({...s, valid_to: null}))}
                                >
                                    clear valid to datetime
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <TextArea
                            title={"Description"}
                            required
                            value={data.description}
                            onChange={(val: string) => setData((s) => ({...s, description: val}))}
                        ></TextArea>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <TextArea
                            title={"Description SK"}
                            required
                            value={data.description_sk}
                            onChange={(val: string) => setData((s) => ({...s, description_sk: val}))}
                        ></TextArea>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RulesetForm;
