import React from "react";
import {Adjustment} from "../../../types/Adjustment";
import {useTr} from "../../../utils/trUtil";
import {useGlobalStore} from "../../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {Upload, Button, FileModel, Loading, Select, List, ListItem} from "@appkit4/react-components";
import {Filter} from "../../../types/Filter";
import {extraPropToFlowjs, uploadUrl} from "../../../utils/uploadUtil";
import {Attachment} from "../../../types/Attachment";
import {AdjustmentAttachment} from "../../../types/AdjustmentAttachment";
import classes from "./AdustmentAttachments.module.scss";

declare type PropTypes = {
    adjustment: Adjustment;
};

export const AdjustmentAttachments: React.FC<PropTypes> = (props: PropTypes) => {
    const [adjustment, setAdjustment] = React.useState<Adjustment>(props.adjustment);
    const [filelist, setFilelist] = React.useState<any[]>([]);
    const [attachments, setAttachments] = React.useState<any[]>([]);

    const trg = useTr("global");
    const trh = useTr("header");
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const loadAdjustments = useGlobalStore((s) => s.loadAdjustments);

    const [showUpload, setShowUpload] = React.useState<boolean>(false);
    const [showAttachmentLoader, setShowAttachmentLoader] = React.useState<boolean>(false);
    const [uploadInProgress, setUploadInProgress] = React.useState<boolean>(false);

    React.useEffect(() => {
        setAdjustment(props.adjustment);
    }, [props]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation)) setAttachments(calculation.value.attachments);
    }, [entity, calculation]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && adjustment) {
            setFilelist(
                attachments
                    .filter(
                        (a: Attachment) =>
                            !adjustment.attachments.some((b: AdjustmentAttachment) => b.attachment.id === a.id)
                    )
                    .map((item) => {
                        return {
                            value: item,
                            label: item.file.original_filename,
                        };
                    })
            );
        }
    }, [entity, calculation, attachments, adjustment]);

    const assignAttachment = (data: Attachment) => {
        if (O.isSome(entity) && O.isSome(calculation) && adjustment) {
            setShowAttachmentLoader(true);
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/${adjustment.id}/attachment/`,
                "post",
                {attachment_id: data.id}
            )
                .then((r) => r.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : loadAdjustments();
                    setTimeout(() => setShowAttachmentLoader(false), 2000);
                })
                .catch((data) => {
                    addErrorNotification(data);
                    setShowAttachmentLoader(false);
                });
        }
    };

    const unassignAttachment = (data: Attachment) => {
        if (O.isSome(entity) && O.isSome(calculation) && adjustment) {
            setShowAttachmentLoader(true);
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/${adjustment.id}/attachment/`,
                "delete",
                {attachment_id: data.id}
            )
                .then((r) => r.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : loadAdjustments();
                    setTimeout(() => setShowAttachmentLoader(false), 2000);
                })
                .catch((data) => {
                    addErrorNotification(data);
                    setShowAttachmentLoader(false);
                });
        }
    };

    const onFileUploadChange = (file: FileModel, fileList: FileList): void => {
        if (O.isSome(entity) && O.isSome(calculation))
            if (file.percent === 100) {
                fetchApi(
                    `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/attachment/`,
                    "post",
                    file.response
                )
                    .then((response) => response.json())
                    .then((data) => {
                        setUploadInProgress(false);
                        typeof data.error !== "undefined" ? addErrorNotification(data) : assignAttachment(data);
                    })
                    .catch((data) => {
                        setUploadInProgress(false);
                        addErrorNotification(data);
                    });
            } else {
                setUploadInProgress(true);
            }
    };

    const renderAttach = (item: any, index: number) => {
        return (
            <ListItem key={index} role="option" style={{padding: "2px 8px", margin: "5px 0"}}>
                <span className="primary-text">{item.attachment.file.original_filename}</span>
                <span className="secondary-text">
                    <span
                        className="Appkit4-icon icon-link-broken-fill"
                        onClick={() => unassignAttachment(item.attachment)}
                    ></span>
                </span>
            </ListItem>
        );
    };

    return O.isSome(entity) && O.isSome(calculation) ? (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="d-flex">
                        <div className="flex-grow-1"></div>
                        <div className="mb-2">
                            <Button
                                kind="secondary"
                                compact={true}
                                icon="icon-plus-outline"
                                onClick={() => setShowUpload(!showUpload)}
                            >
                                {trg("add")}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="attachment-list">
                        <h5>{trh("CalculationAttachmentsLabel")}</h5>
                        {adjustment.attachments.length > 0 ? (
                            <>
                                {showAttachmentLoader && (
                                    <Loading loadingType="circular" indeterminate={true} compact={true}></Loading>
                                )}
                                <List itemKey="id" data={adjustment.attachments} renderItem={renderAttach}></List>
                            </>
                        ) : (
                            <small className="text-muted">
                                <i>{trg("no_attachments")}</i>
                            </small>
                        )}
                    </div>
                </div>
                <div className="col-md-6">
                    <div
                        className={`${classes["uploadAttachment"]} ap-border-radius-3 px-3 py-0 ${
                            showUpload ? classes["active"] : ""
                        }`}
                    >
                        <Upload
                            style={{width: "100%"}}
                            action={uploadUrl(entity.value)}
                            autoUpload={true}
                            extraPropToFlowjs={extraPropToFlowjs(entity.value, calculation.value, accessToken)}
                            //uploadTitle="Upload new attachment"
                            uploadInstruction="Here you can upload new attachment. The max file size is 1 GB."
                            description={() => (
                                <>
                                    <span>{trg("drag_and_drop_or")} </span>
                                    <span className="ap-fileupload-drop-browse-span" data-mode="files">
                                        {trg("choose_files")}
                                    </span>
                                </>
                            )}
                            multiple={true}
                            acceptFileType=""
                            color={"white"}
                            onChange={onFileUploadChange}
                            showFileList={false}
                            config={{
                                trigger: false,
                                type: "inline",
                                size: false,
                            }}
                            onError={(file) => {
                                addErrorNotification({error: {details: {detail: "File upload error."}}});
                            }}
                        ></Upload>
                        {uploadInProgress && (
                            <Loading
                                loadingType="linear"
                                indeterminate={true}
                                compact={false}
                                style={{margin: "0 auto 20px auto"}}
                            ></Loading>
                        )}
                        <div className="mt-0">
                            <div>
                                <span>{trg("or_you_can_select")}</span>
                            </div>
                            <Select
                                data={filelist}
                                searchable={true}
                                value={"1"}
                                placeholder="Choose from existing attachment"
                                onSelect={(attach: any) => assignAttachment(attach)}
                                dropdownMatchWidth={false}
                            ></Select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <></>
    );
};
