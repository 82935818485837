import React from "react";
import {Button, Modal} from "@appkit4/react-components";

const Confirmation = (props: {
    solid?: boolean;
    buttonKind?: string;
    buttonText?: string;
    buttonStyle?: {};
    title?: string;
    description?: string;
    onConfirm?: () => any;
}) => {
    const [visible, setVisible] = React.useState(false);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOnConfirm = () => {
        if (props.onConfirm) props.onConfirm();
        setVisible(false);
        return true;
    };

    return (
        <>
            <Button onClick={showModal} kind={props.buttonKind} style={props.buttonStyle}>
                {props.buttonText ? props.buttonText : "delete"}
            </Button>
            <Modal
                visible={visible}
                className="ap-pattern-confirmation"
                title={props.title ? props.title : "Delete?"}
                closable
                maskCloseable={false}
                onCancel={handleCancel}
                footer={
                    <>
                        <Button kind="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button kind="negative" onClick={handleOnConfirm}>
                            Ok
                        </Button>
                    </>
                }
            >
                <div className="ap-pattern-confirmation-message">
                    {props.description
                        ? props.description
                        : "Are you sure you want to delete this item? This action is permanent."}
                </div>
            </Modal>
        </>
    );
};

export default Confirmation;
