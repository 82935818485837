import React from "react";
import Layout from "../Layout/Layout";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import PagePanel from "../../misc/PagePanel";
import {useTr} from "../../utils/trUtil";
import {useGlobalStore} from "../../stores/GlobalStore";
import {Button, List, ListItem} from "@appkit4/react-components";
import * as O from "fp-ts/Option";
import ClassNames from "classnames";
import "./ExportBase.scss";
import {routerParamsHelper} from "../Layout/LayoutHeader";
import {RoutingTable} from "../../Constants";
import {useNavigate, useParams} from "react-router-dom";
import {GenericParams} from "../../types/Params";
import useDownloadUrl from "../../hooks/useDownloadUrl";

declare type PropTypes = {};

const ExportBase: React.FC<PropTypes> = (props: PropTypes) => {
    const trh = useTr("header");

    const uriParams = useParams<GenericParams>();
    const navigate = useNavigate();

    const {renderLink, download} = useDownloadUrl();

    const {fetchApi, calculation, entity, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        calculation: s.currentCalculation,
        entity: s.currentCalculationEntity,
        addErrorNotification: s.addErrorNotification,
    }));

    const [showLoadingOnType, setShowLoadingOnType] = React.useState<string[]>([]);

    const exportTaxCalculation = (type: string) => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            let base_url = `/entity/${entity.value.hash}/calculation/${calculation.value.hash}`;
            let url = `${base_url}/questions_to_excel/`;
            if (type === "xml") url = `${base_url}/export_tax_form_xml/`;
            if (type === "pdf") url = `${base_url}/export_tax_form_pdf/`;
            if (type === "original") url = `${base_url}/export_tax_calculation_original/`;
            setShowLoadingOnType((prev) => [...prev, type]);
            fetchApi(url)
                .then((res) => {
                    if (res.status >= 400) {
                        return res.json();
                    }
                    return res.blob();
                })
                .then((response) => {
                    if (typeof response.error !== "undefined") {
                        addErrorNotification(response);
                    } else {
                        download(
                            URL.createObjectURL(response),
                            `calculation_${calculation.value.hash}.${type === "original" ? "xlsx" : type}`
                        );
                    }

                    setShowLoadingOnType((prev: any) => prev.filter((e: string) => e !== type));
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setShowLoadingOnType((prev: any) => prev.filter((e: string) => e !== type));
                });
        }
    };

    const data = [
        {
            id: "1",
            primary: "Tax calculation.xlsx",
            secondary: "original",
        },
        {
            id: "2",
            primary: "Tax form.xml",
            secondary: "xml",
        },
        {
            id: "3",
            primary: "Tax calculation(beta).pdf",
            secondary: "pdf",
        },
        {
            id: "4",
            primary: "Tax form.xls",
            secondary: null,
        },
        {
            id: "5",
            primary: "Tax calculation(beta).xlsx",
            secondary: "xlsx",
        },
    ];

    const [selectedId, setSelectedId] = React.useState("");

    const handleKeyDown = (item: any, event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            setSelectedId(item.id);
        }
    };

    const renderItem = (item: any, index: number) => {
        const classes = ClassNames({selected: item.id === selectedId});
        return (
            <ListItem
                key={index}
                role="option"
                className={classes}
                onClick={() => {
                    setSelectedId(item.id);
                }}
                onKeyDown={(e) => handleKeyDown(item, e)}
            >
                <span className="primary-text">{item.primary}</span>
                {item.secondary && (
                    <Button
                        kind="secondary"
                        icon="icon-download-light-outline"
                        className="my-3"
                        style={{width: "200px"}}
                        loading={showLoadingOnType.includes(item.secondary)}
                        onClick={() => exportTaxCalculation(item.secondary)}
                    >
                        Download
                    </Button>
                )}
            </ListItem>
        );
    };

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <div className="table-exports h-auto">
                    <PagePanel
                        title={trh("ExportsLabel")}
                        buttons={
                            <Button
                                compact={true}
                                onClick={() => navigate(routerParamsHelper(RoutingTable.entity_tax_form, uriParams))}
                            >
                                Edit Tax Form
                            </Button>
                        }
                    >
                        <List
                            itemKey="id"
                            bordered={true}
                            data={data}
                            renderItem={renderItem}
                            width={420}
                            height={350}
                            style={{display: "inline-block", margin: 0}}
                        ></List>
                    </PagePanel>
                    {renderLink}
                </div>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default ExportBase;
