import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import Layout from "../../Layout/Layout";
import {HeaderType} from "../../../types/HeaderType";
import {Panel, Button} from "@appkit4/react-components";
import {useNavigate} from "react-router";
import BackButton from "../../../misc/BackButton";

declare type PropTypes = {};

const AdminFixtures: React.FC<PropTypes> = (props: PropTypes) => {
    const navigate = useNavigate();

    return (
        <Layout headerType={HeaderType.Blank}>
            <CanShowAdminMiddleware>
                <h2>
                    Fixtures
                    <BackButton url="/settings" className="float-end" />
                </h2>

                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <Panel title="Rulesets">
                            Add & Update initial rulesets
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/ruleset`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Panel title="Ruleset Forms">
                            Add & Update initial ruleset forms
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/rulesetform`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Panel title="Forms">
                            Add & Update initial form rows
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/formrow`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Panel title="Questionnaires">
                            Add & Update Questions in questionnaires
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/question`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Category group">
                            Add & Update category groups
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/categorygroup`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Category">
                            Add & Update category
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/category`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Ruleset Category pairs">
                            Add & Update Ruleset Category pairs
                            <Button
                                className="ms-auto"
                                onClick={() => navigate(`/settings/fixtures/rulesetcategorypairs`)}
                            >
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Tax Treatment types">
                            Add & Update Treatment types
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/taxtreatmenttype`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Tag for Ruleset">
                            Add & Update Tags for Rulesets
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/tagforruleset`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Tag">
                            Add & Update Tag
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures/tag`)}>
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Adjustment Default">
                            Add & Update Adjustment default
                            <Button
                                className="ms-auto"
                                onClick={() => navigate(`/settings/fixtures/adjustmentdefault`)}
                            >
                                Go
                            </Button>
                        </Panel>
                    </div>
                    <div className="col-md-3 col-sm-6 pt-3">
                        <Panel title="Adjustment Default Ruleset">
                            Add & Update Adjustment default ruleset
                            <Button
                                className="ms-auto"
                                onClick={() => navigate(`/settings/fixtures/adjustmentdefaultruleset`)}
                            >
                                Go
                            </Button>
                        </Panel>
                    </div>
                </div>
            </CanShowAdminMiddleware>
        </Layout>
    );
};

export default AdminFixtures;
