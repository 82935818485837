import {getCookie} from "./getCookie";
import i18n from "./i18n";

// Example: http://localhost:8000/api/v1
const BACKEND_URI = process.env.REACT_APP_BACKEND_URL! + process.env.REACT_APP_API_VERSION!;
const AUTH_URI = process.env.REACT_APP_AUTH_URL!;
const BASE_BACKEND_URI = process.env.REACT_APP_BACKEND_URL!;

export const get_base_backend_uri = () => BASE_BACKEND_URI;
export const get_api_backend_uri = () => BACKEND_URI;

export const isValidURL = (url) => {
    try {
        new URL(url);
        return true;
    } catch (error) {
        return false;
    }
};

export const safeJsonFetch = async (path: string, init?: RequestInit) => {
    // Validate the input path to prevent SSRF attacks
    if (!isValidURL(path)) {
        throw new Error("Invalid URL");
    }

    const url = new URL(path);
    if (url.protocol !== "http:" && url.protocol !== "https:") {
        throw new Error("Invalid protocol");
    }

    if (url.origin !== process.env.REACT_APP_BACKEND_URL) {
        throw new Error("Invalid backend URL");
    }

    // Perform the fetch request with the sanitized URL
    const response = await fetch(url.toString(), {
        ...init,
        headers: {
            ...init?.headers,
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken") + "",
        },
    });

    return response;
};
export const boolPromiseResolver = (p: Promise<any>) => p.then(() => true).catch(() => false);

export const safeBackendFetch = async (path: string, init?: any, accessToken?: string) => {
    let headerParams = {
        "Content-Type": "application/json",
    };

    if (init) init["credentials"] = process.env.REACT_APP_CREDENTIALS ? process.env.REACT_APP_CREDENTIALS : undefined;
    if (accessToken) headerParams["Authorization"] = `Bearer ${accessToken}`;
    let CSRFToken = getCookie("csrftoken");
    if (CSRFToken) {
        headerParams["X-CSRFToken"] = CSRFToken;
    }

    if (i18n.language) headerParams["X-Lang"] = i18n.language;

    let response = safeJsonFetch(`${BACKEND_URI}${path}`, {
        ...init,
        headers: {...init?.headers, ...headerParams},
    });

    //console.log((await response).status);

    return response;
};

export const safeAuthFetch = async (path: string, init?: RequestInit) => {
    let modifiedPath = `${AUTH_URI}${path}`;
    return safeJsonFetch(isValidURL(modifiedPath) ? modifiedPath : "", init);
};
