import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Table, Column} from "@appkit4/react-components/table";
import {DjangoDefaultPaginationType} from "../../../types/DjangoDefaultPaginationType";
import {Pagination} from "@appkit4/react-components/pagination";
import {useFixtureStore} from "../../../stores/FixtureStore";
import {Category} from "../../../types/Category";
import CategoryForm from "./Forms/CategoryForm";

declare type PropTypes = {};

interface PaginationType extends DjangoDefaultPaginationType {
    results: Category[];
}

const AdminFixtureCategory: React.FC<PropTypes> = (props: PropTypes) => {
    const [data, setData] = React.useState<Category[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [countPerPage, setCountPerPage] = React.useState<number>(100);
    const [current, setCurrent] = React.useState(1);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [dataAreLoading, setDataAreLoading] = React.useState<boolean>(false);

    const {fixtureState, init, rulesets, forms} = useFixtureStore((s) => ({
        fixtureState: s,
        init: s.init,
        rulesets: s.rulesets,
        forms: s.forms,
    }));

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    React.useEffect(() => {
        init();
    }, [init]);

    const onPageChange = (page: number) => {
        setCurrent(page);
        setDataAreLoading(true);
    };

    React.useEffect(() => {
        if (dataAreLoaded === false || dataAreLoading === true) {
            fetchApi(`/settings/_/fixtures/adjustmentcategory/?page=${current}`)
                .then((r) => r.json())
                .then((res: PaginationType) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setData(res.results);
                        setTotalCount(res.count);
                    }
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                });
        }
    }, [dataAreLoaded, addErrorNotification, fetchApi, dataAreLoading, current]);

    const renderActionCell = (row) => {
        return (
            <>
                <CategoryForm
                    key={`edit-${row.id}`}
                    categoryGroup={row}
                    onSaveHandler={() => setDataAreLoaded(false)}
                />
            </>
        );
    };

    const pagination = (
        <Pagination
            current={current}
            total={Math.ceil(totalCount / countPerPage)}
            onPageChange={onPageChange}
        ></Pagination>
    );

    return (
        <CanShowAdminMiddleware>
            {fixtureState.rulesetsAreLoaded && fixtureState.formsAreLoaded && dataAreLoaded && (
                <div className="container-fluid">
                    {pagination}
                    <Table originalData={data} className="table-responsive" hasTitle striped condensed>
                        <Column field="id" sortKey="id" slot="end">
                            ID
                        </Column>
                        <Column field="id" title={"Action"} renderCell={renderActionCell}>
                            Action
                        </Column>
                        <Column field="name" sortKey="name">
                            Name
                        </Column>
                        <Column field="name_sk" sortKey="name_sk">
                            Name SK
                        </Column>
                        <Column field="category_group" sortKey="category_group">
                            Category Group
                        </Column>
                        <Column
                            field="default_deferred_tax_classification"
                            sortKey="default_deferred_tax_classification"
                        >
                            Default Deferred Tax Classification
                        </Column>
                        <Column field="default_generic_comment_en" sortKey="default_generic_comment_en">
                            Default Generic Comment en
                        </Column>
                        <Column field="default_generic_comment_sk" sortKey="default_generic_comment_sk">
                            Default Generic Comment sk
                        </Column>
                        <Column field="coefficient" sortKey="coefficient">
                            Coefficient
                        </Column>
                        <Column field="priority" sortKey="priority">
                            Priority
                        </Column>
                        <Column field="adjustment_type" sortKey="adjustment_type">
                            Adjustment Type
                        </Column>
                        <Column
                            sortKey="for_release"
                            renderCell={(row) => {
                                return <span>{row.for_release.toString()}</span>;
                            }}
                        >
                            For Release
                        </Column>
                        <Column
                            field="for_creation"
                            sortKey="for_creation"
                            renderCell={(row) => {
                                return <span>{row.for_creation.toString()}</span>;
                            }}
                        >
                            For Creation
                        </Column>
                        <Column field="for_adjustment_entry_tax_type" sortKey="for_adjustment_entry_tax_type">
                            For Adjustment Entry Tax Type
                        </Column>
                        <Column
                            field="increase_tax_base"
                            sortKey="increase_tax_base"
                            renderCell={(row) => {
                                return <span>{row.increase_tax_base.toString()}</span>;
                            }}
                        >
                            Increase Tax Base
                        </Column>
                        <Column field="calculation_step" sortKey="calculation_step">
                            Calculation Step
                        </Column>
                    </Table>
                    {pagination}
                </div>
            )}
            <CategoryForm onSaveHandler={() => setDataAreLoaded(false)} />
        </CanShowAdminMiddleware>
    );
};

export default AdminFixtureCategory;
