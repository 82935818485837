import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {RoutingTable} from "../Constants";
import PageNotFound from "./PageNotFound/PageNotFound";
import {useGlobalStore} from "../stores/GlobalStore";
import * as O from "fp-ts/Option";
import LoadingContainer, {CircularWidth} from "../misc/LoadingContainer";
import {default as CalculationSettings} from "./Calculation/Settings";
import Summary from "./Calculation/Summary";
import Import from "./Calculation/Import";
import Questionnaire from "./Calculation/Questionnaire";
import CreateCalculation from "./Calculation/Create";
import MyClients from "./Client/MyClients";
import CreateClient from "./Client/CreateClient";
import TrialBalance from "./Calculation/TrialBalance";
import TaxFlows from "./Calculation/TaxFlows";
import TaxBase from "./Calculation/TaxBase";
import ReviewBase from "./Calculation/ReviewBase";
import ExportBase from "./Calculation/ExportBase";
import {default as SettingsClientSpecific} from "./Settings/ClientSpecific";
import {default as SettingsEntitySpecific} from "./Settings/EntitySpecific";
import EntityInfo from "./Calculation/EntityInfo";
import AdjustmentEntries from "./Calculation/AdjustmentEntries";
import Attachments from "./Calculation/Attachments";
import {getCookie} from "../utils/getCookie";
import {ShowHomepage} from "../misc/ShowHomepage";
import LoginPage from "../misc/LoginPage";
import {useAuth} from "react-oidc-context";
import CalculationForm from "./Calculation/CalculationForm";
import DeferredTaxBase from "./Calculation/DeferredTax/DeferredTaxBase";
import AdjustmentDetail from "./Calculation/AdjustmentDetail";
import FilterForm from "./Calculation/Adjustment/FilterForm";
import AdminDashboard from "./Admin/AdminDashboard";
import AdminFixtures from "./Admin/Fixtures/AdminFixtures";
import AdminFixtureByType from "./Admin/Fixtures/AdminFixtureByType";

// TODO: Is logged in and has valid token => send to dashboard. Load while trying to login. Elsewhere, send to login
const App: React.FC = () => {
    // Declare stores
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const userInfo = useGlobalStore((s) => s.userInfo);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const initCSRFtoken = useGlobalStore((s) => s.initCSRFtoken);
    const loadCodebooks = useGlobalStore((s) => s.loadCodebooks);
    const [CSRFtokenIsLoaded, setCSRFtokenIsLoaded] = React.useState<boolean>(false);

    // Load data into stores
    const [usersLoaded, setUsersLoaded] = React.useState<boolean>(false);
    const [codebooksLoaded, setCodebooksLoaded] = React.useState<boolean>(false);
    const setAccessToken = useGlobalStore((s) => s.setAccessToken);

    const auth = useAuth();

    React.useEffect(() => {
        if (accessToken) {
            //setAccessToken(accessToken);
            loadUserInfo().then(() => setUsersLoaded(true));
        }
    }, [loadUserInfo, accessToken]);

    React.useEffect(() => {
        if (O.isSome(userInfo) && accessToken) loadCodebooks(accessToken).then(() => setCodebooksLoaded(true));
    }, [loadCodebooks, userInfo, accessToken]);

    React.useEffect(() => {
        if (CSRFtokenIsLoaded === false) {
            initCSRFtoken().then(() => {
                setCSRFtokenIsLoaded(true);
                getCookie("csrftoken");
            });
        }
    }, [CSRFtokenIsLoaded]);

    return (
        <Router>
            {usersLoaded && codebooksLoaded && O.isSome(userInfo) && userInfo.value.backend_info !== undefined ? (
                <Routes>
                    <Route path={RoutingTable.my_clients} element={<MyClients />} />
                    <Route path={RoutingTable.client_create} element={<CreateClient />} />
                    <Route path={RoutingTable.entity_new_calculation} element={<CreateCalculation />} />
                    <Route path={RoutingTable.entity_base} element={<Summary />} />
                    <Route path={RoutingTable.entity_summary} element={<Summary />} />
                    <Route path={RoutingTable.entity_info} element={<EntityInfo />} />
                    <Route path={RoutingTable.entity_questionnaire} element={<Questionnaire />} />
                    <Route path={RoutingTable.entity_settings} element={<CalculationSettings />} />
                    <Route path={RoutingTable.entity_import_base} element={<Import />} />
                    <Route path={RoutingTable.entity_import_trial_balance_base} element={<Import />} />
                    <Route path={RoutingTable.entity_import_trial_balance_file} element={<Import />} />
                    <Route path={RoutingTable.entity_import_trial_balance_adjustments} element={<Import />} />
                    <Route path={RoutingTable.entity_import_trial_balance_column_mapping} element={<Import />} />
                    <Route path={RoutingTable.entity_import_journal_base} element={<Import />} />
                    <Route path={RoutingTable.entity_import_journal_file} element={<Import />} />
                    <Route path={RoutingTable.entity_import_journal_column_mapping} element={<Import />} />
                    <Route path={RoutingTable.entity_import_data_check} element={<Import />} />
                    <Route path={RoutingTable.entity_trial_balance} element={<TrialBalance />} />
                    <Route path={RoutingTable.entity_tax_flows} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_accounting_result} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_journal_adjustments} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_manual_adjustments} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_taxable_result_adjustments} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_fixed_assets_adjustments} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_provisions} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_valuation_allowances} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_cash_basis} element={<TaxFlows />} />
                    <Route path={RoutingTable.entity_tax_flows_helpful} element={<TaxFlows />} />

                    <Route path={RoutingTable.entity_tax_flows_adjustment} element={<AdjustmentDetail />} />
                    <Route path={RoutingTable.entity_tax_flows_adjustment_filter} element={<FilterForm />} />
                    <Route path={RoutingTable.entity_tax_flows_adjustment_entries} element={<AdjustmentEntries />} />
                    <Route path={RoutingTable.entity_tax_base} element={<TaxBase />} />
                    <Route path={RoutingTable.entity_review_base} element={<ReviewBase />} />
                    <Route path={RoutingTable.entity_tax_form} element={<CalculationForm />} />
                    <Route path={RoutingTable.entity_export_base} element={<ExportBase />} />
                    <Route path={RoutingTable.calculation_attachments} element={<Attachments />} />
                    <Route path={RoutingTable.calculation_deferred_tax} element={<DeferredTaxBase />} />
                    <Route path={RoutingTable.calculation_deferred_tax_prior_entries} element={<DeferredTaxBase />} />
                    <Route path={RoutingTable.calculation_deferred_tax_current_entries} element={<DeferredTaxBase />} />
                    <Route path={RoutingTable.calculation_deferred_tax_result} element={<DeferredTaxBase />} />
                    <Route path={RoutingTable.settings_clients_specific} element={<SettingsClientSpecific />} />
                    <Route path={RoutingTable.settings_entities_specific} element={<SettingsEntitySpecific />} />
                    <Route path={RoutingTable.page_not_found_specific} element={<PageNotFound />} />
                    <Route path={RoutingTable.oidc_login_callback} element={<ShowHomepage />} />
                    <Route path={RoutingTable.page_not_found_global} element={<PageNotFound />} />

                    {/* <Route path={RoutingTable.page_not_found_global} element={<FilterComponent />} /> */}

                    <Route path={RoutingTable.settings_dashboard} element={<AdminDashboard />} />
                    <Route path={RoutingTable.settings_fixtures} element={<AdminFixtures />} />
                    <Route path={RoutingTable.settings_fixtures_type} element={<AdminFixtureByType />} />
                </Routes>
            ) : accessToken === "" || typeof auth.user === "undefined" ? (
                // Problem with PwC Backend Access Token verification
                <LoginPage />
            ) : (
                <LoadingContainer circularWidthOverride={CircularWidth.big} />
            )}
        </Router>
    );
};

export default App;
