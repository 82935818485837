import React from "react";
import {Input, ListItem, Button, Avatar} from "@appkit4/react-components";
import {useTr} from "../../utils/trUtil";
import {Reviewer} from "../../types/Reviewer";
import {Calculation} from "../../types/Calculation";
import {Entity} from "../../types/Entity";
import {useGlobalStore} from "../../stores/GlobalStore";
import {getInitials} from "../../utils/nameFormatter";
import {avatarColor} from "../../utils/avatarColor";
import {Loading} from "@appkit4/react-components/loading";
import {validateEmail} from "../../utils/validateEmail";

declare type PropTypes = {
    type: "first" | "second";
    calculation: Calculation;
    entity: Entity;
    reviewers: Reviewer[];
};

const ReviewersManager: React.FC<PropTypes> = (props: PropTypes) => {
    const formTr = useTr("form");
    const trg = useTr("global");

    const [reviewers, setReviewers] = React.useState<Reviewer[]>();
    const [email, setEmail] = React.useState<string>("");
    const [hasError, setHasError] = React.useState<boolean>(false);

    const fetch = useGlobalStore((s) => s.fetchApi);
    const loadReviewers = useGlobalStore((s) => s.loadReviewers);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [runLoader, setRunLoader] = React.useState<boolean>(false);

    const REVIEWER1_CLAIM = "reviewer1";
    const REVIEWER2_CLAIM = "reviewer2";

    React.useEffect(() => {
        if (props.reviewers) {
            setReviewers(
                props.reviewers.filter(
                    (r) =>
                        r.claim_value === `${props.entity.hash}:${props.calculation.hash}` &&
                        r.claim_type ===
                            `user_entity:calculation:${
                                props.type === "first"
                                    ? REVIEWER1_CLAIM
                                    : props.type === "second"
                                    ? REVIEWER2_CLAIM
                                    : ""
                            }`
                )
            );
        }
    }, [props]);

    const emailValidator = () => {
        if (email.length > 0) setHasError(validateEmail(email) ? false : true);
    };

    const addReviewerHandler = (): void => {
        if (hasError || email === "") return;
        let data = [{email: email, type: props.type}];
        setRunLoader(true);
        fetch(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/reviewers/`, "post", data)
            .then((res) => res.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                    setHasError(true);
                } else {
                    loadReviewers();
                    setEmail("");
                    setHasError(false);
                }
                setRunLoader(false);
            })
            .catch((error) => {
                addErrorNotification(error);
                setRunLoader(false);
            });
    };

    const deleteReviewer = (reviewer: Reviewer) => {
        let body = {email: reviewer.user.email, claim_type: reviewer.claim_type, claim_value: reviewer.claim_value};
        setRunLoader(true);
        fetch(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/reviewers/`, "delete", body)
            .then((res) => res.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                    setHasError(true);
                } else {
                    loadReviewers();
                    setEmail("");
                    setHasError(false);
                }
                setRunLoader(false);
            })
            .catch((error) => {
                addErrorNotification(error);
                setRunLoader(false);
            });
    };

    const renderItem = React.useCallback((item: any, index: string) => {
        const initials = getInitials(
            item.user.first_name ? item.user.first_name + " " + item.user.last_name : item.user.email
        );
        return (
            <ListItem
                key={index + ""}
                role="option"
                title={
                    <div className="small" style={{lineHeight: "1.2rem"}}>
                        {item.user.email}
                        <br />
                        {item.user.first_name} {item.user.last_name}
                    </div>
                }
                avatar={
                    <div className="ap-list-avatar-container" role="option">
                        <Avatar
                            style={{width: "34px", height: "34px"}}
                            label={initials}
                            bgColor={`#${avatarColor(initials)}`}
                        ></Avatar>
                    </div>
                }
            >
                <span>
                    <span className="Appkit4-icon icon-delete-outline" onClick={() => deleteReviewer(item)}></span>
                </span>
            </ListItem>
        );
    }, []);

    return (
        <div>
            <div className="d-flex">
                <div className="flex-grow-1">
                    <Input
                        type="text"
                        title="Add new reviewer"
                        error={hasError}
                        errorNode={
                            <div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">
                                {trg("please_enter_a_valid_email_adress")}{" "}
                            </div>
                        }
                        value={email}
                        onChange={(v) => {
                            setEmail(v);
                            setHasError(false);
                        }}
                        onBlur={emailValidator}
                        placeholder={formTr("typeEmail")}
                    />
                </div>
                <div className="mt-2" style={{paddingLeft: "10px"}}>
                    <Button
                        kind="tertiary"
                        className="small"
                        style={{width: "70px"}}
                        compact
                        icon="icon-plus-outline"
                        onClick={addReviewerHandler}
                    >
                        {trg("add")}
                    </Button>
                </div>
            </div>
            {reviewers && reviewers.length > 0 ? (
                <div className="my-1" style={{width: "331px", display: "inline-block"}}>
                    {reviewers.map((item) => renderItem(item, item.user.email))}
                </div>
            ) : (
                ""
            )}
            {reviewers && reviewers.length === 0 && (
                <small className="text-muted px-2">{trg("list_of_reviewers_is_empty")}</small>
            )}
            {runLoader && <Loading loadingType="circular" indeterminate={true} compact={true}></Loading>}
        </div>
    );
};

export default ReviewersManager;
