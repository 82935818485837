import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Input, Button} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {Form} from "../../../../types/Form";

declare type PropTypes = {
    form: Form;
    className?: string;
    onCopyHandler?: (new_form: Form) => void;
    disabled?: boolean;
};

const ButtonCopyFormObject: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [data, setData] = React.useState<Form>({
        id: 0,
        name: props.form.name,
        name_sk: props.form.name_sk,
        name_en: props.form.name,
        xsd_schema_link: props.form.xsd_schema_link,
        xsd_schema_path: props.form.xsd_schema_path,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const copyHandler = () => {
        fetchApi(`/settings/_/fixtures/form/${props.form.id}/copy/`, "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onCopyHandler !== "undefined") props.onCopyHandler(res);
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Button kind="secondary" className={props.className} compact onClick={() => setVisible(true)}>
                Copy selected form
            </Button>
            <Modal
                visible={visible}
                title={`Form: ${props.form.name}`}
                ariaLabel={`Form: ${props.form.name}`}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={<Badge type="success" value="Copying"></Badge>}
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            Cancel
                        </Button>
                        <Button onClick={copyHandler} loading={isLoading}>
                            Confirm & Copy
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <Input
                            type={"text"}
                            title={"New Name"}
                            value={data.name}
                            required
                            onChange={(val: string) => setData((s: any) => ({...s, name: val}))}
                        ></Input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <Input
                            type={"text"}
                            title={"New Name SK"}
                            value={data.name_sk}
                            required
                            onChange={(val: string) => setData((s: any) => ({...s, name_sk: val}))}
                        ></Input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <Input
                            type={"text"}
                            title={"New XSD Schema Link"}
                            value={data.xsd_schema_link}
                            required
                            onChange={(val: string) => setData((s: any) => ({...s, xsd_schema_link: val}))}
                        ></Input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-3 mb-5">
                        <Input
                            type={"text"}
                            title={"New XSD Schema Path"}
                            value={data.xsd_schema_path}
                            required
                            onChange={(val: string) => setData((s: any) => ({...s, xsd_schema_path: val}))}
                        ></Input>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ButtonCopyFormObject;
