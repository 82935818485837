// Keywords
export const THEME_KEYWORD = "theme";

// Routing
export const ClientHashParamName = "clientHash";
export const EntityHashParamName = "entityHash";
export const CalculationHashParamName = "calculationHash";
export const AdjustmentHashParamName = "adjHash";

export const adjustment_types = {
    ACCOUNTING_RESULT: "accounting-result",
    JOURNAL: "journal",
    MANUAL: "manual",
    TAXABLE_RESULT: "taxable_result",
    FIXED_ASSETS: "fixed_assets",
    PROVISIONS: "provisions",
    VALUATION_ALLOWANCES: "valuation_allowances",
    CASH_BASIS: "cash_basis",
    HELPFUL: "helpful",
};

export const adjustment_tax_types = {
    BS: "BS",
    TD: "TD",
    TNDT: "TND-T",
    TNDP: "TND-P",
    TNDU: "TND-U",
    COMPENS_FEES: "Compens. fees",
    RENT: "Rent",
    MARKET_OTH_STUDIES: "Market./Oth. studies",
    INTERMEDIARY_FEES: "Intermediary fees",
    FEES_NONTREAT_CTRY: "Fees non-treat. ctry.",
    PROF_SERVICES: "Prof. services",
    CONTR_PENALTIES: "Contr. penalties",
    SPONSORING: "Sponsoring",
    AD_BY_NONPROFIT_ORG: "Ad. by non-profit org.",
    NONLIFE_INS_TAX: "Non-life ins. tax",
    OTHER_CASH_BASIS: "Other cash basis",
};

const base_calculation = `/calculation/:${EntityHashParamName}/:${CalculationHashParamName}`;
export const RoutingTable = {
    my_clients: "/",

    client_create: "/client/create",

    entity_new_calculation: `/calculation/:${EntityHashParamName}/new`,

    entity_base: `${base_calculation}`, // TODO: Redirect na entity summary
    entity_summary: `${base_calculation}/summary`,
    entity_settings: `${base_calculation}/settings`,
    entity_info: `${base_calculation}/info`,

    entity_import_base: `${base_calculation}/import`, // TODO: Bude dělat rozhodnutí o tom, kam dá redirect na základě toho co je/není nahranýho
    entity_import_trial_balance_base: `${base_calculation}/import/trial-balance`, // TODO: Bude redirectovat na file nebo collumn mapping na základě toho co už uživatel udělal
    entity_import_trial_balance_file: `${base_calculation}/import/trial-balance/file`,
    entity_import_trial_balance_column_mapping: `${base_calculation}/import/trial-balance/column-mapping`,
    entity_import_trial_balance_adjustments: `${base_calculation}/import/trial-balance/adjustments`,

    entity_import_journal_base: `${base_calculation}/import/journal`, // TODO: Bude redirectovat na file nebo collumn mapping na základě toho co už uživatel udělal
    entity_import_journal_file: `${base_calculation}/import/journal/file`,
    entity_import_journal_column_mapping: `${base_calculation}/import/journal/column-mapping`,

    entity_import_data_check: `${base_calculation}/import/data-check`,

    entity_questionnaire: `${base_calculation}/questionnaire`,

    entity_trial_balance: `${base_calculation}/trial-balance`,

    // TODO: Get info about tax flows
    entity_tax_flows: `${base_calculation}/tax-flows`, // TODO: Redirect to first tax flows group
    entity_tax_flows_accounting_result: `${base_calculation}/tax-flows/${adjustment_types.ACCOUNTING_RESULT}`,
    entity_tax_flows_journal_adjustments: `${base_calculation}/tax-flows/${adjustment_types.JOURNAL}`,
    entity_tax_flows_manual_adjustments: `${base_calculation}/tax-flows/${adjustment_types.MANUAL}`,
    entity_tax_flows_taxable_result_adjustments: `${base_calculation}/tax-flows/${adjustment_types.TAXABLE_RESULT}`,
    entity_tax_flows_fixed_assets_adjustments: `${base_calculation}/tax-flows/${adjustment_types.FIXED_ASSETS}`,
    entity_tax_flows_provisions: `${base_calculation}/tax-flows/${adjustment_types.PROVISIONS}`,
    entity_tax_flows_valuation_allowances: `${base_calculation}/tax-flows/${adjustment_types.VALUATION_ALLOWANCES}`,
    entity_tax_flows_cash_basis: `${base_calculation}/tax-flows/${adjustment_types.CASH_BASIS}`,
    entity_tax_flows_helpful: `${base_calculation}/tax-flows/${adjustment_types.HELPFUL}`,

    entity_tax_flows_adjustment: `${base_calculation}/tax-flows/adjustment/:id`,
    entity_tax_flows_adjustment_filter: `${base_calculation}/tax-flows/adjustment/:adjustment_id/filter/:id`,
    entity_tax_flows_adjustment_entries: `${base_calculation}/tax-flows/adjustment-entries/:type/:entry_id?`,

    entity_tax_base: `${base_calculation}/tax-base`,

    entity_tax_form: `${base_calculation}/form`,

    entity_calculation_questionnaire: `${base_calculation}/questionnaire`,

    entity_review_base: `${base_calculation}/review`,
    entity_review_specific: `${base_calculation}/review/:${AdjustmentHashParamName}`,
    entity_review_specific_transactions: `${base_calculation}/review/:${AdjustmentHashParamName}/transactions`,

    entity_export_base: `${base_calculation}/export`,
    entity_export_tax_calculation_xls: `${base_calculation}/tax-calculation-xls`,
    entity_export_tax_form_pdf: `${base_calculation}/tax-form-pdf`,
    entity_export_tax_form_xml: `${base_calculation}/tax-form-xml`,
    entity_export_memory_report_xls: `${base_calculation}/memory-report-xls`,
    entity_export_dt_figures_nk: `${base_calculation}/dt-figures-nk`,

    settings_notifications: "/settings/notifications",
    settings_clients: "/settings/clients",
    settings_clients_specific: `/settings/clients/:${ClientHashParamName}`,
    settings_entities: "/settings/entities",
    settings_entities_specific: `/settings/entities/:${EntityHashParamName}`,
    settings_extended: "/settings/extended",

    calculation_attachments: `${base_calculation}/attachments`,
    calculation_deferred_tax: `${base_calculation}/deferred-tax`,
    calculation_deferred_tax_prior_entries: `${base_calculation}/deferred-tax/prior-entries`,
    calculation_deferred_tax_current_entries: `${base_calculation}/deferred-tax/current-entries`,
    calculation_deferred_tax_result: `${base_calculation}/deferred-tax/result`,

    //* Access only with staff permissions */
    settings_dashboard: `/settings`,
    settings_fixtures: `/settings/fixtures`,
    settings_fixtures_type: `/settings/fixtures/:type`,

    page_not_found_specific: "/not/found",
    oidc_login_callback: "/oidc/login/callback",
    page_not_found_global: "*",
};

export const ClaimTypes = {
    CLIENT: "user_client",
    ENTITY: "user_entity",
    ENTITY_CALCULATION: "user_entity:calculation",
    ENTITY_CALCULATION_REVIEWER1: "user_entity:calculation:reviewer1",
    ENTITY_CALCULATION_REVIEWER2: "user_entity:calculation:reviewer2",
    ENTITY_CALCULATION_ATTACHMENTS: "user_entity:calculation:attachments",
};

export const CALCULATION_STATUS = {
    CREATED: 1,
    TRIAL_BALANCE_UPLOADED: 2,
    TRIAL_BALANCE_RUNNING_IMPORT: 3,
    TRIAL_BALANCE_IMPORTED: 4,
    JOURNAL_UPLOADED: 5,
    JOURNAL_RUNNING_IMPORT: 6,
    JOURNAL_IMPORTED: 7,
    TRIAL_BALANCE_ADJUSTMENT: 8,
    DATA_CHECKED: 9,
    FINISHED: 10,
    CANCELLED: 11,
    JOURNAL_IMPORT_SKIPPED: 12,
};

export const FORM_ROW = {
    ROW_TYPE: {
        ADJUSTMENT_TOTALING: "adjustment_totaling",
        CALCULATION_DATA: "calculation_data",
        CHOICE: "choice",
        ENTITY_DATA: "entity_data",
        FORM_DATA: "form_data",
        FORMULA: "formula",
        OBJECT: "object",
    },
    DATA_TYPE: {
        DATE_DDMMYYYY: "date|dd.mm.yyyy",
        DECIMAL: "decimal",
        INTEGER: "integer",
        INTEGER_2_DIGITS: "integer_2_digits",
        OBJECT: "object",
        STRING: "string",
    },
    INPUT_TYPE: {
        CHECKBOX: "checkbox",
        DATE: "date",
        RADIOBUTTON: "radiobutton",
        RADIOGROUP: "radigroup",
        SELECT: "select",
        TEXT: "text",
        TEXTAREA: "textarea",
    },
    HTML_TYPE: {
        FLUID: "fluid",
        H2: "h2",
        PANEL: "panel",
        TABLEBOX: "tablebox",
    },
    SIGN_TYPE: {
        SIGN: "sign",
        OPPOSITE_SIGN: "opposite_sign",
    },
    SHOW_TYPE: {
        ZERO_IF_POSITIVE: "zero_if_positive",
        ZERO_IF_NEGATIVE: "zero_if_negative",
    },
};

export const ALLOWED_FIXTURES = [
    "form",
    "formrow",
    "ruleset",
    "rulesetform",
    "adjustmentcategory",
    "adjustmentcategoryruleset",
    "questionnaire",
    "question",
    "questionnairequestion",
    "file_template",
    "questionview",
    "questiongroup",
    "categorygroup",
    "rulesetcategorypairs",
    "taxtreatmenttype",
    "tagforruleset",
    "tag",
    "adjustmentdefault",
    "adjustmentdefaultruleset",
];

export const QuestionTypes = [
    "checkbox",
    "custom_component",
    "date",
    "hyperlink",
    "info",
    "input",
    "number",
    "radiobutton",
    "table",
    "textarea",
];

// Shared variables
export const AllAcceptableChars = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";
export const DEFAULT_KEYWORD = "default";

// Well known endpoints
export const PwCAuthKnownEndpoints = {
    user_info: process.env.REACT_APP_PWC_IDENTITY_AUTHORITY + "/userinfo",
    access_token: process.env.REACT_APP_PWC_IDENTITY_AUTHORITY + "/access_token",
};

// Well known endpoints and third party configurations
export const AzureAuthKnownEndpoints = {
    user_info: "/.auth/me",
    refresh_token: "/.auth/refresh",
    logout: "/.auth/logout",
};

export const PwcWellKnowOpenIDConfiguration = {
    request_parameter_supported: true,
    claims_parameter_supported: false,
    introspection_endpoint: "https://login-stg.pwc.com:443/openam/oauth2/introspect",
    check_session_iframe: "https://login-stg.pwc.com:443/openam/oauth2/connect/checkSession",
    scopes_supported: [
        "stateorprovince",
        "country",
        "countrycode",
        "pwcstreet2",
        "pwclocationcode",
        "pwcstreet3",
        "pwcstreet4",
        "employeeNumber",
        "staffnumber",
        "PwChrGivenName",
        "pwcGlobalGradeAbbreviatedName",
        "pwcIdentityAssuranceLevel",
        "cloudEmail",
        "PwCStaffClass",
        "openid",
        "profile",
        "locality",
        "aduidscope",
        "pwcGlobalGradeName",
        "PwCJobCodeDesc",
        "pwcLocalLOSLevel2",
        "pwcLocalLOSLevel1",
        "pwcLocalLOSLevel4",
        "LOS",
        "pwcLocalLOSLevel3",
        "businessunit",
        "phone",
        "windowsaccountname",
        "preferredLanguage",
        "GlobalGradeCode",
        "uid",
        "postalcode",
        "street",
        "PwChrFamilyName",
        "offline_access",
        "upwcjobtitle",
        "costcenter",
        "PwCLegacyEmployeeNumber",
        "pwcPartyID",
        "email",
        "pwcbudesc",
        "PwCemployeeStatus",
        "employeetype",
        "notesDN",
        "address",
        "jobtitle",
        "PwClos",
        "ms-ds-consistency-guid",
        "mobile",
        "groups",
        "middlename",
        "pwcWorkerID",
        "PwCassignedofficecode",
        "preferredMail",
        "organization",
        "pwcnotesdnabbreviated",
        "upwcdepartment",
        "PwCPPI",
        "uPwCLegacyEmployeeNumber",
    ],
    issuer: "https://login-stg.pwc.com:443/openam/oauth2",
    id_token_encryption_enc_values_supported: [
        "A256GCM",
        "A192GCM",
        "A128GCM",
        "A128CBC-HS256",
        "A192CBC-HS384",
        "A256CBC-HS512",
    ],
    acr_values_supported: ["1", "2", "3", "4", "5", "6", "7", "8"],
    userinfo_encryption_enc_values_supported: [
        "A256GCM",
        "A192GCM",
        "A128GCM",
        "A128CBC-HS256",
        "A192CBC-HS384",
        "A256CBC-HS512",
    ],
    authorization_endpoint: "https://login-stg.pwc.com:443/openam/oauth2/authorize",
    request_object_encryption_enc_values_supported: [
        "A256GCM",
        "A192GCM",
        "A128GCM",
        "A128CBC-HS256",
        "A192CBC-HS384",
        "A256CBC-HS512",
    ],
    rcs_request_encryption_alg_values_supported: [
        "RSA-OAEP",
        "RSA-OAEP-256",
        "A128KW",
        "RSA1_5",
        "A256KW",
        "dir",
        "A192KW",
    ],
    claims_supported: [
        "stateorprovince",
        "zoneinfo",
        "pwcstreet2",
        "locale",
        "pwclocationcode",
        "pwcstreet3",
        "pwcstreet4",
        "postalcode",
        "street",
        "PwChrGivenName",
        "upwcjobtitle",
        "pwcGlobalGradeAbbreviatedName",
        "PwCLegacyEmployeeNumber",
        "email",
        "pwcbudesc",
        "PwCemployeeStatus",
        "pwcIdentityAssuranceLevel",
        "employeetype",
        "address",
        "jobtitle",
        "ms-ds-consistency-guid",
        "PwCStaffClass",
        "profile",
        "locality",
        "mobile",
        "middlename",
        "pwcGlobalGradeName",
        "pwcWorkerID",
        "given_name",
        "PwCJobCodeDesc",
        "pwcLocalLOSLevel2",
        "pwcLocalLOSLevel1",
        "pwcLocalLOSLevel4",
        "pwcLocalLOSLevel3",
        "phone",
        "name",
        "pwcnotesdnabbreviated",
        "phone_number",
        "upwcdepartment",
        "family_name",
        "windowsaccountname",
        "uPwCLegacyEmployeeNumber",
    ],
    userinfo_signing_alg_values_supported: ["ES384", "HS256", "HS512", "ES256", "RS256", "HS384", "ES512"],
    rcs_request_signing_alg_values_supported: ["ES384", "HS256", "HS512", "ES256", "RS256", "HS384", "ES512"],
    token_endpoint_auth_methods_supported: [
        "client_secret_post",
        "private_key_jwt",
        "self_signed_tls_client_auth",
        "tls_client_auth",
        "none",
        "client_secret_basic",
    ],
    tls_client_certificate_bound_access_tokens: true,
    token_endpoint: "https://login-stg.pwc.com:443/openam/oauth2/access_token",
    response_types_supported: [
        "code token id_token",
        "code",
        "code id_token",
        "device_code",
        "id_token",
        "code token",
        "token",
        "token id_token",
    ],
    revocation_endpoint_auth_methods_supported: [
        "client_secret_post",
        "private_key_jwt",
        "self_signed_tls_client_auth",
        "tls_client_auth",
        "none",
        "client_secret_basic",
    ],
    request_uri_parameter_supported: true,
    rcs_response_encryption_enc_values_supported: [
        "A256GCM",
        "A192GCM",
        "A128GCM",
        "A128CBC-HS256",
        "A192CBC-HS384",
        "A256CBC-HS512",
    ],
    userinfo_encryption_alg_values_supported: [
        "RSA-OAEP",
        "RSA-OAEP-256",
        "A128KW",
        "A256KW",
        "RSA1_5",
        "dir",
        "A192KW",
    ],
    grant_types_supported: [
        "implicit",
        "urn:ietf:params:oauth:grant-type:saml2-bearer",
        "refresh_token",
        "password",
        "client_credentials",
        "urn:ietf:params:oauth:grant-type:device_code",
        "authorization_code",
        "urn:openid:params:grant-type:ciba",
        "urn:ietf:params:oauth:grant-type:uma-ticket",
        "urn:ietf:params:oauth:grant-type:jwt-bearer",
    ],
    end_session_endpoint: "https://login-stg.pwc.com:443/openam/oauth2/connect/endSession",
    rcs_request_encryption_enc_values_supported: [
        "A256GCM",
        "A192GCM",
        "A128GCM",
        "A128CBC-HS256",
        "A192CBC-HS384",
        "A256CBC-HS512",
    ],
    revocation_endpoint: "https://login-stg.pwc.com:443/openam/oauth2/token/revoke",
    version: "3.0",
    rcs_response_encryption_alg_values_supported: [
        "RSA-OAEP",
        "RSA-OAEP-256",
        "A128KW",
        "A256KW",
        "RSA1_5",
        "dir",
        "A192KW",
    ],
    userinfo_endpoint: "https://login-stg.pwc.com:443/openam/oauth2/userinfo",
    token_endpoint_auth_signing_alg_values_supported: [
        "PS384",
        "ES384",
        "RS384",
        "HS256",
        "HS512",
        "ES256",
        "RS256",
        "HS384",
        "ES512",
        "PS256",
        "PS512",
        "RS512",
    ],
    require_request_uri_registration: true,
    id_token_encryption_alg_values_supported: [
        "RSA-OAEP",
        "RSA-OAEP-256",
        "A128KW",
        "A256KW",
        "RSA1_5",
        "dir",
        "A192KW",
    ],
    jwks_uri: "https://login-stg.pwc.com:443/openam/oauth2/connect/jwk_uri",
    subject_types_supported: ["public"],
    id_token_signing_alg_values_supported: ["ES384", "HS256", "HS512", "ES256", "RS256", "HS384", "ES512"],
    registration_endpoint: "https://login-stg.pwc.com:443/openam/oauth2/register",
    request_object_signing_alg_values_supported: ["ES384", "HS256", "HS512", "ES256", "RS256", "HS384", "ES512"],
    request_object_encryption_alg_values_supported: [
        "RSA-OAEP",
        "RSA-OAEP-256",
        "A128KW",
        "A256KW",
        "RSA1_5",
        "dir",
        "A192KW",
    ],
    rcs_response_signing_alg_values_supported: ["ES384", "HS256", "HS512", "ES256", "RS256", "HS384", "ES512"],
};

export const country_keys = {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia, Plurinational State of",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, The Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People's Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia, Republic of",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SS: "South Sudan",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarian Republic of",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
};

export const colors = [
    "d04a02",
    "eb8c00",
    "ffb600",
    "7d7d7d",
    "dedede",
    "295477",
    "299d8f",
    "4577c9",
    "00c6f7",
    "3dd5b0",
    "d93954",
    "657dff",
    "e72b33",
    "fedacc",
    "fdab8d",
    "fb7c4d",
    "e45c2b",
    "fbe8cc",
    "f7d199",
    "f3ba66",
    "efa333",
    "fff0cc",
    "ffdc8c",
    "ffc94c",
    "ffbd26",
    "e5e5e5",
    "cbcbcb",
    "b1b1b1",
    "979797",
    "f8f8f8",
    "f2f2f2",
    "ebebeb",
    "e5e5e5",
    "d4dde4",
    "9eb2c2",
    "69879f",
    "496d8b",
    "d4ebe9",
    "9ed3cc",
    "69bab0",
    "49aba0",
    "dae4f4",
    "c7d6ee",
    "a2bbe4",
    "7ca0d9",
    "ccf4fd",
    "b2edfc",
    "7fe2fb",
    "4cd7f9",
    "d8f7ef",
    "c4f2e7",
    "9ee9d7",
    "77e1c7",
    "f8dde1",
    "f1bac3",
    "e998a6",
    "e27588",
    "e0e5ff",
    "c1cbff",
    "a3b1ff",
    "8497ff",
    "fad5d6",
    "f5aaad",
    "f18085",
    "ec555c",
];
