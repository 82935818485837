import React from "react";
import Layout from "../Layout/Layout";
import {Panel, Input, Select} from "@appkit4/react-components";
import {Loading} from "@appkit4/react-components/loading";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {Button} from "@appkit4/react-components/button";
import {LayoutSettings} from "../Layout/LayoutSettings";
import {HeaderType} from "../../types/HeaderType";
import {Client} from "../../types/Client";
import {useTr} from "../../utils/trUtil";
import {ProvideRouteParams} from "../../utils/routeUtils";
import "./ClientSpecific.scss";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {useGlobalStore} from "../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {Notice} from "@appkit4/react-components/notification";
import {BackendUserInfo} from "../../types/UserInfo";
import {validateEmail} from "../../utils/validateEmail";
import {Entity} from "../../types/Entity";
import {country_keys, EntityHashParamName, RoutingTable} from "../../Constants";
import {stripHtml} from "../../utils/stripHtml";

declare type PropTypes = {};

const ClientSpecific: React.FC<PropTypes> = (props: PropTypes) => {
    const tr = useTr("settings");
    const trb = useTr("base");
    const trg = useTr("global");
    const params = useParams();
    const [client, setClient] = React.useState<O.Option<Client>>(O.none);
    const [members, setMembers] = React.useState<BackendUserInfo[]>([]);
    const [currentUser, setCurrentUser] = React.useState<O.Option<BackendUserInfo>>(O.none);
    const [currentUserIsOwner, setCurrentUserIsOwner] = React.useState<boolean>(false);
    const getClientByHash = useGlobalStore((s) => s.getClientByHash);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const getBackendUserInfo = useGlobalStore((s) => s.getBackendUserInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (typeof params.clientHash === "string") {
            setClient(getClientByHash(params.clientHash));
        }
    }, [params]);

    React.useEffect(() => {
        if (O.isSome(client)) {
            fetchApi(`/client/${client.value.hash}/members/`)
                .then((response) => response.json())
                .then((data: BackendUserInfo[] | any) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        let backend_user = getBackendUserInfo();
                        if (!O.isNone(backend_user) && typeof client.value.owner !== "undefined") {
                            setCurrentUserIsOwner(backend_user.value.id === client.value.owner.id);
                        }
                        setCurrentUser(backend_user);
                        setMembers(data);
                    }
                });
        }
    }, [client]);

    const renderMember = (member: BackendUserInfo) => {
        return (
            <tr key={member.id}>
                <td>{member.first_name ? `${member.first_name} ${member.last_name}` : member.email}</td>
                <td>{member.email}</td>
                <td className="text-end">
                    {O.isSome(currentUser) && currentUser.value.id === member.id && !currentUserIsOwner && ""}
                    {O.isSome(currentUser) && currentUser.value.id === member.id && currentUserIsOwner && "owner"}
                    {O.isSome(currentUser) && O.isSome(client) && currentUser.value.id !== member.id && (
                        <DeleteMemberModal key={member.id} member={member} client={client.value} />
                    )}
                </td>
            </tr>
        );
    };

    if (O.isSome(client))
        return (
            <Layout headerType={HeaderType.Settings}>
                <LayoutSettings>
                    <Panel className="settings-client-specific-panel">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h2 className="d-inline">{tr("ClientSpecificLabel")}</h2>
                                <span>
                                    &nbsp;&nbsp;
                                    <span className="Appkit4-icon icon-arrow-right-small-outline"></span>
                                    &nbsp;&nbsp;{client.value.name}
                                </span>
                            </div>
                            <div>
                                <Button
                                    kind="text"
                                    icon="icon-arrow-left-small-outline"
                                    onClick={() => navigate(RoutingTable.my_clients)}
                                >
                                    {trb("back")}
                                </Button>
                            </div>
                        </div>
                        <div style={{padding: "0 8px"}}>
                            <div className="clearfix">
                                <h3 className="float-start">{tr("users")}</h3>
                                <AddMemberModal client={client.value} />
                            </div>
                            <Panel>
                                <table className="table table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th style={{width: "30%"}}>{tr("name")}</th>
                                            <th style={{width: "30%"}}>{tr("email_adress")}</th>
                                            <th style={{width: "30%"}}>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {members.map((member) => {
                                            return renderMember(member);
                                        })}
                                    </tbody>
                                </table>
                            </Panel>
                            <div className="clearfix">
                                <h3 className="float-start">{tr("entities")}</h3>
                                <AddEntityModal client={client.value} />
                            </div>
                            <Panel>
                                {O.isSome(client) && client.value.entities.length > 0
                                    ? client.value.entities.map((e) => {
                                          return <EntityMembers key={e.hash} entity={e} />;
                                      })
                                    : ""}
                            </Panel>
                        </div>
                    </Panel>
                </LayoutSettings>
            </Layout>
        );
    else {
        return (
            <Layout headerType={HeaderType.Settings}>
                <LayoutSettings>
                    <Notice
                        key="main"
                        title={trg("access_denied")}
                        message={trg("you_do_not_have_permission")}
                        status="error"
                        closeable={false}
                        style={{backgroundColor: "white", width: "80%", margin: "10px auto"}}
                    ></Notice>
                </LayoutSettings>
            </Layout>
        );
    }
};

export default ClientSpecific;

declare type EntityMembersPropTypes = {
    entity: Entity;
};

const EntityMembers: React.FC<EntityMembersPropTypes> = (props: EntityMembersPropTypes) => {
    const tr = useTr("settings");
    const [members, setMembers] = React.useState<BackendUserInfo[]>([]);

    const [currentUser, setCurrentUser] = React.useState<O.Option<BackendUserInfo>>(O.none);
    const [reloadedMember, setReloadedMember] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const getBackendUserInfo = useGlobalStore((s) => s.getBackendUserInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (reloadedMember === false)
            fetchApi(`/entity/${props.entity.hash}/members/`)
                .then((response) => response.json())
                .then((data: BackendUserInfo[] | any) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        let backend_user = getBackendUserInfo();
                        setCurrentUser(backend_user);
                        setMembers(data);
                        setReloadedMember(true);
                    }
                });
    }, [reloadedMember]);

    const reloadEntityHandler = () => {
        setReloadedMember(false);
    };

    const renderMember = (member: BackendUserInfo) => {
        return (
            <tr key={member.id}>
                <td>{member.first_name ? `${member.first_name} ${member.last_name}` : member.email}</td>
                <td>{member.email}</td>
                <td className="text-end">
                    {O.isSome(currentUser) && currentUser.value.id === member.id && ""}
                    {O.isSome(currentUser) && currentUser.value.id !== member.id && (
                        <DeleteMemberOfEntityModal
                            key={member.id}
                            member={member}
                            entity={props.entity}
                            reloadEntityHandler={reloadEntityHandler}
                        />
                    )}
                </td>
            </tr>
        );
    };
    return (
        <div className="entity-member-wrapper">
            <div className="clearfix">
                <h4
                    className="float-start as-link"
                    onClick={() =>
                        navigate(
                            ProvideRouteParams(RoutingTable.settings_entities_specific, [
                                {paramName: EntityHashParamName, paramValue: props.entity.hash},
                            ])
                        )
                    }
                >
                    {props.entity.name}
                </h4>
                <AddMemberToEntityModal entity={props.entity} reloadEntityHandler={reloadEntityHandler} />
            </div>
            <table className="table table-hover table-borderless">
                <thead>
                    <tr>
                        <th style={{width: "30%"}}>{tr("name")}</th>
                        <th style={{width: "30%"}}>{tr("email_adress")}</th>
                        <th style={{width: "30%"}}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {members.map((member) => {
                        return renderMember(member);
                    })}
                </tbody>
            </table>
        </div>
    );
};

declare type AddMemberPropTypes = {
    client: Client;
};

const AddMemberModal: React.FC<AddMemberPropTypes> = (props: AddMemberPropTypes) => {
    const tr = useTr("settings");
    const trg = useTr("global");
    const [visible, setVisible] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");
    const [error, setError] = React.useState<boolean>(false);

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const onAddHandle = () => {
        setError(false);
        if (validateEmail(email) !== null) {
            fetchApi(`/client/${props.client.hash}/add_member/`, "post", {email: email})
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : loadUserInfo();
                });
            setVisible(false);
            setEmail("");
        } else {
            setError(true);
        }
    };

    return (
        <>
            <Button kind="primary" className="float-end mt-3" compact onClick={() => setVisible(true)}>
                {tr("add_user")}
            </Button>
            <Modal
                visible={visible}
                title={trg("add_new_user")}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "33.75rem"}}
                icons={""}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {tr("cancel")}
                        </Button>
                        <Button onClick={onAddHandle} kind="primary">
                            {tr("add_user")}
                        </Button>
                    </>
                }
            >
                <Input
                    type="text"
                    required={true}
                    value={email}
                    allowClear={true}
                    title={trg("user_email_address")}
                    onChange={(v) => setEmail(stripHtml(v))}
                    onClick={() => setError(false)}
                    error={error}
                    errorNode={
                        <div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">
                            {tr("enter_valid_email")}{" "}
                        </div>
                    }
                />
            </Modal>
        </>
    );
};

declare type DeleteMemberPropTypes = {
    member: BackendUserInfo;
    client: Client;
};

const DeleteMemberModal: React.FC<DeleteMemberPropTypes> = (props: DeleteMemberPropTypes) => {
    const tr = useTr("settings");
    const trg = useTr("global");
    const [visible, setVisible] = React.useState(false);

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const onDeleteHandle = (email: string) => {
        setVisible(false);
        fetchApi(`/client/${props.client.hash}/delete_member/`, "delete", {email: email})
            .then((res) => res.json())
            .then((data) => {
                typeof data.error !== "undefined" ? addErrorNotification(data) : loadUserInfo();
            })
            .catch((err) => console.error(err));
    };

    return (
        <>
            <span
                className="Appkit4-icon icon-delete-fill ap-font-24"
                aria-hidden="true"
                style={{color: "#D04A02", cursor: "pointer"}}
                onClick={() => setVisible(true)}
            ></span>
            <Modal
                visible={visible}
                title={`${trg("delete_user")} ${props.member.first_name} ${props.member.last_name}?`}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "33.75rem"}}
                icons={""}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {tr("cancel")}
                        </Button>
                        <Button onClick={() => onDeleteHandle(props.member.email)} kind="negative">
                            {tr("yes_delete")}
                        </Button>
                    </>
                }
            >
                <div>
                    {tr("are_you_sure")} <Badge type="danger-outlined" value={props.member.email}></Badge> {trg("as")}
                    &nbsp;a&nbsp;{trg("user_of_this_client")}
                </div>
            </Modal>
        </>
    );
};

declare type AddMemberToEntityPropTypes = {
    entity: Entity;
    reloadEntityHandler: () => void;
};

const AddMemberToEntityModal: React.FC<AddMemberToEntityPropTypes> = (props: AddMemberToEntityPropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");
    const [error, setError] = React.useState<boolean>(false);
    const trg = useTr("global");

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const onAddHandle = () => {
        setError(false);
        if (validateEmail(email) !== null) {
            fetchApi(`/entity/${props.entity.hash}/add_member/`, "post", {email: stripHtml(email)})
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : props.reloadEntityHandler();
                })
                .catch((err) => console.log(err));
            setVisible(false);
            setEmail("");
        } else {
            setError(true);
        }
    };

    return (
        <>
            <Button kind="primary" className="float-end mt-3" compact onClick={() => setVisible(true)}>
                {trg("add_user")}
            </Button>
            <Modal
                visible={visible}
                title={`Add new user`}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "33.75rem"}}
                icons={""}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {trg("cancel")}
                        </Button>
                        <Button onClick={onAddHandle} kind="primary">
                            {trg("add_user")}
                        </Button>
                    </>
                }
            >
                <Input
                    type="text"
                    required={true}
                    value={email}
                    allowClear={true}
                    title="User email address"
                    onChange={(v: any) => setEmail(stripHtml(v))}
                    onClick={() => setError(false)}
                    error={error}
                    errorNode={
                        <div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">
                            {trg("please_enter_a_valid_email")}{" "}
                        </div>
                    }
                />
            </Modal>
        </>
    );
};

declare type DeleteMemberOfEntityPropTypes = {
    member: BackendUserInfo;
    entity: Entity;
    reloadEntityHandler: () => void;
};

const DeleteMemberOfEntityModal: React.FC<DeleteMemberOfEntityPropTypes> = (props: DeleteMemberOfEntityPropTypes) => {
    const [visible, setVisible] = React.useState(false);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const trg = useTr("global");
    const onDeleteHandle = (email: string) => {
        setVisible(false);
        fetchApi(`/entity/${props.entity.hash}/delete_member/`, "delete", {email: email})
            .then((res) => res.json())
            .then((data) => {
                typeof data.error !== "undefined" ? addErrorNotification(data) : props.reloadEntityHandler();
            });
    };

    return (
        <>
            <span
                className="Appkit4-icon icon-delete-fill ap-font-24"
                aria-hidden="true"
                style={{color: "#D04A02", cursor: "pointer"}}
                onClick={() => setVisible(true)}
            ></span>
            <Modal
                visible={visible}
                title={`Delete user ${props.member.first_name} ${props.member.last_name}?`}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "33.75rem"}}
                icons={""}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {trg("cancel")}
                        </Button>
                        <Button onClick={() => onDeleteHandle(props.member.email)} kind="negative">
                            {trg("yes_delete")}
                        </Button>
                    </>
                }
            >
                <div>
                    {trg("are_you_sure_you_really")} <Badge type="danger-outlined" value={props.member.email}></Badge>{" "}
                    {trg("as")}&nbsp;{trg("a")}&nbsp;{trg("user_of_this_entity")}
                </div>
            </Modal>
        </>
    );
};

declare type AddEntityPropTypes = {
    client: Client;
};

const AddEntityModal: React.FC<AddEntityPropTypes> = (props: AddEntityPropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);

    const [name, setName] = React.useState<string>("");
    const [street, setStreet] = React.useState<string>("");
    const [streetNumber, setStreetNumber] = React.useState<string>("");
    const [city, setCity] = React.useState<string>("");
    const [zipCode, setZipCode] = React.useState<string>("");
    const [country, setCountry] = React.useState<string>();
    const [idNumber, setIdNumber] = React.useState<string>("");
    const [taxNumber, setTaxNumber] = React.useState<string>("");
    const [ruleset, setRuleset] = React.useState<string>("");
    const trg = useTr("global");

    const [error, setError] = React.useState<boolean>(false);
    const [buttonIsLoading, setButtonIsLoading] = React.useState<boolean>(false);

    const [countries, setCountries] = React.useState<any[]>([]);
    const [typesOfLegacy, setTypesOfLegacy] = React.useState<any[]>([]);

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const codebooks = useGlobalStore((s) => s.codebooks);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const clearForm = () => {
        setName("");
        setStreet("");
        setStreetNumber("");
        setCity("");
        setCountry("");
        setZipCode("");
        setIdNumber("");
        setTaxNumber("");
        setRuleset("");
    };

    const onAddHandle = () => {
        setError(false);
        let are_data_validated = ("" + name).length > 1;
        let validated_data = {
            name: stripHtml(name),
            street: stripHtml(street),
            street_number: stripHtml(streetNumber),
            city: stripHtml(city),
            country: country ? stripHtml(country) : null,
            zip_code: stripHtml(zipCode),
            id_number: stripHtml(idNumber),
            tax_number: stripHtml(taxNumber),
            ruleset: ruleset,
        };
        if (are_data_validated === true) {
            setButtonIsLoading(true);
            setError(false);
            fetchApi(`/client/${props.client.hash}/entity/`, "post", validated_data)
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        setError(true);
                        addErrorNotification(data);
                    } else {
                        loadUserInfo();
                        clearForm();
                        setVisible(false);
                        setError(false);
                    }
                    setButtonIsLoading(false);
                });
        } else {
            setError(true);
        }
    };

    React.useEffect(() => {
        setCountries(
            Object.entries(country_keys).map((item) => {
                return {
                    key: item[0],
                    value: item[0],
                    label: item[1],
                };
            })
        );
        setTypesOfLegacy(
            Object.entries(codebooks.rulesets).map((item, index) => {
                return {
                    key: item[1].id,
                    value: item[1].id,
                    label: item[1].name,
                };
            })
        );
        setCountry("SK");
    }, []);

    return (
        <>
            <Button kind="primary" className="float-end mt-3" compact onClick={() => setVisible(true)}>
                {trg("add_entity")}
            </Button>
            <Modal
                visible={visible}
                title={`Add new entity`}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "60rem"}}
                icons={""}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {trg("cancel")}
                        </Button>
                        <Button onClick={onAddHandle} kind="primary" loading={buttonIsLoading}>
                            {trg("ok")}
                        </Button>
                    </>
                }
            >
                <div className="container" style={{zIndex: 99999}}>
                    {error && (
                        <Notice
                            key="main"
                            title={trg("please_fill_all_the_fields")}
                            status="error"
                            closeable={true}
                            style={{width: "100%"}}
                        ></Notice>
                    )}
                    {buttonIsLoading && (
                        <Notice
                            key="main"
                            title={
                                <>
                                    <span style={{float: "left"}}>{trg("please_wait")}&nbsp;&nbsp;</span>
                                    <Loading
                                        loadingType="linear"
                                        indeterminate={true}
                                        compact={false}
                                        style={{display: "inline-block"}}
                                    ></Loading>
                                </>
                            }
                            message={<>{trg("a_new_entity_is_being_created")}</>}
                            status="warning"
                            closeable={true}
                            style={{width: "100%"}}
                        ></Notice>
                    )}
                    <div className="row">
                        <div className="col-md-4 col-sm-6 mb-4">
                            <Input
                                type="text"
                                required={true}
                                value={name}
                                allowClear={true}
                                title={trg("entity_name")}
                                onChange={(v) => setName(stripHtml(v))}
                                onClick={() => setError(false)}
                            />
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                            <Input
                                type="text"
                                required={false}
                                value={street}
                                allowClear={true}
                                title={trg("street")}
                                onChange={(v) => setStreet(stripHtml(v))}
                                onClick={() => setError(false)}
                            />
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                            <Input
                                type="text"
                                required={false}
                                value={streetNumber}
                                allowClear={true}
                                title={trg("street_number")}
                                onChange={(v) => setStreetNumber(stripHtml(v))}
                                onClick={() => setError(false)}
                            />
                        </div>
                        <div className="col-md-5 col-sm-6 mb-4">
                            <Input
                                type="text"
                                required={false}
                                value={city}
                                allowClear={true}
                                title={trg("city")}
                                onChange={(v) => setCity(stripHtml(v))}
                                onClick={() => setError(false)}
                            />
                        </div>
                        <div className="col-md-4 col-sm-6 mb-4">
                            {countries.length > 0 && country && (
                                <Select
                                    required={false}
                                    data={countries}
                                    value={country}
                                    defaultValue={country}
                                    placeholder={trg("country")}
                                    searchable={true}
                                    onSelect={(v: any) => setCountry(stripHtml(v))}
                                    dropdownMatchWidth={false}
                                ></Select>
                            )}
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <Input
                                type="text"
                                required={false}
                                value={zipCode}
                                allowClear={true}
                                title={trg("zip_code")}
                                onChange={(v) => setZipCode(stripHtml(v))}
                                onClick={() => setError(false)}
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <Input
                                type="text"
                                required={false}
                                value={idNumber}
                                allowClear={true}
                                title={trg("id_number")}
                                onChange={(v) => setIdNumber(stripHtml(v))}
                                onClick={() => setError(false)}
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-4">
                            <Input
                                type="text"
                                required={false}
                                value={taxNumber}
                                allowClear={true}
                                title={trg("tax_number")}
                                onChange={(v) => setTaxNumber(stripHtml(v))}
                                onClick={() => setError(false)}
                            />
                        </div>
                        <div className="col-md-3 col-sm-6 mb-5">
                            <Select
                                required={false}
                                value={ruleset}
                                data={typesOfLegacy}
                                placeholder={trg("type_of_legacy")}
                                onSelect={(v: any) => setRuleset(v)}
                                dropdownMatchWidth={false}
                            ></Select>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
