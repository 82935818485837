import i18n from "./i18n";

export const numberToLocaleString = (number: any, locale?: string, max_digits?: number, min_digits?: number) => {
    //console.log(number);
    locale = locale ? locale : i18n.language;
    let amount =
        typeof number === "number"
            ? number
            : typeof number === "string"
            ? number.includes(".") && locale.includes("en")
                ? parseFloat(number)
                : parseFloat(number.replace(",", "."))
            : number
            ? parseFloat(number)
            : 0.0;
    //console.log(amount);
    return amount.toLocaleString(locale, {
        maximumFractionDigits: max_digits ? max_digits : 2,
        minimumFractionDigits: min_digits ? min_digits : 2,
    });
};

export const convertLocaleNumberToFloat = (number: string, locale: string) => {
    return locale.includes("en") ? number : number.replace(".", "").replace(" ", "").replace(",", ".");
};
