import React from "react";
import Layout from "../Layout/Layout";
import {Loading, Button} from "@appkit4/react-components";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import {FormRow} from "../../types/FormRow";
import {useTr} from "../../utils/trUtil";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../../stores/GlobalStore";
import PagePanel from "../../misc/PagePanel";
import Centered from "../../misc/Centered";
import "./CalculationForm.scss";
import CalculationFormElement from "./CalculationFormElement";
import {Form} from "../../types/Form";

declare type RecursiveFormRowsComponentPropsType = {
    rows: FormRow[];
    parentPath: string;
};

const CalculationForm: React.FC = () => {
    const trg = useTr("global");

    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const [form, setForm] = React.useState<Form>();
    const [data, setData] = React.useState<FormRow[]>([]);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (dataAreLoaded === false)
            if (O.isSome(entity) && O.isSome(calculation))
                fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/formdata/rows_tree/`)
                    .then((response) => response.json())
                    .then((data) => {
                        if (typeof data.error !== "undefined") {
                            addErrorNotification(data);
                        } else {
                            setData(data);
                            setDataAreLoaded(true);
                        }
                    })
                    .catch((err) => {
                        addErrorNotification(err);
                    });
    }, [dataAreLoaded, entity, addErrorNotification, calculation, fetchApi]);

    React.useEffect(() => {
        if (O.isSome(calculation))
            setForm(() => {
                let forms = calculation.value.available_forms.filter((f) => f.id === calculation.value.form_id);
                return forms.length > 0 ? forms[0] : undefined;
            });
    }, [calculation]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <div className="content-minimal-padding">
                    <div className="calculation-form container">
                        <PagePanel
                            title={trg("Tax form")}
                            subtitle={form ? <>&nbsp;-&nbsp;{form.name}</> : ""}
                            buttons={
                                <Button
                                    compact
                                    kind="secondary"
                                    icon="icon-arrow-left-small-outline"
                                    onClick={() => window.history.back()}
                                >
                                    back
                                </Button>
                            }
                            description="All the inputs below are automatically saved when changed"
                        >
                            {dataAreLoaded ? (
                                <RecursiveFormRowsComponent rows={data} parentPath="" />
                            ) : (
                                <div className="m-5 p-5">
                                    <Centered>
                                        <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                                    </Centered>
                                </div>
                            )}
                        </PagePanel>
                    </div>
                </div>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export const RecursiveFormRowsComponent: React.FC<RecursiveFormRowsComponentPropsType> = ({
    rows,
    parentPath,
}: RecursiveFormRowsComponentPropsType) => {
    var lastPath = "";
    return (
        <>
            {rows.map((element) => {
                var newPath = parentPath + "." + element.xml_node_name;
                if (lastPath === newPath) {
                    return <React.Fragment key={element.id}></React.Fragment>;
                }
                lastPath = parentPath + "." + element.xml_node_name;
                return <CalculationFormElement key={element.id} element={element} parentPath={parentPath} />;
            })}
        </>
    );
};

export default CalculationForm;
