import React from "react";
import CanShowAdminMiddleware from "../../../misc/CanShowAdminMiddleware";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Ruleset} from "../../../types/Ruleset";
import {Table, Column} from "@appkit4/react-components/table";
import {DjangoDefaultPaginationType} from "../../../types/DjangoDefaultPaginationType";
import {Pagination} from "@appkit4/react-components/pagination";
import {useFixtureStore} from "../../../stores/FixtureStore";
import RulesetFormForm from "./Forms/RulesetFormForm";
import {CategoryGroup} from "../../../types/CategoryGroup";
import CategoryGroupForm from "./Forms/CategoryGroupForm";
import {TagForRuleset} from "../../../types/TagForRuleset";
import TagForRulesetForm from "./Forms/TagForRulesetForm";
import {AdjustmentDefault} from "../../../types/AdjustmentDefault";
import {AdjustmentDefaultRuleset} from "../../../types/AdjustmentDefaultRuleset";
import AdjustmentDefaultRulesetForm from "./Forms/AdjustmentDefaultRulesetForm";

declare type PropTypes = {};

interface PaginationType extends DjangoDefaultPaginationType {
    results: AdjustmentDefaultRuleset[];
}

const AdminAdjustmentDefaultRuleset: React.FC<PropTypes> = (props: PropTypes) => {
    const [data, setData] = React.useState<AdjustmentDefaultRuleset[]>([]);
    const [totalCount, setTotalCount] = React.useState<number>(0);
    const [countPerPage, setCountPerPage] = React.useState<number>(100);
    const [current, setCurrent] = React.useState(1);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [dataAreLoading, setDataAreLoading] = React.useState<boolean>(false);

    const {fixtureState, init, rulesets, forms, adjustmentsDefault} = useFixtureStore((s) => ({
        fixtureState: s,
        init: s.init,
        rulesets: s.rulesets,
        forms: s.forms,
        adjustmentsDefault: s.adjustmentsDefault,
    }));

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    React.useEffect(() => {
        init();
    }, [init]);

    const onPageChange = (page: number) => {
        setCurrent(page);
        setDataAreLoading(true);
    };

    React.useEffect(() => {
        if (dataAreLoaded === false || dataAreLoading === true) {
            fetchApi(`/settings/_/fixtures/adjustmentdefaultruleset/?page=${current}`)
                .then((r) => r.json())
                .then((res: PaginationType) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setData(res.results);
                        setTotalCount(res.count);
                    }
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setDataAreLoading(false);
                    setDataAreLoaded(true);
                });
        }
    }, [dataAreLoaded, addErrorNotification, fetchApi, dataAreLoading, current]);

    const renderActionCell = (row) => {
        return (
            <>
                <AdjustmentDefaultRulesetForm
                    key={`edit-${row.id}`}
                    adjustmentDefaultRuleset={row}
                    onSaveHandler={() => setDataAreLoaded(false)}
                />
            </>
        );
    };

    const mergedData = data.map((dataItem) => {
        const mergeAdjustment = adjustmentsDefault.find((adjustment) => adjustment.id === dataItem.adjustment_default);
        const mergeRuleset = rulesets.find((ruleset) => ruleset.id === dataItem.ruleset);

        if (mergeAdjustment && mergeRuleset) {
            return {
                ...dataItem,
                adjustment_name_en: mergeAdjustment.name,
                ruleset_name_en: mergeRuleset.name,
            };
        } else {
            return {
                ...dataItem,
                adjustment_name_en: "-",
                ruleset_name_en: "-",
            };
        }
    });

    const pagination = (
        <Pagination
            current={current}
            total={Math.ceil(totalCount / countPerPage)}
            onPageChange={onPageChange}
        ></Pagination>
    );

    return (
        <CanShowAdminMiddleware>
            {fixtureState.rulesetsAreLoaded && fixtureState.formsAreLoaded && dataAreLoaded && (
                <div className="container-fluid">
                    {pagination}
                    <Table originalData={mergedData} hasTitle striped condensed>
                        <Column field="id" sortKey="id" slot="end">
                            ID
                        </Column>
                        <Column field="adjustment_name_en" sortKey="adjustment_name_en">
                            Adjustment default
                        </Column>
                        <Column field="ruleset_name_en" sortKey="ruleset_name_en">
                            Ruleset
                        </Column>
                        <Column field="id" title={"Action"} renderCell={renderActionCell}>
                            Action
                        </Column>
                    </Table>
                    {pagination}
                </div>
            )}
            <AdjustmentDefaultRulesetForm onSaveHandler={() => setDataAreLoaded(false)} />
        </CanShowAdminMiddleware>
    );
};

export default AdminAdjustmentDefaultRuleset;
