import React from "react";
import * as O from "fp-ts/Option";
import {useGlobalStore} from "../stores/GlobalStore";
import {hasCalculationClaim, hasEntityClaim} from "../utils/claimUtils";
import {ClaimTypes} from "../Constants";

declare type ResultType = () => {hasCalculationClaim: boolean; hasEntityClaim: boolean; hasAttachmentClaim: boolean};

const useUserClaims: ResultType = () => {
    const {calculation, entity, getUserClaims, getUserClients} = useGlobalStore((s) => ({
        calculation: s.currentCalculation,
        entity: s.currentCalculationEntity,
        getUserClaims: s.getUserClaims,
        getUserClients: s.getUserClients,
    }));

    const [_hasCalculationClaim, setHasCalculationClaim] = React.useState<boolean>(false);
    const [_hasEntityClaim, setHasEntityClaim] = React.useState<boolean>(false);
    const [_hasAttachmentClaim, setHasAttachemntClaim] = React.useState<boolean>(false);

    React.useEffect(() => {
        let userClaims = Object.entries(getUserClaims()).map(([i, v]) => v);
        let userClients = getUserClients();
        if (O.isSome(calculation) && O.isSome(entity) && userClaims && userClients) {
            setHasCalculationClaim(
                hasCalculationClaim(calculation.value, entity.value, userClaims, ClaimTypes.ENTITY_CALCULATION)
            );
            setHasEntityClaim(hasEntityClaim(entity.value, userClaims, ClaimTypes.ENTITY, userClients));
            setHasAttachemntClaim(
                hasCalculationClaim(
                    calculation.value,
                    entity.value,
                    userClaims,
                    ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS
                )
            );
        }
    }, [calculation, entity, getUserClaims, getUserClients]);

    return {
        hasCalculationClaim: _hasCalculationClaim,
        hasEntityClaim: _hasEntityClaim,
        hasAttachmentClaim: _hasAttachmentClaim,
    };
};

export default useUserClaims;
