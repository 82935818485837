import React from "react";
import {useGlobalStore} from "../stores/GlobalStore";
import {Switch} from "@appkit4/react-components/switch";
import * as O from "fp-ts/Option";
import {Adjustment} from "../types/Adjustment";
import i18n from "../utils/i18n";

interface PropTypes {
    row: any;
    field: string;
    amount?: number;
    className?: string;
    redIfNotZero?: boolean;
    redIfLtZero?: boolean;
    onClick?: () => void;
    prefix?: string | React.ReactElement;
    suffix?: string | React.ReactElement;
    locale?: string;
}

export const Category: React.FC<PropTypes> = (props: PropTypes) => {
    return <>{props.row.category ? props.row.category.name : ""}</>;
};

export const Comment: React.FC<PropTypes> = (props: PropTypes) => {
    return <i>{props.row.comment_official}</i>;
};

export const Coefficient: React.FC<PropTypes> = (props: PropTypes) => {
    return <>{parseFloat(props.row.coefficient).toFixed(2)}</>;
};

export const Amount: React.FC<PropTypes> = (props: PropTypes) => {
    return <Number {...props} />;
};

export const Number: React.FC<PropTypes> = (props: PropTypes) => {
    const amount = props.amount ? (typeof props.amount === "number" ? props.amount : parseFloat(props.amount)) : 0.0;
    let locale = props.locale ? props.locale : i18n.language;
    return (
        <div onClick={props.onClick} className={props.className} style={{width: "100px"}}>
            <div className="d-flex flex-row-reverse w-100">
                <div
                    className={`px-3${
                        (props.redIfNotZero && amount !== 0) || (props.redIfLtZero && amount < 0) ? " text-danger" : ""
                    }`}
                >
                    <div className={props.className}>
                        {props.prefix ? props.prefix : <></>}
                        {amount.toLocaleString(locale, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                        {props.suffix ? props.suffix : <></>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Filters: React.FC<PropTypes> = (props: PropTypes) => {
    return props.row.filters.map((filter: any) => {
        return (
            <div key={filter.filter.id}>
                {filter.filter.name}
                <br></br>
            </div>
        );
    });
};

export const DeferredTaxClassification: React.FC<PropTypes> = (props: PropTypes) => {
    return <>{props.row.deferred_tax_classification}</>;
};

export const Review: React.FC<PropTypes> = (props: PropTypes) => {
    const reviewers = useGlobalStore((s) => s.currentCalculationReviewers);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const getBackendUserInfo = useGlobalStore((s) => s.getBackendUserInfo);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [adjustment, setAdjustment] = React.useState<Adjustment>();
    const [switchChecked, setSwitchChecked] = React.useState<boolean>(false);
    const [switchDisabled, setSwitchDisabled] = React.useState<boolean>(false);
    const [switchText, setSwitchText] = React.useState<string>("reviewed");

    const onChangeStatusHandler = (state: boolean) => {
        if (O.isSome(calculation) && O.isSome(entity) && adjustment) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/${adjustment.id}/approve/`,
                state === true ? "post" : "delete",
                {}
            )
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setAdjustment(data);
                    }
                });
        }
    };

    React.useEffect(() => {
        if (props.row) {
            setAdjustment(props.row);
        }
    }, [props]);

    React.useEffect(() => {
        if (adjustment !== undefined && reviewers !== undefined) {
            var user = getBackendUserInfo();
            var userInfo: any | null = O.isNone(user) ? null : user.value;

            // If reviewers are not specified
            if (reviewers.length === 0) {
                setSwitchChecked(adjustment.review_status !== "in_progress" ? true : false);
                setSwitchText("reviewed");

                // if reviewers are specified, check if current user is reviewer 1 or reviewer 2
            } else if (reviewers.length > 0 && userInfo !== null) {
                let isR0 = true;
                let isR1 = reviewers.some(
                    (reviewer) =>
                        reviewer.user.email === userInfo.email &&
                        reviewer.claim_type === "user_entity:calculation:reviewer1"
                )
                    ? true
                    : false;

                let isR2 = reviewers.some(
                    (reviewer) =>
                        reviewer.user.email === userInfo.email &&
                        reviewer.claim_type === "user_entity:calculation:reviewer2"
                )
                    ? true
                    : false;

                switch (adjustment.review_status) {
                    case "in_progress":
                        if (isR0 || isR1 || isR2) {
                            setSwitchChecked(false);
                            setSwitchText("for review");
                        }
                        break;
                    case "for_review":
                        if (isR1) {
                            setSwitchChecked(false);
                            setSwitchText("for second review");
                        } else if (isR2) {
                            setSwitchChecked(true);
                            setSwitchText("waiting for first review");
                            setSwitchDisabled(true);
                        } else {
                            setSwitchChecked(true);
                            setSwitchText("for review");
                        }
                        break;
                    case "for_second_review":
                        if (isR2) {
                            setSwitchChecked(false);
                            setSwitchText("reviewed");
                        } else if (isR1) {
                            setSwitchChecked(true);
                            setSwitchText("for second review");
                        } else {
                            setSwitchChecked(true);
                            setSwitchText("for second review");
                            setSwitchDisabled(true);
                        }
                        break;
                    case "reviewed":
                        if (isR2) {
                            setSwitchChecked(true);
                            setSwitchText("reviewed");
                        } else {
                            setSwitchChecked(true);
                            setSwitchText("reviewed");
                            setSwitchDisabled(true);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }, [adjustment, getBackendUserInfo, reviewers]);

    return (
        <Switch
            className="adjustment-overview-switch"
            checked={switchChecked}
            onChange={onChangeStatusHandler}
            disabled={switchDisabled}
        >
            <small className="text-muted">{switchText}</small>
        </Switch>
    );
};
