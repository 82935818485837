import React, {useState} from "react";
import Centered from "../../misc/Centered";
import {Loading} from "@appkit4/react-components/loading";
import Layout from "../Layout/Layout";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../types/HeaderType";
import PagePanel from "../../misc/PagePanel";
import {Select, ItemDataType} from "@appkit4/react-components";
import {Table, Column} from "@appkit4/react-components/table";
import {Tag as AppkitTag} from "@appkit4/react-components/tag";
import {useTr} from "../../utils/trUtil";
import {Tag} from "../../types/Tag";
import {useGlobalStore} from "../../stores/GlobalStore";
import {Button, Input, Upload, Modal, FileModel, Checkbox, Drawer} from "@appkit4/react-components";
import * as O from "fp-ts/Option";
import "./TrialBalance.scss";
import {TrialBalanceComment} from "../../types/TrialBalanceComment";
import {extraPropToFlowjs, uploadUrl} from "../../utils/uploadUtil";
import {Number} from "../../misc/AdjustmentColumns";
import {zeroToString} from "../../utils/zeroToString";
import {stripHtml} from "../../utils/stripHtml";
import {SelectValue} from "@appkit4/react-components/esm/select/Select";
import findCommonElements from "../../utils/findCommonElements";
import useDownloadUrl from "../../hooks/useDownloadUrl";
import {FilterFormContent} from "./Adjustment/FilterForm";

declare type PropTypes = {};

const TrialBalance: React.FC<PropTypes> = (props: PropTypes) => {
    const trh = useTr("header");
    const trg = useTr("global");

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const {renderLink, download} = useDownloadUrl();

    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TrialBalanceComment[]>([]);
    const [renderData, setRenderData] = React.useState<any[]>([]);
    const [availableTags, setAvailableTags] = React.useState<any>([]);
    const [importModalIsOpen, setImportModalIsOpen] = React.useState<boolean>(false);
    const [exportModalIsOpen, setExportModalIsOpen] = React.useState<boolean>(false);

    const [sortActive, setSortActive] = React.useState("account_number");
    const [sortPhase, setSortPhase] = React.useState(1);

    const [searchAccount, setSearchAccount] = React.useState<string>("");
    const [searchAccountName, setSearchAccountName] = React.useState<string>("");
    const [searchOfficialComment, setSearchOfficialComment] = React.useState<string>("");
    const [searchInternalComment, setSearchInternalComment] = React.useState<string>("");
    const [searchTags, setSearchTags] = React.useState<string>("");
    const [searchOpeningBalanceGt, setSearchOpeningBalanceGt] = React.useState<string>("");
    const [searchOpeningBalanceLt, setSearchOpeningBalanceLt] = React.useState<string>("");
    const [searchClosingBalanceGt, setSearchClosingBalanceGt] = React.useState<string>("");
    const [searchClosingBalanceLt, setSearchClosingBalanceLt] = React.useState<string>("");
    const [searchDebitGt, setSearchDebitGt] = React.useState<string>("");
    const [searchDebitLt, setSearchDebitLt] = React.useState<string>("");
    const [searchCreditGt, setSearchCreditGt] = React.useState<string>("");
    const [searchCreditLt, setSearchCreditLt] = React.useState<string>("");
    const [selectedIndexs, setSelectedIndexs] = useState<number[]>([]);
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selectedAccountNumber, setSelectedAccountNumber] = useState<string>("");

    const timeouts = React.useMemo(() => [], []);
    const onCommentChange = React.useCallback(
        (comment: string, account_number: string, type: string) => {
            let timeout_id = account_number + "-" + type;

            if (typeof timeouts[timeout_id] !== "undefined") window.clearTimeout(timeouts[timeout_id]);

            timeouts[timeout_id] = window.setTimeout(() => {
                let body =
                    type === "internal"
                        ? {comment_internal: comment, account_number: account_number}
                        : {comment_official: comment, account_number: account_number};

                if (O.isSome(entity) && O.isSome(calculation)) {
                    fetchApi(
                        `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/trial_balance/comment/`,
                        "post",
                        body
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (typeof data.error !== "undefined") addErrorNotification(data);
                        });
                }
            }, 700);
        },
        [entity, calculation, addErrorNotification, fetchApi, timeouts]
    );

    const removeTag = React.useCallback(
        (e: any, tag_id: number) => {
            if (O.isSome(entity) && O.isSome(calculation)) {
                fetchApi(
                    `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/trial_balance/tag/`,
                    "delete",
                    {
                        tag_id: tag_id,
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        if (typeof data.error !== "undefined") addErrorNotification(data);
                    })
                    .catch((res) => {
                        addErrorNotification(res);
                    });
                let new_data = data.map((item) => {
                    item.tags = item.tags.filter((tag) => {
                        return tag.id !== tag_id;
                    });
                    return item;
                });
                setData(new_data);
            }
        },
        [entity, calculation, addErrorNotification, data, fetchApi]
    );

    const addTags = React.useCallback(
        (tags: Tag[]) => {
            // API add tag was called in SelectTagDropdown component and this parameter tags is result as array of Tag from backend
            tags.forEach((tag) => {
                setData((d) =>
                    d.map((item) => {
                        if (item.account_number === tag.account_number) item.tags = [...item.tags, tag];
                        return item;
                    })
                );
            });
            if (O.isSome(entity) && O.isSome(calculation))
                fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/trial_balance_comments/`)
                    .then((response) => response.json())
                    .then((res) => {
                        typeof res.error !== "undefined"
                            ? addErrorNotification(res)
                            : setAvailableTags(res.available_tags);
                    });
        },
        [entity, calculation, addErrorNotification, fetchApi]
    );

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && dataAreLoaded === false) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/trial_balance_comments/`)
                .then((response) => response.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setAvailableTags(res.available_tags);
                        //setData(res.data.sort((a: any, b: any) => (a.account_number < b.account_number ? -1 : 1)));
                        setData(res.data);
                    }
                    setDataAreLoaded(true);
                });
        }
    }, [entity, calculation, dataAreLoaded, fetchApi, addErrorNotification]);

    const getData = React.useCallback(() => {
        let d = [...renderData];
        try {
            d = d.filter((k) => {
                if (searchAccount !== "" && k.account_number.search(searchAccount) !== 0) return null;
                if (
                    searchAccountName !== "" &&
                    !k.account_name?.toLowerCase().includes(searchAccountName.toLowerCase().trim())
                )
                    return false;

                if (searchOpeningBalanceGt !== "" && parseFloat(k.opening_balance) < parseFloat(searchOpeningBalanceGt))
                    return null;
                if (searchOpeningBalanceLt !== "" && parseFloat(k.opening_balance) > parseFloat(searchOpeningBalanceLt))
                    return null;

                if (searchDebitGt !== "" && parseFloat(k.debit) < parseFloat(searchDebitGt)) return null;
                if (searchDebitLt !== "" && parseFloat(k.debit) > parseFloat(searchDebitLt)) return null;

                if (searchCreditGt !== "" && parseFloat(k.credit) < parseFloat(searchCreditGt)) return null;
                if (searchCreditLt !== "" && parseFloat(k.credit) > parseFloat(searchCreditLt)) return null;

                if (searchClosingBalanceGt !== "" && parseFloat(k.closing_balance) < parseFloat(searchClosingBalanceGt))
                    return null;
                if (searchClosingBalanceLt !== "" && parseFloat(k.closing_balance) > parseFloat(searchClosingBalanceLt))
                    return null;

                if (searchOfficialComment !== "" && k.comment_official.search(searchOfficialComment) === -1)
                    return null;
                if (searchInternalComment !== "" && k.comment_internal.search(searchInternalComment) === -1)
                    return null;
                if (
                    searchTags !== "" &&
                    k.tags.filter((tag: any) => {
                        return tag.name.toLowerCase().search(searchTags.toLowerCase()) !== -1;
                    }).length === 0
                )
                    return null;
                return k;
            });
        } catch (e) {
            console.error(e);
        }
        return d;
    }, [
        renderData,
        searchAccount,
        searchAccountName,
        searchOpeningBalanceGt,
        searchOpeningBalanceLt,
        searchDebitGt,
        searchDebitLt,
        searchCreditGt,
        searchCreditLt,
        searchClosingBalanceGt,
        searchClosingBalanceLt,
        searchInternalComment,
        searchOfficialComment,
        searchTags,
    ]);

    React.useEffect(() => {
        setSelectedIndexs((s) =>
            findCommonElements(
                [...s],
                getData().map((e) => e.account_number)
            )
        );
    }, [
        searchAccount,
        searchAccountName,
        searchOpeningBalanceGt,
        searchOpeningBalanceLt,
        searchDebitGt,
        searchDebitLt,
        searchCreditGt,
        searchCreditLt,
        searchClosingBalanceGt,
        searchClosingBalanceLt,
        searchInternalComment,
        searchOfficialComment,
        searchTags,
        getData,
    ]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            let render_data = zeroToString(data);

            render_data.forEach((item, index) => {
                render_data[index]["comment_official"] = item.comment_official;
                render_data[index]["comment_internal"] = item.comment_internal;
                render_data[index]["render_comment_internal"] = (
                    <Input
                        type="text"
                        hideTitleOnInput={true}
                        defaultValue={item.comment_internal ? item.comment_internal : ""}
                        onChange={(v) => onCommentChange(v, item.account_number, "internal")}
                    />
                );
                render_data[index]["render_comment_official"] = (
                    <Input
                        type="text"
                        hideTitleOnInput={true}
                        defaultValue={item.comment_official ? item.comment_official : ""}
                        onChange={(v) => onCommentChange(v, item.account_number, "official")}
                    />
                );
                render_data[index]["render_tags"] = (
                    <ul className="tagList">
                        {item.tags.length <= 1 && availableTags.default_tags.length > 0 && (
                            <li>
                                <SelectTagDropdown
                                    available_tags={availableTags}
                                    account_number={item.account_number}
                                    calculation_hash={calculation.value.hash}
                                    entity_hash={entity.value.hash}
                                    addTagHandler={addTags}
                                />
                            </li>
                        )}
                        {item.tags.map((tag: Tag) => {
                            return (
                                <li key={tag.id}>
                                    <AppkitTag
                                        onClose={(e) => {
                                            removeTag(e, tag.id);
                                        }}
                                    >
                                        {tag.name}
                                    </AppkitTag>
                                </li>
                            );
                        })}
                    </ul>
                );
            });
            setRenderData(render_data);
        }
    }, [availableTags, data, entity, calculation, addTags, removeTag, onCommentChange]);

    const exportTB = () => {
        if (O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/trial_balance_comments_export/`
            )
                .then((res) => res.blob())
                .then((response) => {
                    download(URL.createObjectURL(response), `trial-balance-export_${calculation.value.hash}.xlsx`);
                });
        }
    };

    const onImportChange = (file: FileModel, fileList: FileList): void => {
        if (file.percent === 100 && O.isSome(calculation) && O.isSome(entity)) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/trial_balance_comments_import/`,
                "post",
                file.response
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        //TODO:fix bug- data are not reloaded
                        setTimeout(() => {
                            setDataAreLoaded(false);
                            setImportModalIsOpen(false);
                        }, 1000);
                        window.location.reload();
                    }
                })
                .catch((data) => {
                    addErrorNotification(data);
                });
        }
    };

    const importModalContent = () => {
        return O.isSome(calculation) && O.isSome(entity) ? (
            <Modal
                visible={importModalIsOpen}
                onCancel={() => setImportModalIsOpen(false)}
                modalStyle={{width: "33.75rem"}}
            >
                <Upload
                    onChange={onImportChange}
                    action={uploadUrl(entity.value)}
                    multiple={false}
                    autoUpload={true}
                    extraPropToFlowjs={extraPropToFlowjs(entity.value, calculation.value, accessToken)}
                    acceptFileType=".xlsx"
                    uploadInstruction={`(trial-balance-export_${calculation.value.hash}.xlsx)`}
                    uploadTitle={trg("upload_previously_exported_updated")}
                    config={{
                        trigger: false,
                        type: "inline",
                        size: false,
                    }}
                ></Upload>
            </Modal>
        ) : (
            <></>
        );
    };

    const exportModalMessage = () => {
        return O.isSome(calculation) && O.isSome(entity) ? (
            <Modal
                visible={exportModalIsOpen}
                onCancel={() => setExportModalIsOpen(false)}
                modalStyle={{width: "33.75rem"}}
                title="Trial balance was downloaded"
            ></Modal>
        ) : (
            <></>
        );
    };

    const renderOrderCell = (row: any, field: string) => {
        const onCheckboxChange = (value: boolean) => {
            setSelectedIndexs((s) => {
                let new_s = [...s];
                if (value) {
                    new_s.push(row.account_number);
                } else {
                    new_s.splice(new_s.indexOf(row.account_number), 1);
                }
                return new_s;
            });
        };

        if (!(field in row)) return "";

        return (
            <>
                <Checkbox
                    value={row[field]}
                    checked={selectedIndexs.includes(row.account_number)}
                    onChange={(value) => onCheckboxChange(value)}
                >
                    <div className="d-flex">
                        <div>{row.account_number}</div>
                        <div className="mx-2">
                            <i>{row.account_name}</i>
                        </div>
                    </div>
                </Checkbox>
            </>
        );
    };

    // React.useEffect(() => {
    //     console.log(sortActive, sortPhase);
    // }, [sortActive, sortPhase]);

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <PagePanel
                    title={trh("TrialBalanceLabel")}
                    buttons={
                        dataAreLoaded ? (
                            <div className="d-flex flex-row-reverse">
                                <div>
                                    {/* exportTB */}
                                    <Button
                                        className="mx-2"
                                        compact={false}
                                        onClick={() => {
                                            setExportModalIsOpen(true);
                                            exportTB();
                                        }}
                                    >
                                        {trg("export")}
                                    </Button>
                                    {exportModalMessage()}
                                </div>
                                <div>
                                    <Button className="mx-2" compact={false} onClick={() => setImportModalIsOpen(true)}>
                                        {trg("import")}
                                    </Button>
                                    {importModalContent()}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                >
                    {dataAreLoaded ? (
                        <>
                            {renderData.length > 0 && (
                                <>
                                    <div className="trial-balance-search-panel">
                                        <div className="d-flex">
                                            <div className="mx-1 flex-fill">
                                                <h4>{trg("search_by")}</h4>
                                            </div>
                                            <div>
                                                <Button
                                                    style={{display: filterVisible ? "block" : "none"}}
                                                    compact={true}
                                                    kind="text"
                                                    onClick={() => {
                                                        setSearchAccount("");
                                                        setSearchAccountName("");
                                                        setSearchOpeningBalanceGt("");
                                                        setSearchOpeningBalanceLt("");
                                                        setSearchDebitGt("");
                                                        setSearchDebitLt("");
                                                        setSearchCreditGt("");
                                                        setSearchCreditLt("");
                                                        setSearchClosingBalanceGt("");
                                                        setSearchClosingBalanceLt("");
                                                        setSearchOfficialComment("");
                                                        setSearchInternalComment("");
                                                        setSearchTags("");
                                                    }}
                                                >
                                                    {trg("reset_filter")}
                                                </Button>
                                            </div>
                                        </div>
                                        {filterVisible && <div className="clearfix">&nbsp;</div>}
                                        <div
                                            className="container-fluid"
                                            style={{display: filterVisible ? "block" : "none"}}
                                        >
                                            <div className="row">
                                                <div className="col">
                                                    <label>{trg("account")}</label>
                                                    <Input
                                                        size="small"
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchAccount}
                                                        onChange={(v: string) => setSearchAccount(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col">
                                                    <label>{trg("account_name")}</label>
                                                    <Input
                                                        size="small"
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchAccountName}
                                                        onChange={(v: string) => setSearchAccountName(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col">
                                                    <label>{trg("official_comment")}</label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchOfficialComment}
                                                        onChange={(v: string) => setSearchOfficialComment(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col">
                                                    <label>{trg("internal_comment")}</label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchInternalComment}
                                                        onChange={(v: string) => setSearchInternalComment(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col">
                                                    <label>{trg("tags")}</label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchTags}
                                                        onChange={(v: string) => setSearchTags(v)}
                                                    ></Input>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label>{trg("opening_balance")}</label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchOpeningBalanceGt}
                                                        onChange={(v: string) => setSearchOpeningBalanceGt(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col-1 col-char-width">
                                                    <label>{trg("to")}</label>
                                                </div>
                                                <div className="col">
                                                    <label></label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchOpeningBalanceLt}
                                                        onChange={(v: string) => setSearchOpeningBalanceLt(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col">
                                                    <label>{trg("debit")}</label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchDebitGt}
                                                        onChange={(v: string) => setSearchDebitGt(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col-1 col-char-width">
                                                    <label>{trg("to")}</label>
                                                </div>
                                                <div className="col">
                                                    <label></label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchDebitLt}
                                                        onChange={(v: string) => setSearchDebitLt(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col">
                                                    <label>{trg("credit")}</label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchCreditGt}
                                                        onChange={(v: string) => setSearchCreditGt(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col-1 col-char-width">
                                                    <label>{trg("to")}</label>
                                                </div>
                                                <div className="col">
                                                    <label></label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchCreditLt}
                                                        onChange={(v: string) => setSearchCreditLt(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col">
                                                    <label>{trg("closing_balance")}</label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchClosingBalanceGt}
                                                        onChange={(v: string) => setSearchClosingBalanceGt(v)}
                                                    ></Input>
                                                </div>
                                                <div className="col-1 col-char-width">
                                                    <label>{trg("to")}</label>
                                                </div>
                                                <div className="col">
                                                    <label></label>
                                                    <Input
                                                        type={"text"}
                                                        hideTitleOnInput={true}
                                                        value={searchClosingBalanceLt}
                                                        onChange={(v: string) => setSearchClosingBalanceLt(v)}
                                                    ></Input>
                                                </div>
                                            </div>
                                            {/* <label className="labelBottom">to</label>
                                                <label className="labelBottom">to</label>
                                                <label className="labelBottom">to</label> */}
                                        </div>
                                    </div>
                                    {filterVisible && <div className="clearfix">&nbsp;</div>}
                                    {O.isSome(entity) &&
                                        O.isSome(calculation) &&
                                        availableTags.default_tags.length > 0 && (
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex">
                                                    <div className="mx-2 mt-2">
                                                        {selectedIndexs.length === getData().length &&
                                                        getData().length !== 0 ? (
                                                            <Button
                                                                onClick={() => setSelectedIndexs([])}
                                                                compact={true}
                                                                kind="text"
                                                                className="mx-1"
                                                                style={{width: "120px"}}
                                                            >
                                                                {trg("deselect_all")}
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                onClick={() =>
                                                                    setSelectedIndexs(
                                                                        getData().map((d) => d.account_number)
                                                                    )
                                                                }
                                                                compact={true}
                                                                kind="text"
                                                                className="mx-1"
                                                                style={{width: "120px"}}
                                                            >
                                                                {trg("select_all")}
                                                            </Button>
                                                        )}
                                                    </div>
                                                    <div style={{margin: "12px 10px"}}>Action on selected:&nbsp; </div>
                                                    <SelectTagDropdown
                                                        available_tags={availableTags}
                                                        selected_indexs={selectedIndexs}
                                                        calculation_hash={calculation.value.hash}
                                                        entity_hash={entity.value.hash}
                                                        addTagHandler={addTags}
                                                    />
                                                </div>
                                                <Button
                                                    style={{float: "right"}}
                                                    onClick={() => {
                                                        setFilterVisible(!filterVisible);
                                                    }}
                                                >
                                                    Filter
                                                </Button>
                                            </div>
                                        )}
                                    <div className="tableresponsive mt-1">
                                        <Table
                                            className="table-trial-balance noshadow"
                                            style={undefined}
                                            originalData={getData()}
                                            selectedIndexs={selectedIndexs}
                                            hasTitle={true}
                                            striped={true}
                                            condensed={true}
                                            sortActive={sortActive}
                                            sortPhase={sortPhase}
                                            animatedSorting={false}
                                            skipInitialSort={true}
                                            onSort={(sortKey: string, sortingPhase: number) => {
                                                setSortActive(sortKey);
                                                setSortPhase(sortingPhase);
                                            }}
                                            onRowClick={(event, index, data) => {
                                                if (event) {
                                                    setDrawerVisible(true);
                                                    setSelectedAccountNumber(data.account_number);
                                                }
                                            }}
                                        >
                                            <Column sortKey="account_number" renderCell={renderOrderCell}>
                                                {trg("account")}
                                            </Column>
                                            <Column
                                                sortKey="opening_balance"
                                                renderCell={(row, field) => (
                                                    <Number row={row} field={field} amount={row.opening_balance} />
                                                )}
                                            >
                                                {trg("opening_balance")}
                                            </Column>
                                            <Column
                                                sortKey="adjusted_debit"
                                                renderCell={(row, field) => (
                                                    <Number row={row} field={field} amount={row.adjusted_debit} />
                                                )}
                                            >
                                                {trg("debit")}
                                            </Column>
                                            <Column
                                                sortKey="adjusted_credit"
                                                renderCell={(row, field) => (
                                                    <Number row={row} field={field} amount={row.adjusted_credit} />
                                                )}
                                            >
                                                {trg("credit")}
                                            </Column>
                                            <Column
                                                sortKey="adjusted_closing_balance"
                                                renderCell={(row, field) => (
                                                    <Number
                                                        row={row}
                                                        field={field}
                                                        amount={row.adjusted_closing_balance}
                                                    />
                                                )}
                                            >
                                                {trg("closing_balance")}
                                            </Column>
                                            <Column field="render_tags">{trg("tags")}</Column>
                                            <Column field="render_comment_official">{trg("official_comment")}</Column>
                                            <Column field="render_comment_internal">{trg("internal_comment")}</Column>
                                            {/* <Column field="render_comments">Comments</Column> */}
                                        </Table>
                                        {selectedAccountNumber && (
                                            <Drawer
                                                placement="bottom"
                                                visible={drawerVisible}
                                                onClose={() => {
                                                    setDrawerVisible(false);
                                                    setSelectedAccountNumber("");
                                                }}
                                                resizable={true}
                                                mask={false}
                                            >
                                                <FilterFormContent
                                                    temporaryFilter={true}
                                                    tempAccountNumber={selectedAccountNumber}
                                                ></FilterFormContent>
                                            </Drawer>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <Centered>
                            <Loading loadingType="linear" indeterminate={false} compact={false}></Loading>
                        </Centered>
                    )}
                </PagePanel>
                {renderLink}
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default TrialBalance;

declare type SelectTagDropdownPropTypes = {
    available_tags: any | {default_tags: Tag[]; custom_tags: Tag[]};
    account_number?: string;
    calculation_hash: string;
    entity_hash: string;
    selected_indexs?: number[];
    addTagHandler: (tags: Tag[]) => void;
};

const SelectTagDropdown: React.FC<SelectTagDropdownPropTypes> = (props: SelectTagDropdownPropTypes) => {
    const [data, setData] = React.useState<ItemDataType[]>([]);
    const [newTag, setNewTag] = React.useState<string>("");
    const [showNewTagModal, setShowNewTagModal] = React.useState<boolean>(false);
    const [showLoader, setShowLoader] = React.useState<boolean>(false);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const trg = useTr("global");

    const fetchApi = useGlobalStore((s) => s.fetchApi);

    const onSelectHandler = (val: SelectValue) => {
        if (
            typeof val === "string" &&
            val.indexOf("custom_") === 0 &&
            (props.account_number || props.selected_indexs)
        ) {
            setShowLoader(true);
            fetchApi(
                `/entity/${props.entity_hash}/calculation/${props.calculation_hash}/trial_balance/tag_add_custom/`,
                "post",
                {
                    custom_tag_id: parseInt(val.replace("custom_", "")),
                    account_numbers: props.account_number ? [stripHtml(props.account_number)] : props.selected_indexs,
                }
            )
                .then((response) => response.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        res.map((t: any) => props.addTagHandler(t));
                        setShowLoader(false);
                    }
                });
        } else if (val && (props.account_number || props.selected_indexs)) {
            setShowLoader(true);
            fetchApi(`/entity/${props.entity_hash}/calculation/${props.calculation_hash}/trial_balance/tag/`, "post", {
                tag_id: val,
                account_numbers: props.account_number ? [stripHtml(props.account_number)] : props.selected_indexs,
            })
                .then((response) => response.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    }
                    res.map((t: any) => props.addTagHandler(t));
                    setShowLoader(false);
                });
        } else {
            setShowNewTagModal(true);
        }
    };

    React.useEffect(() => {
        let default_tags = props.available_tags.default_tags.map((tag: Tag, index: number) => {
            return {
                label: tag.name,
                value: tag.id,
                descValue: index,
            };
        });
        let custom_tags = props.available_tags.custom_tags.map((tag: Tag, index: number) => {
            return {
                label: tag.name,
                value: "custom_" + tag.id,
                descValue: index,
            };
        });
        custom_tags.push({
            label: "+ new tag",
            value: 0,
            descValue: 0,
        });
        setData([
            {
                label: "Default tags",
                key: "default_tags",
                type: "group",
                children: default_tags,
            },
            {
                label: "Custom tags",
                key: "custom_tags",
                type: "group",
                children: custom_tags,
            },
        ]);
    }, [props, props.available_tags]);

    const saveNewTag = () => {
        setShowLoader(true);
        if (newTag.length > 0 && (props.account_number || props.selected_indexs)) {
            fetchApi(
                `/entity/${props.entity_hash}/calculation/${props.calculation_hash}/trial_balance/tag_add_new/`,
                "post",
                {
                    tag_name: newTag,
                    account_numbers: props.account_number ? [stripHtml(props.account_number)] : props.selected_indexs,
                }
            )
                .then((response) => response.json())
                .then((res) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        if (res.length > 0) {
                            res.map((t: any) => props.addTagHandler(t));
                            setShowNewTagModal(false);
                            setNewTag("");
                        }
                        setShowLoader(false);
                    }
                });
        } else {
            setNewTag("");
            setShowNewTagModal(false);
            setShowLoader(false);
        }
    };
    return (
        <>
            <Select
                data={data}
                value=""
                searchable={true}
                placeholder={props.account_number ? "+ tag" : "+ add tag on selected"}
                className="select-tag-dropdown"
                style={{display: "inline-block"}}
                dropdownMatchWidth={false}
                onSelect={onSelectHandler}
            />
            {showLoader && <Loading indeterminate={true} compact={true} className="mt-1" />}
            <Modal
                visible={showNewTagModal}
                modalStyle={{width: "33.75rem"}}
                onCancel={() => setShowNewTagModal(false)}
                title={`${trg("add_new_tag_for_account")} ${
                    props.account_number ? props.account_number : "[all selected]"
                }`}
                footer={
                    <>
                        <Button onClick={() => setShowNewTagModal(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button onClick={saveNewTag} loading={showLoader}>
                            {trg("save")}
                        </Button>
                    </>
                }
            >
                <Input
                    title="Tag name"
                    name="new_tag"
                    type="text"
                    value={newTag}
                    onChange={(v: string) => setNewTag(stripHtml(v))}
                />
            </Modal>
        </>
    );
};
