import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Form} from "../../../../types/Form";
import {Select} from "@appkit4/react-components";

function useFormSelection(disabled) {
    const [selectedForm, setSelectedForm] = React.useState<Form>();
    const [forms, setForms] = React.useState<Form[]>([]);
    const [formsAreLoaded, setFormsAreLoaded] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const [formToSelect, setFormToSelect] = React.useState<Form>();

    React.useEffect(() => {
        if (formsAreLoaded === false)
            fetchApi(`/settings/_/fixtures/form/`)
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setForms(data.results);
                        if (formToSelect) {
                            setSelectedForm(formToSelect);
                            setFormToSelect(undefined);
                        }
                    }
                    setFormsAreLoaded(true);
                })
                .catch((error) => {
                    addErrorNotification(error);
                    setFormsAreLoaded(true);
                });
    }, [formsAreLoaded, fetchApi, addErrorNotification, formToSelect]);

    const setNewForm = (new_form: Form) => {
        setFormToSelect(new_form);
        setFormsAreLoaded(false);
    };

    const formSelector = (
        <Select
            data={forms}
            labelKey="name"
            placeholder="Select form"
            valueKey="id"
            onSelect={(val) => setSelectedForm(val ? forms.filter((f) => f.id === val)[0] : undefined)}
            itemTemplate={(val, item) => (
                <>
                    ({item.id}) - {item.name}
                </>
            )}
            disabled={selectedForm && disabled}
        ></Select>
    );

    return {formSelector, selectedForm, setNewForm};
}

export default useFormSelection;
