import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Input, Button, Select} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {useFixtureStore} from "../../../../stores/FixtureStore";
import {RulesetForm} from "../../../../types/RulesetForm";
import {SelectValue} from "@appkit4/react-components/esm/select/Select";
import {CategoryGroup} from "../../../../types/CategoryGroup";
import {nonEmptyArray} from "fp-ts";
import {Checkbox} from "@appkit4/react-components/checkbox";
import {Tag} from "../../../../types/Tag";
import {AdjustmentDefault} from "../../../../types/AdjustmentDefault";

declare type PropTypes = {
    adjustmentDefault?: AdjustmentDefault;
    onSaveHandler?: () => void;
};

const AdjustmentDefaultForm: React.FC<PropTypes> = (props: PropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [rulesetForm, setRulesetForm] = React.useState<AdjustmentDefault | undefined>(
        props.adjustmentDefault ? props.adjustmentDefault : undefined
    );

    const [data, setData] = React.useState<AdjustmentDefault>({
        id: props.adjustmentDefault ? props.adjustmentDefault.id : 0,
        type: props.adjustmentDefault ? props.adjustmentDefault.type : "",
        name: props.adjustmentDefault ? props.adjustmentDefault.name : "",
        amount: props.adjustmentDefault ? props.adjustmentDefault.amount : 0,
        coefficient: props.adjustmentDefault ? props.adjustmentDefault.coefficient : 0,
        category: props.adjustmentDefault ? props.adjustmentDefault.category : "",
        comment_official: props.adjustmentDefault ? props.adjustmentDefault.comment_official : "",
        comment_official_is_generic: props.adjustmentDefault
            ? props.adjustmentDefault.comment_official_is_generic
            : false,
        deferred_tax_classification: props.adjustmentDefault ? props.adjustmentDefault.deferred_tax_classification : "",
        parent: props.adjustmentDefault ? props.adjustmentDefault.parent : "",
        attributes: props.adjustmentDefault ? props.adjustmentDefault.attributes : "",
        category_condition: props.adjustmentDefault ? props.adjustmentDefault.category_condition : "",
        result_formula: props.adjustmentDefault ? props.adjustmentDefault.result_formula : "",
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint = props.adjustmentDefault
            ? `/settings/_/fixtures/adjustmentdefault/${props.adjustmentDefault.id}/`
            : `/settings/_/fixtures/adjustmentdefault/`;

        fetchApi(endpoint, props.adjustmentDefault ? "patch" : "post", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    setVisible(false);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setRulesetForm(props.adjustmentDefault);
        setData({
            id: props.adjustmentDefault ? props.adjustmentDefault.id : 0,
            type: props.adjustmentDefault ? props.adjustmentDefault.type : "",
            name: props.adjustmentDefault ? props.adjustmentDefault.name : "",
            amount: props.adjustmentDefault ? props.adjustmentDefault.amount : 0,
            coefficient: props.adjustmentDefault ? props.adjustmentDefault.coefficient : 0,
            category: props.adjustmentDefault ? props.adjustmentDefault.category : "",
            comment_official: props.adjustmentDefault ? props.adjustmentDefault.comment_official : "",
            comment_official_is_generic: props.adjustmentDefault
                ? props.adjustmentDefault.comment_official_is_generic
                : false,
            deferred_tax_classification: props.adjustmentDefault
                ? props.adjustmentDefault.deferred_tax_classification
                : "",
            parent: props.adjustmentDefault ? props.adjustmentDefault.parent : "",
            attributes: props.adjustmentDefault ? props.adjustmentDefault.attributes : "",
            category_condition: props.adjustmentDefault ? props.adjustmentDefault.category_condition : "",
            result_formula: props.adjustmentDefault ? props.adjustmentDefault.result_formula : "",
        });
    }, [props.adjustmentDefault]);

    return (
        <>
            {rulesetForm === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    Add
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            <Modal
                visible={visible}
                title={`Adjutment Default ID: ${rulesetForm ? rulesetForm.id : "-"}`}
                ariaLabel={"Ruleset Form"}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    rulesetForm ? (
                        <Badge type={"primary"} value="Editing"></Badge>
                    ) : (
                        <Badge type="success" value="Adding"></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            Cancel
                        </Button>
                        <Button onClick={saveHandler} loading={isLoading}>
                            Save
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row" style={{paddingBottom: "290px"}}>
                    <div className="col-md-8 mt-3">
                        <Select
                            data={[
                                "null",
                                "accounting-result",
                                "journal",
                                "manual",
                                "taxable_result",
                                "fixed_assets",
                                "provisions",
                                "valuation_allowances",
                                "cash_basis",
                                "helpful",
                            ]}
                            value={data.type}
                            placeholder="Type"
                            searchable={true}
                            onSelect={(val) =>
                                setData((s) => ({
                                    ...s,
                                    type: val === "null" ? null : val.toString(),
                                }))
                            }
                        ></Select>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Name"}
                            value={data.name}
                            required
                            onChange={(val: string) => setData((s) => ({...s, name: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Amount"}
                            value={data.amount}
                            required
                            onChange={(val: number) => setData((s) => ({...s, amount: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Coefficient"}
                            value={data.coefficient}
                            required
                            onChange={(val: number) => setData((s) => ({...s, coefficient: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Category"}
                            value={data.category}
                            required
                            onChange={(val: any) => setData((s) => ({...s, category: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Comment official"}
                            value={data.comment_official}
                            onChange={(val: string) => setData((s) => ({...s, comment_official: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-6 mt-3">
                        <Checkbox
                            className="mt-2"
                            checked={data.comment_official_is_generic}
                            onChange={(v) => setData((s) => ({...s, comment_official_is_generic: v}))}
                        >
                            Comment official is generic
                        </Checkbox>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Deferred tax classification"}
                            value={data.deferred_tax_classification}
                            onChange={(val: string) => setData((s) => ({...s, deferred_tax_classification: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Parent"}
                            value={data.parent}
                            required
                            onChange={(val: string) => setData((s) => ({...s, parent: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Category condition"}
                            value={data.attributes.category_condition}
                            required
                            onChange={(val: any) =>
                                setData((s) => ({
                                    ...s,
                                    attributes: {
                                        ...s.attributes,
                                        category_condition: val,
                                    },
                                }))
                            }
                        />
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={"Result formula"}
                            value={data.attributes.result_formula}
                            required
                            onChange={(val: any) =>
                                setData((s) => ({
                                    ...s,
                                    attributes: {
                                        ...s.attributes,
                                        result_formula: val,
                                    },
                                }))
                            }
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AdjustmentDefaultForm;
