import React from "react";
import {FileModel, Button, Loading} from "@appkit4/react-components";
import {Upload} from "@appkit4/react-components/upload";
import {useGlobalStore} from "../../stores/GlobalStore";
import {Calculation} from "../../types/Calculation";
import {Entity} from "../../types/Entity";
import {useNavigate} from "react-router-dom";
import {extraPropToFlowjs, uploadUrl} from "../../utils/uploadUtil";
import {useTr} from "../../utils/trUtil";
import {Notice} from "@appkit4/react-components/notification";
import PagePanel from "../../misc/PagePanel";
import {Badge} from "@appkit4/react-components/badge";

declare type PropTypes = {
    calculation: Calculation;
    entity: Entity;
    menu: React.ReactNode;
};

const ImportTrialBalanceFile: React.FC<PropTypes> = (props: PropTypes) => {
    const tr = useTr("import");
    const trg = useTr("global");
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const updateCurrentCalculation = useGlobalStore((s) => s.updateCurrentCalculation);
    const accessToken = useGlobalStore((s) => s.accessToken);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const navigate = useNavigate();
    const [uploadIsRunning, setUploadIsRunning] = React.useState<boolean>(false);
    const [importIsSkipping, setImportIsSkipping] = React.useState<boolean>(false);

    const onFileUploadChange = (file: FileModel) => {
        setUploadIsRunning(true);
        if (file && file.percent === 100) {
            const fileType = file.name.split(".").pop()?.toLowerCase();

            const url =
                fileType === "xls" || fileType === "xlsx"
                    ? `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/journal_load/`
                    : `/entity/${props.entity.hash}/calculation/${props.calculation.hash}/journal_load_csv/`;

            fetchApi(url, "post", file.response)
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        updateCurrentCalculation(data.calculation);
                        setTimeout(() => {
                            navigate(
                                `/calculation/${props.entity.hash}/${props.calculation.hash}/import/journal/column-mapping`
                            );
                        }, 400);
                    }
                    setUploadIsRunning(false);
                });
        }
    };

    const skipImport = () => {
        setImportIsSkipping(true);
        fetchApi(`/entity/${props.entity.hash}/calculation/${props.calculation.hash}/`, "patch", {
            state_journal_import_skipped: true,
            state_journal_as_balancing_figures: false,
            state_data_checked: false,
        })
            .then((response) => response.json())
            .then((data) => {
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                } else {
                    updateCurrentCalculation(data);
                    setTimeout(() => {
                        navigate(
                            `/calculation/${props.entity.hash}/${props.calculation.hash}/import/trial-balance/adjustments`
                        );
                    }, 400);
                }
                setImportIsSkipping(false);
            });
    };

    return (
        <PagePanel title={"Import journal"}>
            {props.menu}
            <div className="row">
                <div className="col-md-5 pt-5 text-end">
                    <Badge value={trg("journal_entry")} type="success-outlined" />
                </div>
                <div className="col-md-7 pt-5">
                    <Button
                        className="float-end"
                        compact={true}
                        kind="secondary"
                        icon="icon-chevron-double-right-outline"
                        loading={importIsSkipping}
                        onClick={skipImport}
                    >
                        {trg("skip_import")}
                    </Button>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-5 col-sm-9 pb-5 mb-5">
                    <Upload
                        style={{width: "100%"}}
                        action={uploadUrl(props.entity)}
                        autoUpload={true}
                        extraPropToFlowjs={extraPropToFlowjs(props.entity, props.calculation, accessToken)}
                        uploadTitle={tr("import_journal")}
                        uploadInstruction={tr("upload_instruction")}
                        multiple={false}
                        acceptFileType=".xls,.xlsx,.csv"
                        color={"white"}
                        onChange={onFileUploadChange}
                        config={{
                            trigger: false,
                            type: "inline",
                            size: false,
                        }}
                        className="tb-je-upload-container"
                    ></Upload>
                    <Notice
                        title=""
                        message={trg("note_the_first_row_of")}
                        status="warning"
                        closeable={false}
                        className="w-100 m-0 p-3"
                    ></Notice>
                    <Notice
                        title=""
                        message={trg("import_remove_total_rows_and_others")}
                        status="warning"
                        closeable={false}
                        className="w-100 mt-2 m-0 p-3"
                    ></Notice>
                    {uploadIsRunning && (
                        <Loading className="mt-3" loadingType="circular" indeterminate={true} compact={false}></Loading>
                    )}
                </div>
            </div>
        </PagePanel>
    );
};

export default ImportTrialBalanceFile;
