import {create} from "zustand";
import {persist} from "zustand/middleware";

interface AutoScrollStore {
    autoScroll: boolean;
    toggleAutoScroll: () => void;
}

const autoScrollStore = persist<AutoScrollStore>(
    (set) => ({
        autoScroll: true,
        toggleAutoScroll: () => {
            set((state) => ({
                autoScroll: !state.autoScroll,
            }));
        },
    }),
    {name: "auto-scroll"}
);

export const useAutoScrollStore = create(autoScrollStore);
