import React from "react";
import {useTr} from "../../../utils/trUtil";
import {useGlobalStore} from "../../../stores/GlobalStore";
import {Loading} from "@appkit4/react-components";
import * as O from "fp-ts/Option";
import Centered from "../../../misc/Centered";
import {DeferredTaxChildPropTypes} from "./DeferredTaxBase";
import {
    DeferredTaxResultEntry,
    DeferredTaxResult as DeferredTaxResultObject,
} from "../../../types/DeferredTaxResultEntry";
import {numberToLocaleString} from "../../../utils/numberToLocaleString";
import i18n from "../../../utils/i18n";

const DeferredTaxResult: React.FC<DeferredTaxChildPropTypes> = (props: DeferredTaxChildPropTypes) => {
    const trh = useTr("header");
    const trg = useTr("global");

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const [data, setData] = React.useState<DeferredTaxResultObject>();
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && dataAreLoaded === false) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/deferred_tax_entry/result/`)
                .then((res: any) => res.json())
                .then((res: DeferredTaxResultObject | any) => {
                    if (typeof res.error !== "undefined") {
                        addErrorNotification(res);
                    } else {
                        setData(res);
                    }
                    setDataAreLoaded(true);
                });
        }
    }, [entity, calculation, dataAreLoaded, setDataAreLoaded, fetchApi, addErrorNotification]);

    React.useEffect(() => {
        if (props.newEntryWasAdded) {
            setDataAreLoaded(false);
        }
    }, [props.newEntryWasAdded]);

    return (
        <>
            {O.isSome(calculation) && dataAreLoaded && data && data.result.length > 0 ? (
                <div className="ap-table ap-table-condensed ap-table-striped table-responsive deferred-tax-table-wrapper">
                    <table className="table-head-padding table table-borderless table-striped">
                        <thead className="thead-dark">
                            <tr>
                                <th
                                    className="text-center border-end header"
                                    scope="col"
                                    style={{backgroundColor: "#EBEBEB"}}
                                    colSpan={3}
                                >
                                    {trg("deffered_tax_entry_info")}
                                </th>
                                <th
                                    className="text-center border-end header"
                                    scope="col"
                                    style={{backgroundColor: "#D2D7E2"}}
                                    colSpan={7}
                                >
                                    {trg("prior_calculation")}
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th
                                            className="text-center border-end header"
                                            scope="col"
                                            style={{backgroundColor: "#EBEBEB"}}
                                            colSpan={7}
                                        >
                                            {trg("prior_calculation_current_rate")}
                                        </th>
                                    </>
                                )}
                                <th
                                    className="text-center border-end header"
                                    scope="col"
                                    style={{backgroundColor: "#9AA4BE"}}
                                    colSpan={7}
                                >
                                    {trg("current_calculation")}
                                </th>
                                <th
                                    className="text-center header"
                                    scope="col"
                                    style={{backgroundColor: "#D2D7E2"}}
                                    colSpan={2}
                                >
                                    {trg("movement")}
                                    <br />
                                    <i>(current - prior)</i>
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className="text-center" style={{backgroundColor: "#9AA4BE"}} colSpan={2}>
                                            {trg("movement")}
                                            <br />
                                            <i>(current - prior with current rate)</i>
                                        </th>
                                        <th className="text-center" style={{backgroundColor: "#EBEBEB"}} colSpan={2}>
                                            {trg("movement")}
                                            <br />
                                            <i>(prior - prior with current rate)</i>
                                        </th>
                                    </>
                                )}
                            </tr>
                            <tr>
                                <th className="text-center text-nowrap border-bottom">{trg("name")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("type")}</th>
                                <th className="text-center text-nowrap border-bottom border-end">{trg("tax_type")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("book_value")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("tax_value")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("difference")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("rate")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("tax")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("tax_recognised")}</th>
                                <th className="text-center text-nowrap border-bottom border-end">
                                    {trg("value_adjustment")}
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className="text-center text-nowrap border-bottom">{trg("book_value")}</th>
                                        <th className="text-center text-nowrap border-bottom">{trg("tax_value")}</th>
                                        <th className="text-center text-nowrap border-bottom">{trg("difference")}</th>
                                        <th className="text-center text-nowrap border-bottom">{trg("rate")}</th>
                                        <th className="text-center text-nowrap border-bottom">{trg("tax")}</th>
                                        <th className="text-center text-nowrap border-bottom">
                                            {trg("tax_recognised")}
                                        </th>
                                        <th className="text-center text-nowrap border-bottom border-end">
                                            {trg("value_adjustment")}
                                        </th>
                                    </>
                                )}
                                <th className="text-center text-nowrap border-bottom">{trg("book_value")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("tax_value")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("difference")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("rate")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("tax")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("tax_recognised")}</th>
                                <th className="text-center text-nowrap border-bottom border-end">
                                    {trg("value_adjustment")}
                                </th>
                                <th className="text-center text-nowrap border-bottom">{trg("tax_recognised")}</th>
                                <th className="text-center text-nowrap border-bottom">{trg("value_adjustment")}</th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className="text-center text-nowrap border-bottom">
                                            {trg("tax_recognised")}
                                        </th>
                                        <th className="text-center text-nowrap border-bottom">
                                            {trg("value_adjustment")}
                                        </th>
                                        <th className="text-center text-nowrap border-bottom">
                                            {trg("tax_recognised")}
                                        </th>
                                        <th className="text-center text-nowrap border-bottom">
                                            {trg("value_adjustment")}
                                        </th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {data.result.map((entry: DeferredTaxResultEntry, index) => {
                                let description = entry.name !== entry.description ? entry.description : "";
                                return (
                                    <tr key={index}>
                                        <td className="text-nowrap">
                                            {entry.name}
                                            <br />
                                            <small
                                                className="d-inline-block text-truncate"
                                                style={{width: "200px"}}
                                                title={description}
                                            >
                                                {description}
                                            </small>
                                        </td>
                                        <td>{entry.type ? trg(entry.type) : ""}</td>
                                        <td className="border-end">{entry.tax_type}</td>

                                        <td className={"text-end"}>
                                            {entry.book_value_prior !== undefined
                                                ? numberToLocaleString(entry.book_value_prior)
                                                : ""}
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.tax_value_prior !== undefined
                                                ? numberToLocaleString(entry.tax_value_prior)
                                                : ""}
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.difference_prior !== undefined
                                                ? numberToLocaleString(entry.difference_prior)
                                                : ""}
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.rate_prior !== undefined
                                                ? numberToLocaleString(entry.rate_prior)
                                                : ""}
                                            %
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.tax_prior !== undefined ? numberToLocaleString(entry.tax_prior) : ""}
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.tax_recognised_prior !== undefined
                                                ? numberToLocaleString(entry.tax_recognised_prior)
                                                : ""}
                                        </td>
                                        <td className={"text-end border-end"}>
                                            {entry.value_adjustment_prior !== undefined
                                                ? numberToLocaleString(entry.value_adjustment_prior)
                                                : ""}
                                        </td>

                                        {calculation.value.deferred_tax_rate_opening !==
                                            calculation.value.deferred_tax_rate_closing && (
                                            <>
                                                <td className={"text-end"}>
                                                    {entry.book_value_prior_current_rate !== undefined
                                                        ? numberToLocaleString(entry.book_value_prior_current_rate)
                                                        : ""}
                                                </td>
                                                <td className={"text-end"}>
                                                    {entry.tax_value_prior_current_rate !== undefined
                                                        ? numberToLocaleString(entry.tax_value_prior_current_rate)
                                                        : ""}
                                                </td>
                                                <td className={"text-end"}>
                                                    {entry.difference_prior_current_rate !== undefined
                                                        ? numberToLocaleString(entry.difference_prior_current_rate)
                                                        : ""}
                                                </td>
                                                <td className={"text-end"}>
                                                    {entry.rate_prior_current_rate !== undefined
                                                        ? numberToLocaleString(entry.rate_prior_current_rate)
                                                        : ""}
                                                    %
                                                </td>
                                                <td className={"text-end"}>
                                                    {entry.tax_prior_current_rate !== undefined
                                                        ? numberToLocaleString(entry.tax_prior_current_rate)
                                                        : ""}
                                                </td>
                                                <td className={"text-end"}>
                                                    {entry.tax_recognised_prior_current_rate !== undefined
                                                        ? numberToLocaleString(entry.tax_recognised_prior_current_rate)
                                                        : ""}
                                                </td>
                                                <td className={"text-end border-end"}>
                                                    {entry.value_adjustment_prior_current_rate !== undefined
                                                        ? numberToLocaleString(
                                                              entry.value_adjustment_prior_current_rate
                                                          )
                                                        : ""}
                                                </td>
                                            </>
                                        )}

                                        <td className={"text-end"}>
                                            {entry.book_value_current !== undefined
                                                ? numberToLocaleString(entry.book_value_current)
                                                : ""}
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.tax_value_current !== undefined
                                                ? numberToLocaleString(entry.tax_value_current)
                                                : ""}
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.difference_current !== undefined
                                                ? numberToLocaleString(entry.difference_current)
                                                : ""}
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.rate_current !== undefined
                                                ? numberToLocaleString(entry.rate_current)
                                                : ""}
                                            %
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.tax_current !== undefined
                                                ? numberToLocaleString(entry.tax_current)
                                                : ""}
                                        </td>
                                        <td className={"text-end"}>
                                            {entry.tax_recognised_current !== undefined
                                                ? numberToLocaleString(entry.tax_recognised_current)
                                                : ""}
                                        </td>
                                        <td className={"text-end border-end"}>
                                            {entry.value_adjustment_current !== undefined
                                                ? numberToLocaleString(entry.value_adjustment_current)
                                                : ""}
                                        </td>
                                        {/* 1. Tax Recognised Movement */}
                                        <td className={"text-end"}>
                                            {entry.movement_tax_recognised_current_prior !== undefined
                                                ? numberToLocaleString(entry.movement_tax_recognised_current_prior)
                                                : ""}
                                        </td>
                                        {/* 1. Tax Value adjustment Movement */}
                                        <td className={"text-end"}>
                                            {entry.movement_value_adjustment_current_prior !== undefined
                                                ? numberToLocaleString(entry.movement_value_adjustment_current_prior)
                                                : ""}
                                        </td>
                                        {calculation.value.deferred_tax_rate_opening !==
                                            calculation.value.deferred_tax_rate_closing && (
                                            <>
                                                {/* 2. Tax Recognised Movement */}
                                                <td className={"text-end"}>
                                                    {entry.movement_tax_recognised_current_prior_current_rate !==
                                                    undefined
                                                        ? numberToLocaleString(
                                                              entry.movement_tax_recognised_current_prior_current_rate
                                                          )
                                                        : ""}
                                                </td>
                                                {/* 2 .Tax Value adjustment  Movement */}
                                                <td className={"text-end"}>
                                                    {entry.movement_value_adjustment_current_prior_current_rate !==
                                                    undefined
                                                        ? numberToLocaleString(
                                                              entry.movement_value_adjustment_current_prior_current_rate
                                                          )
                                                        : ""}
                                                </td>
                                                {/* 3. Tax Recognised Movement */}
                                                <td className={"text-end"}>
                                                    {entry.movement_tax_recognised_prior_current_rate_prior !==
                                                    undefined
                                                        ? numberToLocaleString(
                                                              entry.movement_tax_recognised_prior_current_rate_prior
                                                          )
                                                        : ""}
                                                </td>
                                                {/* 3. Tax Value adjustment  Movement*/}
                                                <td className={"text-end"}>
                                                    {entry.movement_value_adjustment_prior_current_rate_prior !==
                                                    undefined
                                                        ? numberToLocaleString(
                                                              entry.movement_value_adjustment_prior_current_rate_prior
                                                          )
                                                        : "-"}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot style={{lineHeight: "1 !important"}}>
                            <tr>
                                <th colSpan={1} rowSpan={2} className="border-end border-top">
                                    {trg("subtotal")}
                                </th>
                                <th colSpan={2} className="border-end border-top">
                                    {trg("assets")}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_book_value_prior)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_tax_value_prior)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_difference_prior)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_rate_prior)}%
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_tax_prior)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_tax_recognised_prior)}
                                </th>
                                <th className={"text-end border-top border-end"}>
                                    {numberToLocaleString(data.total.subtotal_assets_value_adjustment_prior)}
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_assets_book_value_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_assets_tax_value_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_assets_difference_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(data.total.subtotal_assets_rate_prior_current_rate)}%
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(data.total.subtotal_assets_tax_prior_current_rate)}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_assets_tax_recognised_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-top border-end"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_assets_value_adjustment_prior_current_rate
                                            )}
                                        </th>
                                    </>
                                )}
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_book_value_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_tax_value_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_difference_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_rate_current)}%
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_tax_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.subtotal_assets_tax_recognised_current)}
                                </th>
                                <th className={"text-end border-top border-end"}>
                                    {numberToLocaleString(data.total.subtotal_assets_value_adjustment_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(
                                        data.total.subtotal_assets_movement_tax_recognised_current_prior
                                    )}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(
                                        data.total.subtotal_assets_movement_value_adjustment_current_prior
                                    )}
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total
                                                    .subtotal_assets_movement_tax_recognised_current_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total
                                                    .subtotal_assets_movement_value_adjustment_current_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total
                                                    .subtotal_assets_movement_tax_recognised_prior_current_rate_prior
                                            )}
                                        </th>
                                        <th className={"text-end border-top border-end"}>
                                            {numberToLocaleString(
                                                data.total
                                                    .subtotal_assets_movement_value_adjustment_prior_current_rate_prior
                                            )}
                                        </th>
                                    </>
                                )}
                            </tr>
                            <tr>
                                <th colSpan={2} className="border-end">
                                    {trg("liabilities")}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_book_value_prior)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_tax_value_prior)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_difference_prior)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_rate_prior)}%
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_tax_prior)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_tax_recognised_prior)}
                                </th>
                                <th className={"text-end border-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_value_adjustment_prior)}
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_liabilities_book_value_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_liabilities_tax_value_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_liabilities_difference_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_liabilities_rate_prior_current_rate
                                            )}
                                            %
                                        </th>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_liabilities_tax_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_liabilities_tax_recognised_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-end"}>
                                            {numberToLocaleString(
                                                data.total.subtotal_liabilities_value_adjustment_prior_current_rate
                                            )}
                                        </th>
                                    </>
                                )}
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_book_value_current)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_tax_value_current)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_difference_current)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_rate_current)}%
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_tax_current)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_tax_recognised_current)}
                                </th>
                                <th className={"text-end border-end"}>
                                    {numberToLocaleString(data.total.subtotal_liabilities_value_adjustment_current)}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(
                                        data.total.subtotal_liabilities_movement_tax_recognised_current_prior
                                    )}
                                </th>
                                <th className={"text-end"}>
                                    {numberToLocaleString(
                                        data.total.subtotal_liabilities_movement_value_adjustment_current_prior
                                    )}
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total
                                                    .subtotal_liabilities_movement_tax_recognised_current_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total
                                                    .subtotal_liabilities_movement_value_adjustment_current_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end"}>
                                            {numberToLocaleString(
                                                data.total
                                                    .subtotal_liabilities_movement_tax_recognised_prior_current_rate_prior
                                            )}
                                        </th>
                                        <th className={"text-end border-end"}>
                                            {numberToLocaleString(
                                                data.total
                                                    .subtotal_liabilities_movement_value_adjustment_prior_current_rate_prior
                                            )}
                                        </th>
                                    </>
                                )}
                            </tr>
                            <tr>
                                <th colSpan={3} className="border-end border-top">
                                    {trg("total")}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.book_value_prior)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.tax_value_prior)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.difference_prior)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.rate_prior)}%
                                </th>
                                <th className={"text-end border-top"}>{numberToLocaleString(data.total.tax_prior)}</th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.tax_recognised_prior)}
                                </th>
                                <th className={"text-end border-top border-end"}>
                                    {numberToLocaleString(data.total.value_adjustment_prior)}
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(data.total.book_value_prior_current_rate)}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(data.total.tax_value_prior_current_rate)}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(data.total.difference_prior_current_rate)}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(data.total.rate_prior_current_rate)}%
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(data.total.tax_prior_current_rate)}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(data.total.tax_recognised_prior_current_rate)}
                                        </th>
                                        <th className={"text-end border-top border-end"}>
                                            {numberToLocaleString(data.total.value_adjustment_prior_current_rate)}
                                        </th>
                                    </>
                                )}
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.book_value_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.tax_value_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.difference_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.rate_current)}%
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.tax_current)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.tax_recognised_current)}
                                </th>
                                <th className={"text-end border-top border-end"}>
                                    {numberToLocaleString(data.total.value_adjustment_current)}
                                </th>

                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.movement_tax_recognised_current_prior)}
                                </th>
                                <th className={"text-end border-top"}>
                                    {numberToLocaleString(data.total.movement_value_adjustment_current_prior)}
                                </th>
                                {calculation.value.deferred_tax_rate_opening !==
                                    calculation.value.deferred_tax_rate_closing && (
                                    <>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total.movement_tax_recognised_current_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total.movement_value_adjustment_current_prior_current_rate
                                            )}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total.movement_tax_recognised_prior_current_rate_prior
                                            )}
                                        </th>
                                        <th className={"text-end border-top"}>
                                            {numberToLocaleString(
                                                data.total.movement_value_adjustment_prior_current_rate_prior
                                            )}
                                        </th>
                                    </>
                                )}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            ) : dataAreLoaded && data && data.result && data.result.length === 0 ? (
                <span>List of entries is empty.</span>
            ) : (
                <Centered>
                    <Loading loadingType="linear" indeterminate={true} compact={false}></Loading>
                </Centered>
            )}
        </>
    );
};

export default DeferredTaxResult;
