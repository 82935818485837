import React from "react";
import {Input, Button} from "@appkit4/react-components";
import {useTr} from "../utils/trUtil";
import {useGlobalStore} from "../stores/GlobalStore";
import {validateEmail} from "../utils/validateEmail";
import {Entity} from "../types/Entity";
import {Calculation} from "../types/Calculation";

declare type PropTypes = {
    claimType: string;
    entity: Entity;
    calculation: Calculation;
    beforeFetch: () => void;
    afterFetch: () => void;
};

const UserClaimAddingForm: React.FC<PropTypes> = ({
    claimType,
    entity,
    calculation,
    beforeFetch = () => {},
    afterFetch = () => {},
}) => {
    const trg = useTr("global");
    const fetch = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const [hasError, setHasError] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");

    const saveForm = () => {
        if (!hasError) {
            let data = {email: email};
            if (beforeFetch) beforeFetch();
            fetch(`/entity/${entity.hash}/claims/?type=${claimType}&calculation_hash=${calculation.hash}`, "post", data)
                .then((res) => res.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        afterFetch();
                        setEmail("");
                        setHasError(false);
                    }
                })
                .catch((error) => {
                    addErrorNotification(error);
                    if (afterFetch) afterFetch();
                });
        }
    };

    const emailValidator = () => {
        if (email.length > 0) setHasError(validateEmail(email) ? false : true);
    };

    return (
        <div className="d-flex">
            <Input
                title="Email address"
                error={hasError}
                errorNode={
                    <div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">
                        {trg("please_enter_a_valid_email_adress")}{" "}
                    </div>
                }
                value={email}
                onChange={(v) => {
                    setEmail(v);
                    setHasError(false);
                }}
                onBlur={emailValidator}
                className="flex-grow-1 mx-2"
            />
            <Button className="mt-1" onClick={saveForm}>
                {trg("save")}
            </Button>
        </div>
    );
};

export default UserClaimAddingForm;
