import React from "react";
import {Header, HeaderOptionItem} from "@appkit4/react-components";
import {matchPath, useLocation, useNavigate, useParams} from "react-router-dom";
import {CalculationHashParamName, ClaimTypes, EntityHashParamName, RoutingTable} from "../../Constants";
import UserAvatar from "./UserAvatar";
import "./LayoutHeader.scss";
import {GenericParams} from "../../types/Params";
import {ProvideRouteParams} from "../../utils/routeUtils";
import {HeaderType} from "../../types/HeaderType";
import {useGlobalStore} from "../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {useTr} from "../../utils/trUtil";
import LayoutSidebar from "./LayoutSidebar";
import "./LayoutHeader.scss";
import {hasCalculationClaim, hasEntityClaim} from "../../utils/claimUtils";

interface PropTypes {
    headerType: HeaderType;
}

interface IlistItems {
    possiblePaths: string[]; // First one is always used to redirect!
    content: string;
}

export const routerParamsHelper = (route: string, uriParams: Readonly<Partial<GenericParams>>) =>
    ProvideRouteParams(route, [
        {
            paramName: EntityHashParamName,
            paramValue: uriParams[EntityHashParamName]!,
        },
        {
            paramName: CalculationHashParamName,
            paramValue: uriParams[CalculationHashParamName]!,
        },
    ]);

// TODO:
// - Align to left,
// - make active links unclicable,
// - delimeter calculation name with some fancy looking line
// - Add sidebar
const LayoutHeader: React.FC<PropTypes> = (props: PropTypes) => {
    const tr = useTr("header");
    const navigate = useNavigate();
    const currentCalculation = useGlobalStore((s) => s.currentCalculation);
    const currentCalculationEntity = useGlobalStore((s) => s.currentCalculationEntity);
    const getUserClaims = useGlobalStore((s) => s.getUserClaims);
    const getUserClients = useGlobalStore((s) => s.getUserClients);

    const location = useLocation();
    const uriParams = useParams<GenericParams>();

    const CALCULATION_FULL_ACCESS_MENU = React.useMemo(
        () => [
            {
                possiblePaths: [routerParamsHelper(RoutingTable.entity_calculation_questionnaire, uriParams)],
                content: tr("QuestionnaireLabel"),
            },

            {
                possiblePaths: [routerParamsHelper(RoutingTable.entity_trial_balance, uriParams)],
                content: tr("TrialBalanceLabel"),
            },
            {
                possiblePaths: [routerParamsHelper(RoutingTable.entity_tax_flows, uriParams)],
                content: tr("TaxFlowsLabel"),
            },
            {
                possiblePaths: [routerParamsHelper(RoutingTable.entity_tax_base, uriParams)],
                content: tr("TaxBaseLabel"),
            },
            {
                possiblePaths: [
                    routerParamsHelper(RoutingTable.entity_review_base, uriParams),
                    routerParamsHelper(RoutingTable.entity_review_specific, uriParams),
                    routerParamsHelper(RoutingTable.entity_review_specific_transactions, uriParams),
                ],
                content: tr("ReviewLabel"),
            },
            {
                possiblePaths: [routerParamsHelper(RoutingTable.calculation_deferred_tax, uriParams)],
                content: tr("DeferredTaxLabel"),
            },
            {
                possiblePaths: [routerParamsHelper(RoutingTable.entity_info, uriParams)],
                content: <span className="Appkit4-icon icon-sticker-outline" title={tr("EntityInfoLabel")}></span>,
            },
            {
                possiblePaths: [
                    routerParamsHelper(RoutingTable.entity_import_base, uriParams),
                    routerParamsHelper(RoutingTable.entity_import_trial_balance_base, uriParams),
                    routerParamsHelper(RoutingTable.entity_import_trial_balance_file, uriParams),
                    routerParamsHelper(RoutingTable.entity_import_trial_balance_column_mapping, uriParams),
                    routerParamsHelper(RoutingTable.entity_import_journal_base, uriParams),
                    routerParamsHelper(RoutingTable.entity_import_journal_file, uriParams),
                    routerParamsHelper(RoutingTable.entity_import_journal_column_mapping, uriParams),
                    routerParamsHelper(RoutingTable.entity_import_data_check, uriParams),
                ],
                content: <span className="Appkit4-icon icon-upload-outline" title={tr("ImportLabel")}></span>,
            },
            {
                possiblePaths: [routerParamsHelper(RoutingTable.calculation_attachments, uriParams)],
                content: (
                    <span className="Appkit4-icon icon-folder-opened-outline" title={tr("AttachmentsLabel")}></span>
                ),
            },
            {
                possiblePaths: [
                    routerParamsHelper(RoutingTable.entity_export_base, uriParams),
                    routerParamsHelper(RoutingTable.entity_export_tax_calculation_xls, uriParams),
                    routerParamsHelper(RoutingTable.entity_export_tax_form_pdf, uriParams),
                    routerParamsHelper(RoutingTable.entity_tax_form, uriParams),
                    routerParamsHelper(RoutingTable.entity_export_tax_form_xml, uriParams),
                    routerParamsHelper(RoutingTable.entity_export_memory_report_xls, uriParams),
                    routerParamsHelper(RoutingTable.entity_export_dt_figures_nk, uriParams),
                ],
                //content: tr("ExportsLabel"),
                content: <span className="Appkit4-icon icon-document-export-outline" title={tr("ExportsLabel")}></span>,
            },
            {
                possiblePaths: [routerParamsHelper(RoutingTable.entity_settings, uriParams)],
                content: <span className="Appkit4-icon icon-setting-outline" title={tr("CalculationInfoLabel")}></span>,
            },
        ],
        [tr, uriParams]
    );

    const CALCULATION_ATTACHMENT_ACCESS_MENU = React.useMemo(
        () => [
            {
                possiblePaths: [routerParamsHelper(RoutingTable.entity_calculation_questionnaire, uriParams)],
                content: tr("QuestionnaireLabel"),
            },

            {
                possiblePaths: [routerParamsHelper(RoutingTable.calculation_attachments, uriParams)],
                content: (
                    <span className="Appkit4-icon icon-folder-opened-outline" title={tr("AttachmentsLabel")}></span>
                ),
            },
        ],
        [tr, uriParams]
    );

    const getCalculationMenu = React.useCallback(() => {
        const userClaims = Object.entries(getUserClaims()).map(([i, v]) => v);
        const userClients = getUserClients();

        if (O.isSome(currentCalculation) && O.isSome(currentCalculationEntity) && userClaims.length) {
            return hasCalculationClaim(
                currentCalculation.value,
                currentCalculationEntity.value,
                userClaims,
                ClaimTypes.ENTITY_CALCULATION
            ) || hasEntityClaim(currentCalculationEntity.value, userClaims, ClaimTypes.ENTITY, userClients)
                ? CALCULATION_FULL_ACCESS_MENU
                : hasCalculationClaim(
                      currentCalculation.value,
                      currentCalculationEntity.value,
                      userClaims,
                      ClaimTypes.ENTITY_CALCULATION_ATTACHMENTS
                  )
                ? CALCULATION_ATTACHMENT_ACCESS_MENU
                : [];
        }

        return [];
    }, [
        currentCalculation,
        currentCalculationEntity,
        getUserClaims,
        getUserClients,
        CALCULATION_ATTACHMENT_ACCESS_MENU,
        CALCULATION_FULL_ACCESS_MENU,
    ]);

    const listitems = React.useMemo(
        () =>
            (props.headerType === HeaderType.Blank
                ? []
                : props.headerType === HeaderType.Calculation &&
                  O.isSome(currentCalculation) &&
                  O.isSome(currentCalculationEntity)
                ? getCalculationMenu()
                : []) as Array<IlistItems>, //props.headerType === HeaderType.Settings
        [props.headerType, currentCalculation, currentCalculationEntity, getCalculationMenu]
    );

    function customMatchPath(currentPath: string, pattern: string) {
        return matchPath(pattern, currentPath) != null || currentPath.startsWith(pattern);
    }

    return (
        <header id="header">
            <Header
                onClickLogo={() => navigate(RoutingTable.my_clients)}
                optionsTemplate={() => (
                    <>
                        {uriParams[EntityHashParamName] &&
                            listitems.map((item, idx) => (
                                <HeaderOptionItem
                                    key={idx}
                                    label={item.content}
                                    className={
                                        item.possiblePaths.some(
                                            (s) =>
                                                customMatchPath(location.pathname, s) ||
                                                customMatchPath(location.pathname + "/", s)
                                        )
                                            ? "active-nav-item"
                                            : ""
                                    }
                                    onClick={() => navigate(item.possiblePaths[0])}
                                />
                            ))}
                    </>
                )}
                titleTemplate={() => (
                    <>
                        {"Corporate Tax Flow "}
                        {O.isSome(currentCalculationEntity) ? (
                            <>
                                <span style={{borderRight: "2px solid lightgray"}}>&nbsp;</span>
                                <span>&nbsp;&nbsp;{currentCalculationEntity.value.name}</span>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                )}
                userTemplate={() => (
                    <div className="position-right">
                        <UserAvatar />
                    </div>
                )}
            >
                <LayoutSidebar />
            </Header>
        </header>
    );
};

export default LayoutHeader;
